import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-custom-style',
  templateUrl: './nav-custom-style.component.html',
  styleUrls: ['./nav-custom-style.component.css']
})
export class NavCustomStyleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
