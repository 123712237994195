import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radios-basic',
  templateUrl: './radios-basic.component.html',
  styleUrls: ['./radios-basic.component.css']
})
export class RadiosBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
