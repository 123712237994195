import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sparklines',
  templateUrl: './sparklines.component.html',
  styleUrls: ['./sparklines.component.css']
})
export class SparklinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
