<p>
  <mat-checkbox [formControl]="disableSelect">Disable select</mat-checkbox>
</p>
<div class="row">
	<div class="col-sm-6">
	<h4>mat-select</h4>
	<mat-form-field appearance="fill">
	  <mat-label>Choose an option</mat-label>
	  <mat-select [disabled]="disableSelect.value">
		<mat-option value="option1">Option 1</mat-option>
		<mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
		<mat-option value="option3">Option 3</mat-option>
	  </mat-select>
	</mat-form-field>

	</div>
	<div class="col-sm-6">
	<h4>native html select</h4>
	<mat-form-field appearance="fill">
	  <mat-label>Choose an option</mat-label>
	  <select matNativeControl [disabled]="disableSelect.value">
		<option value="" selected></option>
		<option value="volvo">Volvo</option>
		<option value="saab" disabled>Saab</option>
		<option value="mercedes">Mercedes</option>
		<option value="audi">Audi</option>
	  </select>
	</mat-form-field>
	</div>
</div>

