import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-basic',
  templateUrl: './accordion-basic.component.html',
  styleUrls: ['./accordion-basic.component.css']
})
export class AccordionBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
