<ul>
  <li>on empty input all options will be taken</li>
  <li>otherwise options will be filtered against the search term</li>
  <li>it will limit the display to 10 results in all cases</li>
</ul>

<label for="typeahead-focus">Search for a state:</label>
<input
  id="typeahead-focus"
  type="text"
  class="form-control"
  [(ngModel)]="model"
  [ngbTypeahead]="search"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  #instance="ngbTypeahead"
/>
<hr>
<pre>Model: {{ model | json }}</pre>