
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-autocomplete default-mt-select">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Select</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with custom trigger text</h4>
                    </div>
                    <div class="card-body">
                        <app-custom-trigger-text></app-custom-trigger-text>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Disabled select</h4>
                    </div>
                    <div class="card-body">
                        <app-disabled-select></app-disabled-select>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with a custom ErrorStateMatcher</h4>
                    </div>
                    <div class="card-body">
                        <app-custom-error-state-matcher></app-custom-error-state-matcher>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select in a form</h4>
                    </div>
                    <div class="card-body">
                        <app-select-in-form></app-select-in-form>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with form field features</h4>
                    </div>
                    <div class="card-body">
                        <app-select-form-field-features></app-select-form-field-features>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic mat-select with initial value</h4>
                    </div>
                    <div class="card-body">
                        <app-initial-value-no-form></app-initial-value-no-form>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with multiple selection</h4>
                    </div>
                    <div class="card-body">
                        <app-multiple-selection></app-multiple-selection>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with no option ripple</h4>
                    </div>
                    <div class="card-body">
                        <app-no-option-ripple></app-no-option-ripple>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with option groups</h4>
                    </div>
                    <div class="card-body">
                        <app-option-groups></app-option-groups>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic select</h4>
                    </div>
                    <div class="card-body">
                        <app-basic-select></app-basic-select>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with custom panel styling</h4>
                    </div>
                    <div class="card-body">
                        <app-custom-panel-styling></app-custom-panel-styling>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select in a reactive form</h4>
                    </div>
                    <div class="card-body">
                        <app-select-in-reactive-form></app-select-in-reactive-form>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with reset option</h4>
                    </div>
                    <div class="card-body">
                        <app-select-with-reset-option></app-select-with-reset-option>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Select with 2-way value binding</h4>
                    </div>
                    <div class="card-body">
                        <app-two-way-value-binding></app-two-way-value-binding>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->