<ngb-carousel class="default-carousel" *ngIf="images">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[0]" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <h3>10 seconds between slides...</h3>
      <p>This carousel uses customized default values.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[1]" alt="Random second slide">
    </div>
    <div class="carousel-caption">
      <h3>No mouse events...</h3>
      <p>This carousel doesn't pause or resume on mouse events</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[2]"  alt="Random third slide">
    </div>
    <div class="carousel-caption">
      <h3>No keyboard...</h3>
      <p>This carousel uses customized default values.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[3]" alt="Random fourth slide">
    </div>
    <div class="carousel-caption">
      <h3>And no wrap after last slide.</h3>
      <p>This carousel uses customized default values.</p>
    </div>
  </ng-template>
</ngb-carousel>