<div class="card">
    <div class="card-header d-block d-sm-flex border-0">
        <div>
            <h4 class="fs-20 text-black">Shipper Inbound</h4>
            <p class="mb-0 fs-12">Total Berat By KG {{dt2 | date : 'dd MMM y'}}</p>
        </div>
        <div class="card-action card-tabs mt-3 mt-sm-0">
            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" activeId="Bulaninbound">
                <li class="nav-item" ngbNavItem="Hariinbound">
                    <a class="nav-link" data-toggle="tab" href="#Hariinbound" role="tab" aria-selected="false" ngbNavLink>
                        Hari	
                    </a>
                    <ng-template ngbNavContent>
                        <app-hariinbound class="bar-chart"></app-hariinbound>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Bulaninbound">
                    <a class="nav-link" data-toggle="tab" href="#Bulaninbound" role="tab" aria-selected="false" ngbNavLink>
                        Bulan
                    </a>
                    <ng-template ngbNavContent>
                        <app-bulaninbound class="bar-chart"></app-bulaninbound>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Tahuninbound">
                    <a class="nav-link" data-toggle="tab" href="#Tahuninbound" role="tab" aria-selected="false" ngbNavLink>
                        Tahun
                    </a>
                    <ng-template ngbNavContent>
                        <app-tahuninbound class="bar-chart"></app-tahuninbound>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>   
<div class="card-body pb-2">
    <div [ngbNavOutlet]="nav" class=""></div>
</div>
</div>
