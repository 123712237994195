import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-toggle-basic',
  templateUrl: './slide-toggle-basic.component.html',
  styleUrls: ['./slide-toggle-basic.component.css']
})
export class SlideToggleBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
