import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from "ng-apexcharts";
import { MasterService } from '../../../../_services/master.service';
// import {
//   ApexNonAxisChartSeries,
//   ApexResponsive,
//   ApexChart,
//   ApexLegend
// } from "ng-apexcharts";

// export type ChartOptions = {
//   series?: ApexNonAxisChartSeries | any;
//   chart?: ApexChart | any;
//   responsive?: ApexResponsive[] | any;
//   labels: any;
//   legend?: ApexLegend | any;
// };

@Component({
  selector: 'app-pie-juncountry',
  templateUrl: './pie-juncountry.component.html',
  styleUrls: ['./pie-juncountry.component.css']
})
export class PieJuncountryComponent implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  // public chartOptions: any;
  chartOptions: any = {};
  resultdata: any;

  constructor(private apiService: MasterService) {
    let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	  params.monthlys = 'monthlys';

    this.apiService.getAlls('dash/getGrandShipmentCountrymonth', params)
	  .subscribe(
		response => {
		
		  // var dataseties = [];
      var shippers = [];
      var month = [];
		  for (let i = 0; i < response.length; i++) {
        shippers.push(response[i].namecountry);
        month.push(response[i].totalshipment);
		  }
      // console.log(dataseties,'dataseties')
		  // CHARTS
      this.chartOptions = {
        series: month,
        chart: {
          width: 380,
          type: "pie"
        },
        labels: shippers,
        stroke: {
            width: 0,
        },
        // colors:['#FFAB2D','#AC4CBC','#FF334F','#F3FF33','#CA33FF','#3361FF'],
        legend:{
          position: "bottom",
				  show:true
			  },
        dataLabels: {
            enabled: true
        },
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom",
                show:true
              }
            }
          }
        ]
      };
		},
		error => {
		  // console.log(error);
		});
    
  }

  ngOnInit(): void {
  }

}
