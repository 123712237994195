import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitor-activity',
  templateUrl: './visitor-activity.component.html',
  styleUrls: ['./visitor-activity.component.css']
})
export class VisitorActivityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
