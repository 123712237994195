<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-autocomplete default-mt-input">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Input</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic icons</h4>
                    </div>
                    <div class="card-body">
                        <app-input-with-clear-button></app-input-with-clear-button>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Input with a custom ErrorStateMatcher</h4>
                    </div>
                    <div class="card-body">
                        <app-input-error-state-matcher></app-input-error-state-matcher>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Input with error messages</h4>
                    </div>
                    <div class="card-body">
                        <app-input-with-error-message></app-input-with-error-message>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Inputs with prefixes and suffixes</h4>
                    </div>
                    <div class="card-body">
                        <app-inputs-with-prefixes-suffixes></app-inputs-with-prefixes-suffixes>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Input with hints</h4>
                    </div>
                    <div class="card-body">
                        <app-inputs-with-hints></app-inputs-with-hints>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic Inputs</h4>
                    </div>
                    <div class="card-body">
                        <app-inputs-basic></app-inputs-basic>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Inputs in a form</h4>
                    </div>
                    <div class="card-body">
                        <app-inputs-in-form></app-inputs-in-form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->