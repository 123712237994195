import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-form-field',
  templateUrl: './simple-form-field.component.html',
  styleUrls: ['./simple-form-field.component.css']
})
export class SimpleFormFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
