    <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
        <div class="container-fluid default-mt-btn default-mt-table default-mt-select default-autocomplete default-mt-spinner">
            <div class="row page-titles mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>Hi, welcome back!</h4>
                        <p class="mb-0">Your business dashboard template</p>
                    </div>
                </div>
                <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0)">Table</a></li>
                    </ol>
                </div>
            </div>
            <!-- row -->
            <div class="row">
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Basic use of table</h4>
                        </div>
                        <div class="card-body">
                                <app-basic-table></app-basic-table>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table dynamically changing the columns displayed</h4>
                        </div>
                        <div class="card-body">
                                <app-dynamically-changing-columns-displayed></app-dynamically-changing-columns-displayed>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with expandable rows</h4>
                        </div>
                        <div class="card-body">
                                <app-table-expandable-rows></app-table-expandable-rows>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with filtering</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-filtering></app-table-with-filtering>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Basic use of `mat-table` (uses display flex)</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-display-flex></app-table-with-display-flex>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Footer row table</h4>
                        </div>
                        <div class="card-body">
                                <app-footer-row-table></app-footer-row-table>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table retrieving data through HTTP</h4>
                        </div>
                        <div class="card-body">
                                <app-retrieving-data-through-http></app-retrieving-data-through-http>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with multiple header and footer rows</h4>
                        </div>
                        <div class="card-body">
                                <app-multiple-header-footer-rows></app-multiple-header-footer-rows>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Data table with sorting, pagination, and filtering</h4>
                        </div>
                        <div class="card-body">
                                <app-data-table-sorting-pagination-filtering></app-data-table-sorting-pagination-filtering>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with pagination</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-pagination></app-table-with-pagination>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with re-orderable columns</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-re-orderable-columns></app-table-with-re-orderable-columns>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table showing each row context properties</h4>
                        </div>
                        <div class="card-body">
                                <app-table-context-properties></app-table-context-properties>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with selection</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-selection></app-table-with-selection>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with sorting</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-sorting></app-table-with-sorting>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with sticky columns</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-sticky-columns></app-table-with-sticky-columns>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Flex-layout tables with toggle-able sticky headers, footers, and columns</h4>
                        </div>
                        <div class="card-body">
                                <app-table-flex-with-toggle-able-sticky-headers-footers-columns></app-table-flex-with-toggle-able-sticky-headers-footers-columns>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Tables with toggle-able sticky headers, footers, and columns</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-toggle-able-sticky-headers-footers-columns></app-table-with-toggle-able-sticky-headers-footers-columns>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with a sticky footer</h4>
                        </div>
                        <div class="card-body">
                                <app-table-sticky-footer></app-table-sticky-footer>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Table with sticky header</h4>
                        </div>
                        <div class="card-body">
                                <app-table-sticky-header></app-table-sticky-header>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Use of 'mat-text-column' with various configurations of the interface</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-configurations-interface></app-table-with-configurations-interface>
                        </div>
                        </div>
                </div>
                <div class="col-xl-12 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Use of `mat-text-column` which can be used for simple columns that only need to display a text value for the header and cells</h4>
                        </div>
                        <div class="card-body">
                                <app-table-with-simple-text-header-footer></app-table-with-simple-text-header-footer>
                        </div>
                        </div>
                </div>
            </div>
            
        </div>
    </div>
    <!--**********************************
        Content body end
    ***********************************-->