	<!--**********************************
		Content body start
	***********************************-->
	<div class="content-body">
		<div class="container-fluid default-apex-cl">
			<div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Hi, welcome back!</h4>
                            <p class="mb-0">Your business dashboard template</p>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">Bar chart</a></li>
                        </ol>
                    </div>
                </div>

			<!-- row -->
			<div class="row">
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Basic</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-basic></app-bar-basic>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Grouped</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-grouped></app-bar-grouped>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Stacked Bar</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-stacked></app-bar-stacked>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Stacked Bars 100</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-stacked100></app-bar-stacked100>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Bar with Negative Values</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-negative-values></app-bar-negative-values>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Reversed Bar Chart</h4>
							<!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
						</div>
						<div class="card-body">
							
							<app-bar-reversed></app-bar-reversed>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Custom DataLabels Bar</h4>
						</div>
						<div class="card-body">
							
							<app-bar-custom-data-labels></app-bar-custom-data-labels>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Patterned</h4>
						</div>
						<div class="card-body">
							
							<app-bar-patterned></app-bar-patterned>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Bar with Images</h4>
						</div>
						<div class="card-body">
							
							<app-bar-images></app-bar-images>
							
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<!--**********************************
		Content body end
	***********************************-->