<mat-chip-list
class="example-chip"
cdkDropList 
cdkDropListOrientation="horizontal"
(cdkDropListDropped)="drop($event)">
    <mat-chip
    class="example-box"
    cdkDrag
    *ngFor="let vegetable of vegetables">
        {{vegetable.name}}
    </mat-chip>
</mat-chip-list>
