<div class="table-responsive">
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

	  <!-- Checkbox Column -->
	  <ng-container matColumnDef="select">
		<th mat-header-cell *matHeaderCellDef>
		  <mat-checkbox (change)="$event ? masterToggle() : null"
						[checked]="selection.hasValue() && isAllSelected()"
						[indeterminate]="selection.hasValue() && !isAllSelected()"
						[aria-label]="checkboxLabel()">
		  </mat-checkbox>
		</th>
		<td mat-cell *matCellDef="let row">
		  <mat-checkbox (click)="$event.stopPropagation()"
						(change)="$event ? selection.toggle(row) : null"
						[checked]="selection.isSelected(row)"
						[aria-label]="checkboxLabel(row)">
		  </mat-checkbox>
		</td>
	  </ng-container>

	  <!-- Position Column -->
	  <ng-container matColumnDef="position">
		<th mat-header-cell *matHeaderCellDef> No. </th>
		<td mat-cell *matCellDef="let element"> {{element.position}} </td>
	  </ng-container>

	  <!-- Name Column -->
	  <ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef> Name </th>
		<td mat-cell *matCellDef="let element"> {{element.name}} </td>
	  </ng-container>

	  <!-- Weight Column -->
	  <ng-container matColumnDef="weight">
		<th mat-header-cell *matHeaderCellDef> Weight </th>
		<td mat-cell *matCellDef="let element"> {{element.weight}} </td>
	  </ng-container>

	  <!-- Symbol Column -->
	  <ng-container matColumnDef="symbol">
		<th mat-header-cell *matHeaderCellDef> Symbol </th>
		<td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
	  </ng-container>

	  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
		  (click)="selection.toggle(row)">
	  </tr>
	</table>
</div>
