<p>Example of the range selection</p>
<div class="calendar-responsive">
	<ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
	</ngb-datepicker>
</div>

<ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<hr>

<pre>From: {{ fromDate | json }} </pre>
<pre>To: {{ toDate | json }} </pre>