<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <!-- Add Order -->
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">List Group</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Basic List Group</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group">
                                <li class="list-group-item">Cras justo odio</li>
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item">Morbi leo risus</li>
                                <li class="list-group-item">Porta ac consectetur ac</li>
                                <li class="list-group-item">Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">List Active items</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group">
                                <li class="list-group-item active">Cras justo odio</li>
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item">Morbi leo risus</li>
                                <li class="list-group-item">Porta ac consectetur ac</li>
                                <li class="list-group-item">Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">List Disabled items</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group">
                                <li class="list-group-item disabled">Cras justo odio</li>
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item">Morbi leo risus</li>
                                <li class="list-group-item">Porta ac consectetur ac</li>
                                <li class="list-group-item">Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Links and buttons items</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <div class="list-group"><a href="javascript:void(0);" class="list-group-item list-group-item-action active">Cras
                                justo odio </a><a href="javascript:void(0);" class="list-group-item list-group-item-action">Dapibus
                                ac facilisis in</a> <a href="javascript:void(0);" class="list-group-item list-group-item-action">Morbi
                            leo risus</a>
                            <a href="javascript:void(0);" class="list-group-item list-group-item-action">Porta
                                ac consectetur
                                ac</a> <a href="javascript:void(0);" class="list-group-item list-group-item-action disabled">Vestibulum
                            at eros</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Flush</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Cras justo odio</li>
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item">Morbi leo risus</li>
                                <li class="list-group-item">Porta ac consectetur ac</li>
                                <li class="list-group-item">Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">With badges</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Cras justo odio <span class="badge badge-primary badge-pill text-white">14</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Dapibus ac facilisis in <span class="badge badge-primary badge-pill text-white">2</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Cras justo odio <span class="badge badge-primary badge-pill text-white">14</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Morbi leo risus <span class="badge badge-primary badge-pill text-white">1</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Morbi leo risus <span class="badge badge-primary badge-pill text-white">1</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Custom content</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <div class="list-group">
                                <a href="javascript:void(0);" class="list-group-item list-group-item-action flex-column align-items-start active">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-3 text-white">List group item heading</h5><small>3 days
                                        ago</small>
                                    </div>
                                    <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p><small>Donec id elit non mi
                                    porta.</small>
                                </a>
                                <a href="javascript:void(0);" class="list-group-item list-group-item-action flex-column align-items-start">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-3">List group item heading</h5><small class="text-muted">3
                                        days ago</small>
                                    </div>
                                    <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p><small class="text-muted">Donec
                                    id elit non mi porta.</small>
                                </a>
                                <a href="javascript:void(0);" class="list-group-item list-group-item-action flex-column align-items-start">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-3">List group item heading</h5><small class="text-muted">3
                                        days ago</small>
                                    </div>
                                    <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p><small class="text-muted">Donec
                                    id elit non mi porta.</small>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Contextual</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-list-group">
                            <ul class="list-group">
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item list-group-item-primary">This is a primary list group item</li>
                                <li class="list-group-item list-group-item-secondary">This is a secondary list group item</li>
                                <li class="list-group-item list-group-item-success">This is a success list group item</li>
                                <li class="list-group-item list-group-item-danger">This is a danger list group item
                                </li>
                                <li class="list-group-item list-group-item-warning">This is a warning list group item</li>
                                <li class="list-group-item list-group-item-info">This is a info list group item
                                </li>
                                <li class="list-group-item list-group-item-light">This is a light list group item
                                </li>
                                <li class="list-group-item list-group-item-dark">This is a dark list group item
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Toggle</h4>
                    </div>
                    <div class="card-body">
                        <p>
                            <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                            aria-controls="collapseExample">
                                Toggle
                            </button>
                        </p>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                            <h5>You can collapse this card by clicking Toggle</h5>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->