<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [stroke]="chartOptions.stroke"
    [colors]="chartOptions.colors"
    [title]="chartOptions.title"
    [subtitle]="chartOptions.subtitle"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>