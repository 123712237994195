<mat-tab-group dynamicHeight>
  <mat-tab label="Short tab">
    <div class="example-small-box mat-elevation-z4">
      Small content
    </div>
  </mat-tab>
  <mat-tab label="Long tab">
    <div class="example-large-box mat-elevation-z4">
      Large content
    </div>
  </mat-tab>
</mat-tab-group>
