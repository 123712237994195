<div class="card">
	<div class="card-body">
		<div class="profile-statistics">
			<div class="text-center">
				<div class="row">
					<div class="col">
						<h3 class="m-b-0">150</h3><span>Follower</span>
					</div>
					<div class="col">
						<h3 class="m-b-0">140</h3><span>Place Stay</span>
					</div>
					<div class="col">
						<h3 class="m-b-0">45</h3><span>Reviews</span>
					</div>
				</div>
				<div class="mt-4">
					<a href="javascript:void(0);" class="btn btn-primary mb-1 mr-1">Follow</a> 
					<a href="javascript:void(0);" class="btn btn-primary mb-1" (click)="open(sendMessageModal)">Send Message</a>
				</div>
			</div>
		</div>
	</div>
</div>	
<!-- Modal -->
<ng-template #sendMessageModal let-modal>
	
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Send Message</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row"> 
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Name <span class="required">*</span></label>
						<input type="text" class="form-control" value="Author" name="Author" placeholder="Author">
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Email <span class="required">*</span></label>
						<input type="text" class="form-control" value="Email" placeholder="Email" name="Email">
					</div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Comment</label>
						<textarea rows="8" class="form-control" name="comment" placeholder="Comment"></textarea>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="form-group mb-0">
						<input type="submit" value="Post Comment" class="submit btn btn-primary" name="submit">
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>