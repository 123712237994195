<mat-form-field class="example-user-input mb-3">
  <mat-label>Tooltip position</mat-label>
  <mat-select [formControl]="position">
    <mat-option *ngFor="let positionOption of positionOptions" [value]="positionOption">
      {{positionOption}}
    </mat-option>
  </mat-select>
</mat-form-field>

<button mat-raised-button color="primary"
        matTooltip="Info about the action"
        [matTooltipPosition]="position.value"
        aria-label="Button that displays a tooltip in various positions">
  Action
</button>
