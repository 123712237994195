import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dzmt-checkbox',
  templateUrl: './dzmt-checkbox.component.html',
  styleUrls: ['./dzmt-checkbox.component.css']
})
export class DzmtCheckboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
