<table class="table table-bordered">
  <tbody class="text-small">
    <tr>
      <td class="grid-3" colspan="2" class="text-center">
        <div class="header-container">
          <div class="header-logo d-inline-block">
            <img src=""/>
          </div>
          <div class="header-address d-inline-block text-preline">address</div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="grid-3" colspan="2" class="text-center">
        <div class="airwaybill">airwaybill</div>
      </td>
    </tr>
    <tr>
      <td class="grid-3" colspan="2" class="text-center">
        aaa
        <div class="text-center text-small" style="margin-top: 5px">airwaybill</div>
      </td>
    </tr>
    <tr>
      <td class="grid-4 border-right-none">
        <div class="text-preline">
          <span class="font-weight-bold">Sender : </span><span>shipper->name</span>
          <span class="font-weight-bold">Reff : </span><span>shipper_reff</span>
          <span class="font-weight-bold">Phone Number : </span><span>shipper->phone_number</span>
          <div class="font-weight-bold">Address :</div>
          <div class="text-preline">address</div>
          <br/>
        </div>
      </td>
      <td class="grid-4 border-left-none">
        <div class="text-preline">
          <span class="font-weight-bold">Total Pcs : </span><span>total_pcs</span>
          <span class="font-weight-bold">Weight : </span><span>actual_weight</span>
          <span class="font-weight-bold">Customs Value (USD) : </span><span>customs_value</span>
          <span class="font-weight-bold">Description of Goods : </span><span>description</span>
          <br/>
        </div>
      </td>
    </tr>
    <tr>
      <td class="grid-4 border-right-none">
        <div class="text-preline">
          <span class="font-weight-bold">Receiver : </span><span>consignee->name</span>
          <span class="font-weight-bold">Reff : </span><span>consignee->reff_name</span>
          <span class="font-weight-bold">Phone Number : </span><span>consignee->phone_number</span>
          <div class="font-weight-bold">Address :</div>
          <div class="text-preline">address </div>
        </div>
      </td>
      <td class="grid-4 border-left-none">
        <div class="text-preline">
          <span class="font-weight-bold">City : </span><span>consignee->city</span>
          <span class="font-weight-bold">Country : </span><span>consignee->country</span>
          <span class="font-weight-bold">Postal Code : </span><span>consignee->post_code</span>
          <span class="font-weight-bold">Account No. : </span><span>consignee->arc_number</span>
        </div>
      </td>
    </tr>
    <tr>
      <td class="grid-4" colspan="2" style="border-bottom: 1px solid #000;">
        <div class="text-preline notes-container">
          <span class="font-weight-bold">Notes : </span>
          <div class="text-preline">note</div>
        </div>
        <div class="qrcode-container"><img src="qrcode" class="qrcode d-inline-block mt-2" style="margin-top: 10px !important"></div>
      </td>
    </tr>
  </tbody>
</table>