<p>
  You should use <code>class="nav-pills"</code> if you need pills and the <code>[orientation]</code> input
  to change nav orientation
</p>

<div class="d-sm-flex d-block">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills mb-sm-0 mb-3" orientation="vertical">
    <li ngbNavItem="top">
      <a ngbNavLink>Top</a>
      <ng-template ngbNavContent>
        <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth
          master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
          keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
          salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
      </ng-template>
    </li>
    <li ngbNavItem="middle">
      <a ngbNavLink>Middle</a>
      <ng-template ngbNavContent>
        <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
          farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
          ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
          helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
          8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
          stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
      </ng-template>
    </li>
    <li ngbNavItem="bottom">
      <a ngbNavLink>Bottom</a>
      <ng-template ngbNavContent>
        <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
          Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
          Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
          egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
          tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
          neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="ml-4"></div>
</div>

<pre>Active: {{ active }}</pre>