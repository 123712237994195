import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-basic',
  templateUrl: './popover-basic.component.html',
  styleUrls: ['./popover-basic.component.css']
})
export class PopoverBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
