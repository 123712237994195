<h4 class="text-black mt-3">Basic Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-button>Basic</button>
  <button mat-button color="primary">Primary</button>
  <button mat-button color="accent">Accent</button>
  <button mat-button color="warn">Warn</button>
  <button mat-button disabled>Disabled</button>
  <a mat-button routerLink=".">Link</a>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Raised Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-raised-button>Basic</button>
  <button mat-raised-button color="primary">Primary</button>
  <button mat-raised-button color="accent">Accent</button>
  <button mat-raised-button color="warn">Warn</button>
  <button mat-raised-button disabled>Disabled</button>
  <a mat-raised-button routerLink=".">Link</a>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Stroked Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-stroked-button>Basic</button>
  <button mat-stroked-button color="primary">Primary</button>
  <button mat-stroked-button color="accent">Accent</button>
  <button mat-stroked-button color="warn">Warn</button>
  <button mat-stroked-button disabled>Disabled</button>
  <a mat-stroked-button routerLink=".">Link</a>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Flat Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-flat-button>Basic</button>
  <button mat-flat-button color="primary">Primary</button>
  <button mat-flat-button color="accent">Accent</button>
  <button mat-flat-button color="warn">Warn</button>
  <button mat-flat-button disabled>Disabled</button>
  <a mat-flat-button routerLink=".">Link</a>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Icon Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-icon-button aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button color="primary" aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button color="accent" aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button color="warn" aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button disabled aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Fab Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-fab>
	<mat-icon>menu</mat-icon>
  </button>
  <button mat-fab color="primary">
	<mat-icon>plus_one</mat-icon>
  </button>
  <button mat-fab color="accent">
	<mat-icon>filter_list</mat-icon>
  </button>
  <button mat-fab color="warn">
	<mat-icon>home</mat-icon>
  </button>
  <button mat-fab disabled>
	<mat-icon>plus_one</mat-icon>
  </button>
  <button mat-fab aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <a mat-fab routerLink=".">Link</a>
</div>
<mat-divider></mat-divider>
<h4 class="text-black mt-3">Mini Fab Buttons</h4>
<div class="example-button-row mb-sm-0 mb-2">
  <button mat-mini-fab>
	<mat-icon>menu</mat-icon>
  </button>
  <button mat-mini-fab color="primary">
	<mat-icon>plus_one</mat-icon>
  </button>
  <button mat-mini-fab color="accent">
	<mat-icon>filter_list</mat-icon>
  </button>
  <button mat-mini-fab color="warn">
	<mat-icon>home</mat-icon>
  </button>
  <button mat-mini-fab disabled>
	<mat-icon>plus_one</mat-icon>
  </button>
  <button mat-mini-fab aria-label="Example icon-button with a heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <a mat-mini-fab routerLink=".">Link</a>
</div>
