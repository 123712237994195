<nav mat-tab-nav-bar [backgroundColor]="background">
  <a mat-tab-link *ngFor="let link of links"
     (click)="activeLink = link"
     [active]="activeLink == link"> {{link}} </a>
  <a mat-tab-link disabled>Disabled Link</a>
</nav>

<button mat-raised-button class="example-action-button" (click)="toggleBackground()">
  Toggle background
</button>
<button mat-raised-button class="example-action-button" (click)="addLink()">
  Add link
</button>
