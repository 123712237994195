<div class="card-body">
	<h5>570</h5>
	<p>All Sales</p>
</div>
<div class="chart-wrapper">
	<!-- <canvas id="chart_widget_14"></canvas> -->
	
	<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
	[yaxis]="chartOptions.yaxis"
    [dataLabels]="chartOptions.dataLabels"
    [grid]="chartOptions.grid"
    [stroke]="chartOptions.stroke"
    [title]="chartOptions.title"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>
	
</div>