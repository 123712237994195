import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-tab-group',
  templateUrl: './basic-tab-group.component.html',
  styleUrls: ['./basic-tab-group.component.css']
})
export class BasicTabGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
