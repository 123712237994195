<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Product Grid</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			
			
			<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6" *ngFor="let product of products">
				<div class="card">
					<div class="card-body">
						<div class="new-arrival-product">
							<div class="new-arrivals-img-contnent">
								<img class="img-fluid" src="{{product.image}}" alt="">
							</div>
							<div class="new-arrival-content text-center mt-3">
								<h4><a routerLink="/{{product.url}}">{{product.title}}</a></h4>
								<ul class="star-rating">
									<li *ngFor="let ratings of product.ratings_class "><i class="{{ratings.icon_class}}"></i></li>
								</ul>
								<span class="price">{{product.price}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->