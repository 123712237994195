
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- row -->
    <div class="container-fluid">
        <app-card-slider [data]="cardsArray"></app-card-slider>
        
        <div class="row">
            <div class="col-xl-12">
                <app-card-details></app-card-details>
            </div>
            <div class="col-xl-6">
                <div class="row">
                    <div class="col-xl-12">
                        <app-wallet-coin-chart></app-wallet-coin-chart>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <app-wallet-activity></app-wallet-activity>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->