<p>
  You can easily override open and close triggers by specifying event names (separated by <code>:</code>) in the <code>triggers</code> property.
</p>

<button type="button" class="btn btn-outline-secondary mb-2" ngbTooltip="You see, I show up on click!" triggers="click:blur">
  Click me!
</button>

<hr>
<p>
  Alternatively you can take full manual control over tooltip opening / closing events.
</p>

<button type="button" class="btn btn-outline-secondary mr-2 mb-2" ngbTooltip="What a great tip!" [autoClose]="false" triggers="manual" #t="ngbTooltip" (click)="t.open()">
  Click me to open a tooltip
</button>
<button type="button" class="btn btn-outline-secondary mr-2 mb-2" (click)="t.close()">
  Click me to close a tooltip
</button>