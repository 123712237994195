<form [formGroup]="form">
	<div class="row">
		<div class="col-sm-6">
		  <h4>mat-select</h4>
		  <mat-form-field appearance="fill">
			<mat-label>Favorite Food</mat-label>
			<mat-select [formControl]="foodControl" name="food">
			  <mat-option>None</mat-option>
			  <mat-option *ngFor="let food of foods" [value]="food.value">
				{{food.viewValue}}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  <p>Selected: {{foodControl.value}}</p>
		  </div>
		<div class="col-sm-6">
		  <h4>Native select</h4>
		  <mat-form-field appearance="fill">
			<mat-label>Favorite Car</mat-label>
			<select matNativeControl [formControl]="carControl" name="car">
			  <option value="">None</option>
			  <option *ngFor="let car of cars" [value]="car.value">
				  {{car.viewValue}}
			  </option>
			</select>
		  </mat-form-field>
		  <p>Selected: {{carControl.value}}</p>
	  </div>
	</div>
</form>
