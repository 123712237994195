<div class="content-body">
    <div class="container-fluid">
      <div class="row page-titles mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
              <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="'/admin/app-tr-inbound'">List Inbound</a></li>
              <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Form Inbound</a></li>
            </ol>
          </div>
        </div>
        <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
              <a *ngIf="isAdmin || isAgen || isInbound" href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendAgenModalKurir)"><i class="fa fa-plus scale5 mr-2"></i>Create Kurir By Scan</a>
            <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        </div>
      </div>
      <!-- row -->
      <!-- <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title"></h4>
            </div>
            <div class="card-body">
          
  
            </div>
          </div>
        </div>
  
      </div> -->
  
    </div>
  </div>

  <!-- START KURIR SCAN -->
<!-- Modal ADD -->
<ng-template #sendAgenModalKurir let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Inbound Kurir</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan Connote <span class="required">*</span></label>
                        <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult1($event)"
                        [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
                        (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
                    </div>
                </div>


				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Resi <span class="required">*</span></label>
                        <input type="text" class="form-control" id="no_resi" required [(ngModel)]="no_resi" #name="ngModel" name="no_resi"/>
                    </div>
				</div>

				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Pengirim </label>
                        <input type="text" class="form-control" id="name_pengirim" required [(ngModel)]="name_pengirim" #name="ngModel" name="name_pengirim" />
                    </div>
				</div>
                
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Kurir</label>
                        <select class="form-control default-select" *ngIf="vcomboskurir.length > 0" id="id_kurirs" [(ngModel)]="id_kurirs" name="id_kurirs">
                        <option value="">Please select</option>
                        <option *ngFor="let n of vcomboskurir" value="{{n.id}}">{{n.name}}</option>
                        </select>
                    </div>
				</div>

                <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Type Items</label>
                        <p-dropdown [options]="typeItems" optionLabel="name" [filter]="true" [disabled]="action == 'view'" filterBy="name" [showClear]="true"
                            styleClass="w-100" [(ngModel)]="selectedTypeItem" [ngModelOptions]="{standalone: true}" placeholder="Select a Type" (onChange)="onDropdownChange($event, 'type_id')" (onClear)="onDropdownClear('type_id')" >
                            <ng-template pTemplate="selectedItem">
                                <div class="" *ngIf="selectedTypeItem">
                                <div>{{ selectedTypeItem.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="">
                                <div>{{ item.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
				</div>

                <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Sub Type Items</label>
                        <p-dropdown [options]="subtypeItems" optionLabel="name" [filter]="true" filterBy="name" [disabled]="action == 'view'" [showClear]="true" styleClass="w-100"
                            [(ngModel)]="selectedSubTypeItem" [ngModelOptions]="{standalone: true}" placeholder="Select a Type" (onChange)="onDropdownChange($event, 'subtype_id')" (onClear)="onDropdownClear('subtype_id')">
                            <ng-template pTemplate="selectedItem">
                                <div class="" *ngIf="selectedSubTypeItem">
                                <div>{{ selectedSubTypeItem.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="">
                                <div>{{ item.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
				</div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="text-black font-w600">Keterangan</label>
                        <textarea class="form-control" id="description" rows="5" [(ngModel)]="description" name="description" placeholder="Keterangan"></textarea>
                    </div>
				</div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="text-black font-w600">Berat Paket (KG)</label>
                        <input type="text" class="form-control" id="berat_paket" required [(ngModel)]="berat_paket" #name="ngModel" name="berat_paket" />
                    </div>
				</div>

                <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Upload Foto Paket</label>
                        <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
                        <input type="file" class="form-control" name="fileimage1" id="fileimage1" (change)="onFileSelected1($event)">
                        <input type="file" class="form-control" name="fileimage2" id="fileimage2" (change)="onFileSelected2($event)">
                        <input type="file" class="form-control" name="fileimage3" id="fileimage3" (change)="onFileSelected3($event)">
                    </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
                    <ng-container *ngIf="(isLoading$) === false">
                        <span class="indicator-label">
                            <button (click)="saveBySCAN()" [disabled]="name.invalid" class="submit btn btn-primary">
                                {{isCreated ? 'Submit':'Update'}}
                            </button>
                        </span>
						</ng-container>
						<ng-container *ngIf="isLoading$">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>  
  
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
  