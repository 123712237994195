<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<!-- Add Order -->
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Product Detail</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-xl-3 col-lg-6  col-md-6 col-xxl-5 ">
								<!-- Tab panes -->
								<div class="tab-content">
									<div role="tabpanel" class="tab-pane fade show active" id="first">
										<img class="img-fluid" src="{{imageSrc}}" alt="">
									</div>
								</div>
								<div class="tab-slide-content new-arrival-product mb-4 mb-xl-0">
									<!-- Nav tabs -->
									<ul class="nav slide-item-list mt-3" role="tablist">
										<li role="presentation" class="show">
											<a href="javascript:void(0);" (click)="onClick('1.jpg')" >
												<img class="img-fluid" src="assets/images/tab/1.jpg" alt="" width="50">
											</a>
										</li>
										<li role="presentation">
											<a href="javascript:void(0);" (click)="onClick('2.jpg')" ><img class="img-fluid" src="assets/images/tab/2.jpg" alt="" width="50"></a>
										</li>
										<li role="presentation">
											<a href="javascript:void(0);" (click)="onClick('3.jpg')" ><img class="img-fluid" src="assets/images/tab/3.jpg" alt="" width="50"></a>
										</li>
										<li role="presentation">
											<a href="javascript:void(0);" (click)="onClick('4.jpg')" ><img class="img-fluid" src="assets/images/tab/4.jpg" alt="" width="50"></a>
										</li>
									</ul>
								</div>
							</div>
							<!--Tab slider End-->
							<div class="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
								<div class="product-detail-content">
									<!--Product details-->
									<div class="new-arrival-content pr">
										<h4>Solid Women's V-neck Dark T-Shirt</h4>
										<div class="comment-review star-rating">
											<ul>
												<li><i class="fa fa-star"></i></li>
												<li><i class="fa fa-star"></i></li>
												<li><i class="fa fa-star"></i></li>
												<li><i class="fa fa-star-half-empty"></i></li>
												<li><i class="fa fa-star-half-empty"></i></li>
											</ul>
											<span class="review-text">(34 reviews) / </span><a class="product-review" href="javascript:void(0);" (click)="open(reviewModal)">Write a review?</a>
										</div>
										<div class="d-table mb-2">
											<p class="price float-left d-block">$325.00</p>
										</div>
										<p>Availability: <span class="item"> In stock <i
										class="fa fa-shopping-basket"></i></span>
										</p>
										<p>Product code: <span class="item">0405689</span> </p>
										<p>Brand: <span class="item">Lee</span></p>
										<p>Product tags:&nbsp;&nbsp;
											<span class="badge badge-success light mr-1">bags</span>
											<span class="badge badge-success light mr-1">clothes</span>
											<span class="badge badge-success light mr-1">shoes</span>
											<span class="badge badge-success light mr-1">dresses</span>
										</p>
										<p class="text-content">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
										If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
										<div class="d-flex align-items-end flex-wrap mt-4">
											<div class="filtaring-area mb-3 mr-3">
												<div class="size-filter">
													<h4 class="m-b-15">Select size</h4>
													
													<div class="btn-group" data-toggle="buttons">
														<label class="btn btn-outline-primary light mb-0 {{sizeClass == 'xs' ? 'active' : ''}}" >
                                                            <input type="radio" class="position-absolute invisible" name="options" id="option5" (click)="toggleSizeClass('xs')"> XS
                                                        </label>
														<label class="btn btn-outline-primary light mb-0 {{sizeClass == 'sm' ? 'active' : ''}}">
                                                            <input type="radio" class="position-absolute invisible" name="options" id="option1" (click)="toggleSizeClass('sm')">SM
                                                        </label>
														<label class="btn btn-outline-primary light mb-0 {{sizeClass == 'md' ? 'active' : ''}}">
                                                            <input type="radio" class="position-absolute invisible" name="options" id="option2" (click)="toggleSizeClass('md')"> MD
                                                        </label>
														<label class="btn btn-outline-primary light mb-0 {{sizeClass == 'lg' ? 'active' : ''}}">
                                                            <input type="radio" class="position-absolute invisible" name="options" id="option3" (click)="toggleSizeClass('lg')"> LG
                                                        </label>
														<label class="btn btn-outline-primary light mb-0 {{sizeClass == 'xl' ? 'active' : ''}}">
                                                            <input type="radio" class="position-absolute invisible" name="options" id="option4" (click)="toggleSizeClass('xl')"> XL
                                                        </label>
													</div>
												</div>
											</div>
											<!--Quantity start-->
											<div class="col-2 px-0  mb-3 mr-3">
												<input type="number" name="num" class="form-control input-btn input-number" value="1">
											</div>
											<!--Quanatity End-->
											<div class="shopping-cart  mb-3 mr-3">
												<a class="btn btn-primary" href="javascript:void(0)"><i
														class="fa fa-shopping-basket mr-2"></i>Add
													to cart</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- review -->
			<ng-template #reviewModal let-modal>
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Review</h4>
					<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form>
						<div class="text-center mb-4">
							<img class="img-fluid rounded" width="78" src="assets/images/avatar/1.jpg" alt="DexignZone">
						</div>
						<div class="form-group">
							<div class="rating-widget mb-4 text-center">
								<!-- Rating Stars Box -->
								<ngb-rating [max]="5"></ngb-rating>
							</div>
						</div>
						<div class="form-group">
							<textarea class="form-control" placeholder="Comment" rows="5"></textarea>
						</div>
						<button class="btn btn-success btn-block">RATE</button>
					</form>
				</div>
				
			</ng-template>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->