<div class="row">
	<div class="col-sm-6">
	<h4>mat-select</h4>
	<mat-form-field appearance="fill">
	  <mat-label>Choose one</mat-label>
	  <mat-select [formControl]="selected" [errorStateMatcher]="matcher">
		<mat-option>Clear</mat-option>
		<mat-option value="valid">Valid option</mat-option>
		<mat-option value="invalid">Invalid option</mat-option>
	  </mat-select>
	  <mat-hint>Errors appear instantly!</mat-hint>
	  <mat-error *ngIf="selected.hasError('required')">You must make a selection</mat-error>
	  <mat-error *ngIf="selected.hasError('pattern') && !selected.hasError('required')">
		Your selection is invalid
	  </mat-error>
	</mat-form-field>
	</div>
	<div class="col-sm-6">
	<h4>native html select</h4>
	<mat-form-field class="demo-full-width" appearance="fill">
	  <mat-label>Choose one</mat-label>
	  <select matNativeControl [formControl]="nativeSelectFormControl" [errorStateMatcher]="matcher">
		<option value=""></option>
		<option value="valid" selected>Valid option</option>
		<option value="invalid">Invalid option</option>
	  </select>
	  <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
	  <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
		Your selection is invalid
	  </mat-error>
	</mat-form-field>
	</div>
</div>