<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>One</a>
    <ng-template ngbNavContent>
      <p>These navs will always stay in DOM and have no roles</p>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Two</a>
    <ng-template ngbNavContent>
      <p>Because default values have been customized in the config</p>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>