<div class="card">
    <div class="card-header border-0 pb-0">
        <h4 class="fs-20 text-black">Current Graph</h4>
        <div class="dropdown ml-auto dropdown-no-icon" ngbDropdown>
            <div class="btn-link" data-toggle="dropdown" ngbDropdownToggle>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Delete</a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <!-- <canvas id="lineChart2" height="300"></canvas> -->
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [dataLabels]="chartOptions.dataLabels"
            [yaxis]="chartOptions.yaxis"
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend"
            [title]="chartOptions.title"
            [subtitle]="chartOptions.subtitle"
            [colors]="chartOptions.colors"
            [fill]="chartOptions.fill"
            [tooltip]="chartOptions.tooltip"
            [stroke]="chartOptions.stroke"
        ></apx-chart>
    </div>
</div>