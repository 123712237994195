import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-triggers',
  templateUrl: './tooltip-triggers.component.html',
  styleUrls: ['./tooltip-triggers.component.css']
})
export class TooltipTriggersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
