<div id="DZ_W_Todo2" class="widget-media dz-scroll height370" [perfectScrollbar]>
	<ul class="timeline">
		<li>
			<div class="timeline-panel">
				<div class="media mr-2">
					<img alt="image" width="50" src="assets/images/avatar/1.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Dr sultads Send you Photo</h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-info">
					KG
				</div>
				<div class="media-body">
					<h5 class="mb-1">Resport created successfully <span class="badge badge-warning">Warning</span></h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-info light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-success">
					<i class="fa fa-home"></i>
				</div>
				<div class="media-body">
					<h5 class="mb-1">Reminder : Treatment Time!</h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-success light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2">
					<img alt="image" width="50" src="assets/images/avatar/1.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Dr sultads Send you Photo <span class="badge light badge-danger">Danger</span></h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-danger">
					KG
				</div>
				<div class="media-body">
					<h5 class="mb-1">Resport created successfully <span class="badge light badge-success">Success</span></h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-danger light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-primary">
					<i class="fa fa-home"></i>
				</div>
				<div class="media-body">
					<h5 class="mb-1">Reminder : Treatment Time! <span class="badge light badge-success">Success</span></h5>
					<small class="d-block">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu >
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
	</ul>
</div>