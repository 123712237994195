<div class="card-body pb-0">
	<div class="row">
		<div class="col">
			<h5 class="text-white">Power</h5>
			<span class="text-white">2017.1.20</span>
		</div>
		<div class="col text-right">
			<h5 class="text-white"><i class="fa fa-caret-up"></i> 260</h5>
			<span class="text-white">+12.5(2.8%)</span>
		</div>
	</div>
</div>
<div class="chart-wrapper">
	<!-- <canvas id="chart_widget_1"></canvas> -->
	
	<apx-chart
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[dataLabels]="chartOptions.dataLabels"
		[plotOptions]="chartOptions.plotOptions"
		[yaxis]="chartOptions.yaxis"
		[legend]="chartOptions.legend"
		[fill]="chartOptions.fill"
		[stroke]="chartOptions.stroke"
		[tooltip]="chartOptions.tooltip"
		[xaxis]="chartOptions.xaxis"
		[colors]="chartOptions.colors"
		[grid]="chartOptions.grid"
	></apx-chart>
</div>