<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">Transaction</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)">Bill Unpaid</a>
            </li>
          </ol>
          <!-- <h4>List Consignee</h4> -->
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <!-- <a
          [routerLink]="'/admin/form-cleansing'"
          class="btn btn-primary btn-rounded"
          >+ Create</a
        > -->
        <!-- &nbsp;<a href="javascript:void(0);" (click)="open(sendModalFilter)" class="btn btn-primary btn-rounded"> Filter</a> -->
        <!-- &nbsp; <a href="javascript:void(0);" class="btn btn-primary btn-rounded"> Delete All</a> -->
      </div>
    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <!-- <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
            <div class="input-group-append">
                <a href="javascript:void(0);" class="input-group-text"><i class="flaticon-381-search-2"></i></a>
            </div>
            <input type="text" class="form-control" placeholder="Search here">
        </div> -->
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input
          type="text"
          class="form-control"
          placeholder="Search by title"
          [(ngModel)]="title"
          (ngModelChange)="searchChanged($event)"
        />
        <div class="input-group-append">
          <!-- <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button> -->
          <button class="btn" type="button">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3"><i class="las la-download scale5 mr-2"></i>Get Report</a> -->
      <!-- <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3"><i class="las la-calendar scale5 mr-2"></i>Filter Periode</a> -->
      <!-- <mat-form-field appearance="fill" class="style-1 dashboard-select  mb-3">
          <mat-select value="Newest">
            <mat-option value="Newest">Newest</mat-option>
            <mat-option value="Oldest">Oldest</mat-option>
            <mat-option value="Latest">Latest</mat-option>
          </mat-select>
        </mat-form-field> -->
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Bill Unpaid</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">No Invoice</th>
                    <th scope="col">Tanggal</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Jenis</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Penerima</th>
                    <th scope="col">Total</th>
                    <th scope="col">Sisa</th>
                    <th scope="col">Status</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.no_invoice }}
                    </td>
                    <td>
                      {{ tutorial.tanggal_tagihan }}
                    </td>
                    <td>
                      {{ tutorial.kode_tagihan }}
                    </td>
                    <td>
                      {{ tutorial.jenis_tagihan }}
                    </td>
                    <td>
                      {{ tutorial.nama_tagihan }}
                    </td>
                    <td>
                      {{ tutorial.nameconsignee }}
                    </td>
                    <td>
                      {{ currencyStr(tutorial.jumlah_tagihan) }}
                    </td>
                    <td>
                      {{ currencyStr(tutorial.sisa_tagihan) }}
                    </td>
                    <td *ngIf="tutorial.is_status_tagihan == 'N'">
                      Belum Lunas
                    </td>
                    <td *ngIf="tutorial.is_status_tagihan == 'Y'">
                      Lunas
                    </td>
                    <td *ngIf="tutorial.is_status_tagihan == 'N'">
                      <button type="button" class="btn btn-rounded btn-danger"
                      (click)="actionPembayaran(sendMessageModal, tutorial, 'update')"><span
                        class="btn-icon-left text-danger"><i class="fa fa-money color-danger"></i>
                      </span>Bayar</button>&nbsp;
                      <!-- <button
                        type="button"
                        class="btn btn-rounded btn-success"
                        (click)="openData(tutorial, 'view')"
                      >
                        <span class="btn-icon-left text-success"
                          ><i class="fa fa-eye color-success"></i> </span
                        >Detail</button
                      >&nbsp;
                      <button
                        type="button"
                        class="btn btn-rounded btn-info"
                        (click)="openData(tutorial, 'update')"
                      >
                        <span class="btn-icon-left text-info"
                          ><i class="fa fa-pencil color-info"></i> </span
                        >Edit</button
                      >&nbsp;
                      <button (click)="deleteUser(tutorial)" type="button" class="btn btn-rounded btn-danger">
                        <span class="btn-icon-left text-danger"
                          ><i class="fa fa-trash-o color-danger"></i> </span
                        >Remove
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Pembayaran</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <div class="row">
        <!-- <div class="col-lg-6"> -->
          <!-- <div class="form-group">
            <label class="text-black font-w600"
              >ID <span class="required">*</span></label
            > -->
            <input
              type="hidden"
              class="form-control"
              id="id"
              required
              [(ngModel)]="fadd_role.id"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="id"
            />
            <input
              type="hidden"
              class="form-control"
              id="idcleansing"
              required
              [(ngModel)]="fadd_role.idcleansing"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="idcleansing"
            />
            <!-- </div> -->
        <!-- </div> -->
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >No Invoice <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="no_invoice"
              required
              [(ngModel)]="fadd_role.no_invoice"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="no_invoice"
            />
            </div>
        </div>
         <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Jenis Tagihan <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="jenis_tagihan"
              required
              [(ngModel)]="fadd_role.jenis_tagihan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="jenis_tagihan"
            />
            </div>
        </div>
         <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Nama Tagihan <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="nama_tagihan"
              required
              [(ngModel)]="fadd_role.nama_tagihan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="nama_tagihan"
            />
            </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Jumlah Tagihan <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="jumlah_tagihan"
              required
              [(ngModel)]="fadd_role.jumlah_tagihan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="jumlah_tagihan"
            />
            </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="form-group">
            <label class="text-black font-w600"
              >Sisa Tagihan <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="sisa_tagihan"
              required
              [(ngModel)]="fadd_role.sisa_tagihan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'Submit' ? 'Submit' : 'Update'"
              name="sisa_tagihan"
            />
            </div>
        </div> -->
        <div class="col-lg-12">
          <div class="form-group">
            <label class="text-black font-w600"
              >Tanggal Pembayaran <span class="required">*</span></label
            >
            <input
              type="date"
              class="form-control"
              id="tgl_bayar"
              required
              [(ngModel)]="fadd_role.tgl_bayar"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_bayar"
            />
            </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="text-black font-w600"
              >Metode Bayar <span class="required">*</span></label
            >
            <select (change)="onChange($event.target)"
            [ngModelOptions]="{standalone: true}"
            placeholder="Select a Metode Bayar"
            class="form-control default-select" id="metode_bayar" [(ngModel)]="fadd_role.metode_bayar" #name="ngModel" name="metode_bayar">
              <option ng-value="Tunai">Tunai</option>
              <option ng-value="Transfer">Transfer</option>
            </select>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="statusopen">
          <div class="form-group">
            <label class="text-black font-w600"
              >Transfer <span class="required">*</span></label
            >
            <select class="form-control default-select" *ngIf="vcombo.length > 0" id="idrekening" [(ngModel)]="fadd_role.idrekening" #name="ngModel" name="idrekening">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.code_bank}} - {{n.nama_bank}} - {{n.no_rekening}} - {{n.nama_rekening}} - {{n.cabang_bank}}</option>
            </select>
            </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="text-black font-w600"
              >Nominal Pembayaran <span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="jumlah_bayar"
              required
              [(ngModel)]="fadd_role.jumlah_bayar"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="jumlah_bayar"
            />
            </div>
        </div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Keterangan <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.description"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="description" rows="5"></textarea>
          </div>
				</div>
        <div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
          <div class="form-group mb-0">
            <button
              (click)="saveTutorial()"
              [disabled]="name.invalid"
              class="submit btn btn-primary"
            >
              {{ isCreated ? "Submit" : "Update" }}
            </button>
            </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
