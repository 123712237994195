import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graph-market-overview',
  templateUrl: './graph-market-overview.component.html',
  styleUrls: ['./graph-market-overview.component.css']
})
export class GraphMarketOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
