<div class="mb-3">
  <mat-button-toggle-group #colorToggle="matButtonToggleGroup"
                           value="primary"
                           aria-label="Change color">
    <mat-button-toggle value="primary"> Primary </mat-button-toggle>
    <mat-button-toggle value="accent"> Accent </mat-button-toggle>
  </mat-button-toggle-group>
  <span class="example-button-toggle-label"> Color </span>
</div>

<div class="mb-3">
  <mat-button-toggle-group #backgroundColorToggle="matButtonToggleGroup"
                           value="primary"
                           aria-label="Change color">
                               <mat-button-toggle value="primary"> Primary </mat-button-toggle>
    <mat-button-toggle value="accent"> Accent </mat-button-toggle>
  </mat-button-toggle-group>
  <span class="example-button-toggle-label"> Background Color </span>
</div>

<mat-tab-group [color]="colorToggle.value" [backgroundColor]="backgroundColorToggle.value">
  <mat-tab label="First"> Content 1 </mat-tab>
  <mat-tab label="Second"> Content 2 </mat-tab>
  <mat-tab label="Third"> Content 3 </mat-tab>
</mat-tab-group>
