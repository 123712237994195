<div id="pieChart">
                        
  <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [colors]="chartOptions.colors"
      [legend]="chartOptions.legend"
  ></apx-chart>
  
</div>