    <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
        <div class="container-fluid default-autocomplete default-mt-select default-mt-btn default-mt-sidenav">
            <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Hi, welcome back!</h4>
                            <p class="mb-0">Your business dashboard template</p>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">Sidenav</a></li>
                        </ol>
                    </div>
                </div>
            <!-- row -->
            <div class="row">
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Autosize sidenav</h4>
                        </div>
                        <div class="card-body">
                                <app-autosize-sidenav></app-autosize-sidenav>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Drawer with explicit backdrop setting</h4>
                        </div>
                        <div class="card-body">
                                <app-drawer-explicit-backdrop-setting></app-drawer-explicit-backdrop-setting>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <!--**********************************
        Content body end
    ***********************************-->