import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SelectItem, PrimeNGConfig } from "primeng/api";

import * as XLSX from 'xlsx';

export interface Tutorial {
  id?: any;
  titles?: string,
  keterangan?: string,
  is_publish?: string,
  fileimage?: string,
  images?: string,
  event?: string,
  state?: any;
  action?: string
}


@Component({
  selector: 'app-mst-news',
  templateUrl: './mst-news.component.html',
  styleUrls: ['./mst-news.component.css']
})
export class MstNewsComponent implements OnInit {

  newsImg = 'https://api.nissaexpress.com/uploads/images/news/';
  // newsImg = 'http://localhost:39909/uploads/images/news/';
  
  image: File | undefined;
  resData: any;
  selectedFile: any;
  //  selectedFile = null;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  titles: any;
  keterangan: any;
  is_publish: any;
  id: any;


  action: any = '';

  ngOnInit(): void {
    this.retrieveTutorials();
    this.primengConfig.ripple = true;
  }

  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig
  ) {
    this.createForm();   
  }


  createForm() {
    this.angForm = this.fb.group({
      titles: ['', [Validators.required]],
      keterangan: ['', [Validators.required]],
      is_publish: ['', [Validators.required]]
    });

  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = null;
    this.titles = null;
    this.keterangan = null;
    this.modalService.open(content, { size: 'lg' });
  }

 

  // START
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

 

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('news/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }



  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    titles: '',
    keterangan: '',
    fileimage: ''
  };

  submitted!: boolean;
  saveSliders(): void {
    const data = {
      titles: this.titles,
      keterangan: this.keterangan,
      is_publish: this.is_publish
    };

    if (this.isCreated) {
      
      const formData: FormData = new FormData();
      formData.append('titles', this.titles);
      formData.append('keterangan', this.keterangan);
      formData.append('is_publish', this.is_publish);
      formData.append('fileimage', this.selectedFile);

      this.tutorialService.postData('news/create', formData)
        .subscribe(
          async response => {
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // console.log(data, 'data');
      const formData: FormData = new FormData();
      formData.append('titles', data.titles);
      formData.append('is_publish', data.is_publish);
      formData.append('keterangan', data.keterangan);
      formData.append('fileimage', this.selectedFile);

      this.tutorialService.postData('news/update/' + this.id, formData)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editNews(sendMessageModal: any, tutorial: any, action: any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.id = tutorial.id;
    this.titles = tutorial.titles;
    // this.keterangan = tutorial.keterangan;
    // console.log(tutorial.keterangan,'tutorial')
    // console.log(this.ab2str(tutorial.keterangan),'this.ab2str(tutorial.keterangan.data)')
    // this.keterangan = this.ab2str(tutorial.keterangan);
    this.keterangan = tutorial.keterangan;
    this.is_publish = tutorial.is_publish;
    this.fadd_role.action = action;
  }

  deleteSliders(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`news/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  

  file: any;
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  ab2str(buffer:any) {
    var binary = '';
    var bytes = new Uint16Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }


}
