<p>
    Default appearance:
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="bold">Bold</mat-button-toggle>
        <mat-button-toggle value="italic">Italic</mat-button-toggle>
        <mat-button-toggle value="underline">Underline</mat-button-toggle>
    </mat-button-toggle-group>
</p>

<p>
    Legacy appearance:
    <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="bold">Bold</mat-button-toggle>
        <mat-button-toggle value="italic">Italic</mat-button-toggle>
        <mat-button-toggle value="underline">Underline</mat-button-toggle>
    </mat-button-toggle-group>
</p>