import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datepicker-palette-colors',
  templateUrl: './datepicker-palette-colors.component.html',
  styleUrls: ['./datepicker-palette-colors.component.css']
})
export class DatepickerPaletteColorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
