<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Alert</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic Alerts</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-alert-basic></app-alert-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Closable Alert</h4>
                    </div>
                    <div class="card-body">
                        <app-alert-closeable></app-alert-closeable>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Self closing alert</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-alert-slefclosing></app-alert-slefclosing>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom alert</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-alert-custom></app-alert-custom>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of alerts</h4>
                    </div>
                    <div class="card-body">
                        <app-alert-config></app-alert-config>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->