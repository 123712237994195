A typeahead example that gets values from a static <code>string[]</code>
<ul>
  <li><code>debounceTime</code> operator</li>
  <li>kicks in only if 2+ characters typed</li>
  <li>limits to 10 results</li>
</ul>

<label for="typeahead-basic">Search for a state:</label>
<input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"/>
<hr>
<pre>Model: {{ model | json }}</pre>