<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-12 p-md-0">
            <div class="welcome-text">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/admin/app-tr-manifest']">Outbound</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/admin/app-tr-manifest-bag/', idmanifest]">Outbound Bag</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Outbound Bag detail</a></li>
              </ol>
            </div>
          </div>

      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
        <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="opens(contents)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->

    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Outbound Bag detail</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Bag No</th>
                      <th scope="col">Label</th>
                      <th scope="col">Connote</th>
                      <th scope="col">Airwaybill</th>
                      <th scope="col">Create date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <!-- <td>{{ tutorial.manifest_id }}</td> -->
                        <td>{{ tutorial.bag_no }}</td>
                        <td>{{ tutorial.label }}</td>
                        <td>{{ tutorial.connote }}</td>
                        <td>{{ tutorial.airwaybill }}</td>
                        <td>{{ tutorial.date_bag_details }}</td>
                        <!-- <td>{{ tutorial.flight_number }}</td> -->
                        <!-- <td>{{ tutorial.vendor_id }}</td> -->
                        <!-- <td>{{ tutorial.note }}</td> -->
                        <!-- <td>{{ tutorial.status }}</td> -->
                        <td>
                          <!-- <a [routerLink]="['/admin/app-tr-manifest']" class="btn btn-rounded btn-outline-primary mt-sm-4 mt-2"><i class="fa fa-box scale5 mr-3"></i></a> -->
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag Detail">
                            <i class="fa fa-archive color-info"></i></a> -->
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-outline btn-xs btn-dark"><i class="fa fa-archive color-info"></i></a>&nbsp; -->
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag-detail/', tutorial.id ]" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag Detail">
                            <i class="fa fa-archive color-info"></i></a> -->
                          <!-- <button type="button" class="btn btn-rounded btn-info btn-xxs mr-2 mb-2" (click)="editUser(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                            <i class="fa fa-pencil color-info"></i>
                            </button> -->
                            <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteUser(tutorial)" placement="left" ngbTooltip="Delete">
                              <i class="fa fa-trash color-info"></i>
                              </button>
                          <!-- <button type="button" class="btn btn-rounded btn-danger"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button> -->
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- MODAL CREATE -->

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Manifest Bag Detail</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Scan Connote <span class="required">*</span></label>
            <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
              [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
              (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </div>
        </div>
					<!-- <div class="form-group"> -->
						<!-- <label class="text-black font-w600">Manifest ID <span class="required">*</span></label> -->
            <!-- <input
              type="text"
              class="form-control"
              id="manifest_bag_id"
              required
              [(ngModel)]="fadd_role.manifest_bag_id"
              #name="ngModel"
              name="manifest_bag_id"
            /> -->
          <!-- </div> -->
				<!-- </div> -->
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Connote <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="cleansing_id"
              required
              [(ngModel)]="fadd_role.cleansing_id"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="cleansing_id"
            />
          </div>
				</div>


				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
