import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autosize-sidenav',
  templateUrl: './autosize-sidenav.component.html',
  styleUrls: ['./autosize-sidenav.component.css']
})
export class AutosizeSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  showFiller = false;


}
