<ngb-carousel class="default-carousel" #carousel [interval]="1000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
  <ng-template ngbSlide *ngFor="let img of images; index as i">
    <div class="carousel-caption">
      <h3>My slide {{i + 1}} title</h3>
    </div>
    <a href="https://www.google.fr/?q=Number+{{i+1}}" target="_blank" rel="nofollow noopener noreferrer">
      <div class="picsum-img-wrapper">
        <img [src]="img" alt="My image {{i + 1}} description">
      </div>
    </a>
  </ng-template>
</ngb-carousel>

<hr>

<div class="form-check">
  <input type="checkbox" class="form-check-input" id="pauseOnHover" [(ngModel)]="pauseOnHover">
  <label class="form-check-label" for="pauseOnHover">Pause on hover</label>
</div>
<div class="form-check">
  <input type="checkbox" class="form-check-input" id="pauseOnFocus" [(ngModel)]="pauseOnFocus">
  <label class="form-check-label" for="pauseOnFocus">Pause on focus</label>
</div>
<div class="form-check">
  <input type="checkbox" class="form-check-input" id="unpauseOnArrow" [(ngModel)]="unpauseOnArrow">
  <label class="form-check-label" for="unpauseOnArrow">Unpause when clicking on arrows</label>
</div>
<div class="form-check">
  <input type="checkbox" class="form-check-input" id="pauseOnIndicator" [(ngModel)]="pauseOnIndicator">
  <label class="form-check-label" for="pauseOnIndicator">Pause when clicking on navigation indicator</label>
</div>
<button type="button" (click)="togglePaused()" class="btn btn-outline-primary mt-2 btn-sm">
  {{paused ? 'Cycle' : 'Pause' }}
</button>