import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-toggle-appearance',
  templateUrl: './button-toggle-appearance.component.html',
  styleUrls: ['./button-toggle-appearance.component.css']
})
export class ButtonToggleAppearanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
