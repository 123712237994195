<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Carousel</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic Carousel</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-carousel-basic></app-carousel-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Navigation arrows and indicators</h4>
                    </div>
                    <div class="card-body">
                        <app-carousel-navigation></app-carousel-navigation>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Pause/cycle</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-carousel-pause></app-carousel-pause>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of carousels</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-carousel-config></app-carousel-config>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->