import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';


export interface Tutorial {
  id?: any;
  code_resi?: string,
  code?: string,
  name?: string,
  phonecode?: string,
  phone_number?: string,
  address?: string,
  template_option?: string,
  fileimage?: string,
  event?: string,
  // published?: boolean;
  action?: string
}


@Component({
  selector: 'app-mst-kurir',
  templateUrl: './mst-kurir.component.html',
  styleUrls: ['./mst-kurir.component.css']
})
export class MstKurirComponent implements OnInit {
//   imageSrc: string = '';
//   myForm = new FormGroup({
//    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
//    file: new FormControl('', [Validators.required]),
//    fileSource: new FormControl('', [Validators.required])
//  });

 image: File | undefined;
 resData: any;
 selectedFile : any;
//  selectedFile = null;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;

  code_resi: any;
  code: any;
  name: any;
  phone_number: any;
  address: any;
  template_option: any;
  // fileimage: any;
  id: any;

  countries: any[] = [];
  selectedShipperPhoneNumberCountry: any = {};

  action:any = '';
  phonecode: any;

  ngOnInit(): void {
    this.retrieveTutorials();
    this.fetchDropdownValues();
  }

  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder
    ) {
      this.createForm();
    }


  createForm() {
    this.angForm = this.fb.group({
      //  code_resi: ['', [Validators.required ]],
       code: ['', [Validators.required ]],
       name: ['', [Validators.required ]],
      //  phone_number: ['', [Validators.required ]],
      //  address: ['', [Validators.required] ],
      //  template_option: ['', [Validators.required] ]
    });

    // console.log('masuk')

  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = null;
    // this.code_resi = null;
    this.code = null;
    this.name = null;
    // this.phonecode = null;
    // this.phone_number = null;
    // this.address = null;
    // this.template_option = null;
    // this.selectedShipperPhoneNumberCountry = {};
		this.modalService.open(content);
	}

  // START
  onFileSelected(event:any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  onSubmit() {
    // console.log('masuk sini')
    const payload = new FormData();
    // payload.append('code_resi', this.code_resi);
    payload.append('code', this.code);
    payload.append('name', this.name);
    // payload.append('fileimage', this.selectedFile);
    // console.log(this.code, this.name, 'this.code, this.name')

      this.tutorialService.postData('kurirs/create', payload)
      .subscribe(res => {
        // console.log(res);
        alert('Uploaded Successfully.');
      })
    // this.http
    //   .post(`https://srinu.org/Api.php?apicall=upload_sub_cat`, payload, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   .subscribe((data: any) => {
    //     this.resData = data;
    //     console.log(this.resData);
    //   });
  }

  // get f(){
  //   return this.myForm.controls;
  // }

  // onFileChange(event:any) {
  //   const reader = new FileReader();

  //   if(event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {

  //       this.imageSrc = reader.result as string;

  //       this.myForm.patchValue({
  //         fileSource: reader.result
  //       });

  //     };

  //   }
  //   console.log(reader)
  // }

  // submit(){
  //   console.log(this.myForm.value);
  //   const formData: FormData = new FormData();
  //   formData.append('fileimage', this.myForm.value.file);
  //   formData.append('name', this.myForm.value.name);
  //   console.log(formData)
  //   // this.http.post('http://localhost:8001/upload.php', this.myForm.value)
  //   this.tutorialService.postData('kurirs/create', formData)
  //     .subscribe(res => {
  //       console.log(res);
  //       alert('Uploaded Successfully.');
  //     })
  // }
  // END

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('kurirs/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  fetchDropdownValues() {
      const params = this.getRequestParams(this.title, this.page, this.pageSize);
      // console.log(params, 'params')
      this.tutorialService.getcombo(`country/combodata`, params).subscribe((response: any) => {
      this.countries = response.data;
      // console.log(this.countries, 'ss')
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    // code_resi: '',
    code: '',
    name: '',
    // phonecode: '',
    // phone_number: '',
    // address: '',
    // template_option: '',
    // fileimage: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      // code_resi: this.code_resi,
      code: this.code,
      name: this.name,
      // phonecode: this.selectedShipperPhoneNumberCountry,
      // phone_number: this.phone_number,
      // address: this.address,
      // template_option: this.template_option,
      // fileimage: this.fadd_role.fileimage,
    };

    if(this.isCreated) {
      // this.code = this.code;
      // this.name= this.name;
      // this.phone_number= this.phone_number;
      // this.address= this.address;
      // this.template_option= this.template_option;
      // this.fileimage= this.fadd_role.fileimage;
    //   const payload = new FormData();
    // payload.append('code', this.code);
    // payload.append('fileimage', this.selectedFile);
      // const phonecode = data.phonecode.phonecode
      // console.log(this.selectedShipperPhoneNumberCountry.phonecode, 'datacreate phonecode name')

      const formData: FormData = new FormData();
      // formData.append('code_resi', this.code_resi);
      formData.append('code', this.code);
      formData.append('name', this.name);
      // formData.append('phonecode', this.selectedShipperPhoneNumberCountry.phonecode);
      // formData.append('phone_number', this.phone_number);
      // formData.append('address', this.address);
      // formData.append('template_option', this.template_option);
      // formData.append('fileimage', this.selectedFile);
      // formData.append('fileimage', this.fileimage);
      // console.log(formData, 'data')
      // this.tutorialService.postDataI('kurirs/create', formData)
      this.tutorialService.postData('kurirs/create', formData)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // console.log(data, 'data');
      const formData: FormData = new FormData();
      // formData.append('code_resi', data.code_resi);
      formData.append('code', data.code);
      formData.append('name', data.name);
      // formData.append('phonecode', this.selectedShipperPhoneNumberCountry.phonecode);
      // formData.append('phone_number', data.phone_number);
      // formData.append('address', data.address);
      // formData.append('template_option', data.template_option);
      // formData.append('fileimage', this.selectedFile);


      this.tutorialService.postData('kurirs/update/'+this.id, formData)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.id = tutorial.id;
    // this.code_resi = tutorial.code_resi;
    this.code = tutorial.code;
    this.name = tutorial.name;
    // this.phonecode = tutorial.phonecode;
    // this.phone_number = tutorial.phone_number;
    // this.address = tutorial.address;
    // this.template_option = tutorial.template_option;
    // this.fileimage = tutorial.fileimage;
    // console.log(this.countries)
    // let selectedShipper = this.countries.find((e:any) => e.phonecode == this.phonecode);
    // console.log(selectedShipper)
    // this.selectedShipperPhoneNumberCountry = selectedShipper;
    this.fadd_role.action = action;
  }

  deleteShippers(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`kurirs/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

   reloadPage(): void {
    window.location.reload();
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)
    // if(tipe === 'shipper_phone') {
      this.selectedShipperPhoneNumberCountry = event.value;
      // console.log('selectedShipperPhoneNumberCountry',this.selectedShipperPhoneNumberCountry);
    // }
  }

  onDropdownClear(tipe: string) {
    // if(tipe === 'shipper_phone') {
      this.selectedShipperPhoneNumberCountry = {};
    // }
  }

}
