<mat-form-field class="example-user-input mb-3">
  <mat-label>Show delay</mat-label>
  <input matInput type="number" [formControl]="showDelay"
         aria-label="Adds a delay between hovering over the button and displaying the tooltip">
  <mat-hint>milliseconds</mat-hint>
</mat-form-field>

<mat-form-field class="example-user-input mb-3">
  <mat-label>Hide delay</mat-label>
  <input matInput type="number" [formControl]="hideDelay"
         aria-label="Adds a delay between hovering away from the button and hiding the tooltip">
  <mat-hint>milliseconds</mat-hint>
</mat-form-field>

<button mat-raised-button color="primary" matTooltip="Info about the action"
        [matTooltipShowDelay]="showDelay.value"
        [matTooltipHideDelay]="hideDelay.value"
        aria-label="Button that displays a tooltip with a customized delay in showing and hiding">
  Action
</button>
