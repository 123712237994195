<div class="card">
                    <div class="card-body d-flex align-items-center">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5566 23.893C21.1991 24.0359 20.8009 24.0359 20.4434 23.893L16.6064 22.3582L21 31.1454L25.3936 22.3582L21.5566 23.893Z" fill="#AC4CBC"></path>
                            <path d="M21 20.8846L26.2771 18.7739L21 10.3304L15.7229 18.7739L21 20.8846Z" fill="#AC4CBC"></path>
                            <path d="M21 0.00012207C9.40213 0.00012207 0.00012207 9.40213 0.00012207 21C0.00012207 32.5979 9.40213 41.9999 21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9871 9.40762 32.5924 0.0129395 21 0.00012207ZM29.8417 20.171L22.3417 35.171C21.9714 35.9121 21.0701 36.2124 20.3294 35.8421C20.0387 35.697 19.8034 35.4617 19.6583 35.171L12.1583 20.171C11.9253 19.7032 11.9519 19.1479 12.2284 18.7043L19.7284 6.70453C20.2269 6.00232 21.1996 5.83661 21.9018 6.33511C22.0451 6.43674 22.1701 6.56125 22.2717 6.70453L29.7712 18.7043C30.0482 19.1479 30.0747 19.7032 29.8417 20.171Z" fill="#AC4CBC"></path>
                        </svg>
                        <div class="ml-3">
                            <h2 class="text-black fs-20 mb-0 font-w600">Digital Cash</h2>
                            <span>ETH (USD) = $148.46 (<span class="text-danger">-12.24%</span>)</span>
                        </div>
                    </div>
                    <div id="widget-chart1">
                        <apx-chart
                            [series]="chartOptions.series"
                            [chart]="chartOptions.chart"
                            [xaxis]="chartOptions.xaxis"
                            [dataLabels]="chartOptions.dataLabels"
                            [yaxis]="chartOptions.yaxis"
                            [labels]="chartOptions.labels"
                            [legend]="chartOptions.legend"
                            [title]="chartOptions.title"
                            [subtitle]="chartOptions.subtitle"
                            [colors]="chartOptions.colors"
                            [markers]="chartOptions.markers"
                            [grid]="chartOptions.grid"
                          ></apx-chart>
                            <!-- [fill]="chartOptions.fill"
                            [tooltip]="chartOptions.tooltip"
                            [stroke]="chartOptions.stroke" -->
                        
                    </div>
                    
                    
                    
                    <div class="card-footer">
                        <div class="row">
                            <div class="col text-center">
                                <h5 class="font-weight-normal">1230</h5>
                                <span>Type A</span>
                            </div>
                            <div class="col text-center">
                                <h5 class="font-weight-normal">1230</h5>
                                <span>Type A</span>
                            </div>
                            <div class="col text-center">
                                <h5 class="font-weight-normal">1230</h5>
                                <span>Type A</span>
                            </div>
                        </div>
                    </div>
                </div>