<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- Leaf node -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    {{node.item}}
  </mat-tree-node>

  <!-- expandable node -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button
            [attr.aria-label]="'Toggle ' + node.item"
            (click)="loadChildren(node)"
            matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.item}}
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore">
    <button mat-button (click)="loadMore(node.loadMoreParentItem)">
      Load more...
    </button>
  </mat-tree-node>
</mat-tree>
