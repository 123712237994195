
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- row -->
    <div class="container-fluid">
        <div class="page-titles px-md-0 px-3">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active"><a href="javascript:void(0)">Crypto</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Coin Details</a></li>
            </ol>
        </div>
        <div class="d-flex flex-wrap mb-3">
            <h4 class="fs-24 text-black mr-auto font-w600 mb-3">Coin Details</h4>
            <div class="card-action coin-tabs mb-3">
                
                <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                    <li class="nav-item " ngbNavItem="Bitcoin">
                        <a class="nav-link bg-warning" data-toggle="tab" href="#Bitcoin" role="tab" aria-selected="false" ngbNavLink>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 9.50011C15.9993 8.67201 15.328 8.00092 14.5001 8H10V11H14.5001C15.328 10.9993 15.9993 10.328 16 9.50011Z" fill="#FFAB2D"/>
                                <path d="M10 16H14.5001C15.3285 16 16 15.3285 16 14.5001C16 13.6715 15.3285 13 14.5001 13H10V16Z" fill="#FFAB2D"/>
                                <path d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM18.0001 14.5713C17.9978 16.4641 16.4641 17.9978 14.5716 17.9999V18.8571C14.5716 19.3305 14.1876 19.7143 13.7144 19.7143C13.2409 19.7143 12.8572 19.3305 12.8572 18.8571V17.9999H11.1431V18.8571C11.1431 19.3305 10.7591 19.7143 10.2859 19.7143C9.8124 19.7143 9.42866 19.3305 9.42866 18.8571V17.9999H6.85733C6.38387 17.9999 6.00013 17.6161 6.00013 17.1429C6.00013 16.6695 6.38387 16.2857 6.85733 16.2857H7.71427V7.71427H6.85733C6.38387 7.71427 6.00013 7.33053 6.00013 6.85707C6.00013 6.38361 6.38387 5.99987 6.85733 5.99987H9.42866V5.14293C9.42866 4.66947 9.8124 4.28573 10.2859 4.28573C10.7593 4.28573 11.1431 4.66947 11.1431 5.14293V5.99987H12.8572V5.14293C12.8572 4.66947 13.2409 4.28573 13.7144 4.28573C14.1879 4.28573 14.5716 4.66947 14.5716 5.14293V5.99987C16.4571 5.99202 17.992 7.5139 18.0001 9.39937C18.0043 10.3978 17.5714 11.3481 16.8152 12C17.5643 12.6445 17.9967 13.5828 18.0001 14.5713Z" fill="#FFAB2D"/>
                            </svg>
                            Bitcoin
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-3 col-xxl-4">
                                    <app-about [data]="aboutBtcArray"></app-about>
                                </div>
                                <div class="col-xl-9 col-xxl-8">
                                    <app-graph-coin-chart></app-graph-coin-chart>
                                </div>
                                <div class="col-xl-6 col-xxl-12">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <app-das2-sell-order></app-das2-sell-order>
                                        </div>
                                        <div class="col-sm-6">
                                            <app-das2-buy-order></app-das2-buy-order>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-xxl-12">	
                                    <app-coin-detail-quick-transfer  [data]="'1.svg'"></app-coin-detail-quick-transfer>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item " ngbNavItem="Ethereum">
                        <a class="nav-link bg-secondary" data-toggle="tab" href="#Ethereum" role="tab" aria-selected="false" ngbNavLink>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.3269 13.7554C12.1177 13.839 11.8846 13.839 11.6753 13.7554L9.42969 12.8571L12.0011 18L14.5725 12.8571L12.3269 13.7554Z" fill="#DC3CCC"/>
                                <path d="M11.9989 12L15.4275 10.8001L11.9989 6L8.57031 10.8001L11.9989 12Z" fill="#DC3CCC"/>
                                <path d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9927 5.37574 18.6243 0.00732425 12 0V0ZM17.0524 11.5263L12.7667 20.0977C12.5551 20.5212 12.04 20.6928 11.6168 20.4812C11.4507 20.3983 11.3162 20.2638 11.2333 20.0977L6.94757 11.5263C6.81443 11.2589 6.8296 10.9416 6.9876 10.6882L11.2733 3.83111C11.5582 3.42984 12.114 3.33515 12.5153 3.62001C12.5972 3.67808 12.6686 3.74923 12.7267 3.83111L17.0121 10.6882C17.1704 10.9416 17.1856 11.2589 17.0524 11.5263V11.5263Z" fill="#DC3CCC"/>
                            </svg>
                            Ethereum
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-3 col-xxl-4">
                                    <app-about [data]="aboutEthArray"></app-about>
                                </div>
                                <div class="col-xl-9 col-xxl-8">
                                    <app-graph-coin-chart></app-graph-coin-chart>
                                </div>
                                <div class="col-xl-6 col-xxl-12">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <app-das2-sell-order></app-das2-sell-order>
                                        </div>
                                        <div class="col-sm-6">
                                            <app-das2-buy-order></app-das2-buy-order>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-xxl-12">	
                                    <app-coin-detail-quick-transfer  [data]="'4.svg'"></app-coin-detail-quick-transfer>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item " ngbNavItem="Dash">
                        <a class="nav-link bg-info" data-toggle="tab" href="#Dash" role="tab" aria-selected="false" ngbNavLink>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9927 5.37574 18.6243 0.00732425 12 0V0ZM7.04462 11.1428H10.4732C10.9466 11.1428 11.3304 11.5265 11.3304 12C11.3304 12.4735 10.9466 12.8572 10.4732 12.8572H7.04462C6.57116 12.8572 6.18742 12.4735 6.18742 12C6.18742 11.5265 6.57142 11.1428 7.04462 11.1428ZM17.7624 9.92331L16.7315 15.0812C16.4887 16.2784 15.4374 17.1401 14.2158 17.1429H7.04462C6.57116 17.1429 6.18742 16.7592 6.18742 16.2857C6.18742 15.8123 6.57142 15.4285 7.04462 15.4285H14.2158C14.621 15.4275 14.9697 15.1418 15.0503 14.7448L16.0814 9.58692C16.173 9.12654 15.8743 8.67924 15.4141 8.58768C15.3595 8.57696 15.304 8.57147 15.2486 8.57147H8.75902C8.28556 8.57147 7.90182 8.18773 7.90182 7.71427C7.90182 7.24081 8.28556 6.85707 8.75902 6.85707H15.2486C16.6648 6.85759 17.8123 8.00567 17.8121 9.42186C17.8121 9.59006 17.7953 9.75799 17.7624 9.92331V9.92331Z" fill="#3693FF"/>
                            </svg>
                            Dash
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-3 col-xxl-4">
                                    <app-about [data]="aboutDashArray"></app-about>
                                </div>
                                <div class="col-xl-9 col-xxl-8">
                                    <app-graph-coin-chart></app-graph-coin-chart>
                                </div>
                                <div class="col-xl-6 col-xxl-12">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <app-das2-sell-order></app-das2-sell-order>
                                        </div>
                                        <div class="col-sm-6">
                                            <app-das2-buy-order></app-das2-buy-order>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-xxl-12">	
                                    <app-coin-detail-quick-transfer  [data]="'2.svg'"></app-coin-detail-quick-transfer>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li class="nav-item  " ngbNavItem="Litecoin">
                        <a class="nav-link bg-primary" data-toggle="tab" href="#Litecoin" role="tab" aria-selected="false" ngbNavLink>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0V0ZM16.2857 18.0001H9.42866C8.9552 18.0001 8.57147 17.6164 8.57147 17.1429C8.57147 17.1024 8.57434 17.0618 8.5801 17.0216L9.22515 12.5054L7.92222 12.8313C7.85421 12.8486 7.78437 12.8572 7.71427 12.8572C7.24081 12.8567 6.85759 12.4727 6.85785 11.9992C6.85838 11.6063 7.12571 11.2642 7.50683 11.1684L9.48674 10.6735L10.2942 5.0213C10.3612 4.55254 10.7954 4.22714 11.2642 4.2941C11.7329 4.36107 12.0583 4.79529 11.9914 5.26404L11.2825 10.2247L14.3636 9.4543C14.8222 9.33737 15.2886 9.61439 15.4053 10.0729C15.5222 10.5315 15.2452 10.9979 14.7866 11.1148C14.784 11.1153 14.7814 11.1161 14.7788 11.1166L11.0204 12.0562L10.4164 16.2857H16.2857C16.7592 16.2857 17.1429 16.6695 17.1429 17.1429C17.1429 17.6161 16.7592 18.0001 16.2857 18.0001Z" fill="#374C98"/>
                            </svg>
                            Litecoin
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-xl-3 col-xxl-4">
                                    <app-about [data]="aboutLitArray"></app-about>
                                </div>
                                <div class="col-xl-9 col-xxl-8">
                                    <app-graph-coin-chart></app-graph-coin-chart>
                                </div>
                                <div class="col-xl-6 col-xxl-12">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <app-das2-sell-order></app-das2-sell-order>
                                        </div>
                                        <div class="col-sm-6">
                                            <app-das2-buy-order></app-das2-buy-order>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-xxl-12">	
                                    <app-coin-detail-quick-transfer  [data]="'5.svg'"></app-coin-detail-quick-transfer>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            
            
            
            
            
            
            
            
            
            
                
            </div>
        </div>
        
        
        
            <div [ngbNavOutlet]="nav" class=""></div>
        
        
        
    
    
    
    
    </div>
</div>
<!--**********************************
    Content body end
***********************************--> 