import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-multiple-sections',
  templateUrl: './card-multiple-sections.component.html',
  styleUrls: ['./card-multiple-sections.component.css']
})
export class CardMultipleSectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
