<mat-form-field>
  <mat-label>List length</mat-label>
  <input matInput [(ngModel)]="length" type="number">
</mat-form-field>

<mat-form-field>
  <mat-label>Page size</mat-label>
  <input matInput [(ngModel)]="pageSize" type="number">
</mat-form-field>
<mat-form-field>
  <mat-label>Page size options</mat-label>
  <input matInput [ngModel]="pageSizeOptions" (ngModelChange)="setPageSizeOptions($event)"
         [ngModelOptions]="{updateOn: 'blur'}" placeholder="Ex. 10,25,50">
</mat-form-field>

<mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent = $event">
</mat-paginator>

<div *ngIf="pageEvent">
  <h5>Page Change Event Properties</h5>
  <div>List length: {{pageEvent.length}}</div>
  <div>Page size: {{pageEvent.pageSize}}</div>
  <div>Page index: {{pageEvent.pageIndex}}</div>
</div>
