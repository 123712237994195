<div class="widget-stat card bg-primary">
	<div class="card-header border-0 pb-0">
		<h3 class="card-title text-white">Total Students</h3>
		<h5 class="text-white mb-0"><i class="fa fa-caret-up"></i> 422</h5>
	</div>
	<div class="card-body text-center pb-3">
		<div class="ico-sparkline">
			<apx-chart class="text-center"
				[series]="chartOptions.series"
				[chart]="chartOptions.chart"
				[labels]="chartOptions.labels"
				[title]="chartOptions.title"
				[theme]="chartOptions.theme"
				[responsive]="chartOptions.responsive"
				[stroke]="chartOptions.stroke"
				[dataLabels]="chartOptions.dataLabels"
			></apx-chart>
		</div>
	</div>
</div>