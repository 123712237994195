<ngb-alert [dismissible]="false">
  If you configure the locale and register the locale data as explained in the
  <a href="https://angular.io/guide/i18n" target="_blank">i18n guide</a>, the date picker will honor
  the locale and use days and months names from the locale data. You can however
  provide a custom service, as demonstrated in this example, to customize the
  days and months names the way you want to.
</ngb-alert>

<p>Datepicker in French</p>

<ngb-datepicker [(ngModel)]="model"></ngb-datepicker>