import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-toggle',
  templateUrl: './accordion-toggle.component.html',
  styleUrls: ['./accordion-toggle.component.css']
})
export class AccordionToggleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
