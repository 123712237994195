import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dzmt-divider',
  templateUrl: './dzmt-divider.component.html',
  styleUrls: ['./dzmt-divider.component.css']
})
export class DzmtDividerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
