<mat-tab-group>
  <mat-tab label="First">
    <ng-template matTabContent>
      Content 1 - Loaded: {{getTimeLoaded(1) | date:'medium'}}
    </ng-template>
  </mat-tab>
  <mat-tab label="Second">
    <ng-template matTabContent>
      Content 2 - Loaded: {{getTimeLoaded(2) | date:'medium'}}
    </ng-template>
  </mat-tab>
  <mat-tab label="Third">
    <ng-template matTabContent>
      Content 3 - Loaded: {{getTimeLoaded(3) | date:'medium'}}
    </ng-template>
  </mat-tab>
</mat-tab-group>
