<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [grid]="chartOptions.grid"
    [fill]="chartOptions.fill"
    [annotations]="chartOptions.annotations"
  ></apx-chart>