<div class="nav-header">
    <a [routerLink]="['/admin/index']" class="brand-logo">
      <!-- <img class="brand-title" src="assets/images/logo-full.png" alt=""> -->
      <img class="brand-title" src="{{imgheader}}" alt="">
    </a>

    <div class="nav-control">
        <div [ngClass]="{'is-active': hamburgerClass, '': !hamburgerClass}" class="hamburger" (click)="toggleHamburgerClass()">
            <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
    </div>
</div>
