<div class="card">
    <div class="card-header d-block d-sm-flex border-0">
        <div>
            <h4 class="fs-20 text-black">Total Pengiriman</h4>
            <p class="mb-0 fs-12">Bulan {{dt2 | date : 'MMM y'}}</p>
        </div>
        <div class="card-action card-tabs mt-3 mt-sm-0">
            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                <!-- <li class="nav-item" ngbNavItem="Harins">
                    <a class="nav-link" data-toggle="tab" href="#Harins" role="tab" aria-selected="false" ngbNavLink>
                        Hari	
                    </a>
                    <ng-template ngbNavContent>
                        <app-harins class="bar-chart"></app-harins>
                    </ng-template>
                </li> -->
                <li class="nav-item" ngbNavItem="Bulanns">
                    <a class="nav-link" data-toggle="tab" href="#Bulanns" role="tab" aria-selected="false" ngbNavLink>
                        Bulan
                    </a>
                    <ng-template ngbNavContent>
                        <app-bulanns class="bar-chart"></app-bulanns>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Tahunns">
                    <a class="nav-link" data-toggle="tab" href="#Tahunns" role="tab" aria-selected="false" ngbNavLink>
                        Tahun
                    </a>
                    <ng-template ngbNavContent>
                        <app-tahunns class="bar-chart"></app-tahunns>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>   
<div class="card-body pb-2">
    <div [ngbNavOutlet]="nav" class=""></div>
</div>
</div>
