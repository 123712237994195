<h1 mat-dialog-title>Favorite Animal</h1>
<div mat-dialog-content>
  My favorite animal is:
  <ul class="pt-3">
    <li>
      <span *ngIf="data.animal === 'panda'">&#10003;</span> Panda
    </li>
    <li>
      <span *ngIf="data.animal === 'unicorn'">&#10003;</span> Unicorn
    </li>
    <li>
      <span *ngIf="data.animal === 'lion'">&#10003;</span> Lion
    </li>
  </ul>
</div>

 
 
 
 