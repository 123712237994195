import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';

export interface Tutorial {
  id?: any;
  code_kategori?: any;
  titles?: any;
  keterangan?: any;
  action?: string;
}

@Component({
  selector: 'app-mst-daftarharga',
  templateUrl: './mst-daftarharga.component.html',
  styleUrls: ['./mst-daftarharga.component.css']
})
export class MstDaftarhargaComponent implements OnInit {

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  vcombo: any = [];
  code_kategori: any;
  titles: any;
  keterangan: any;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    // this.retrieveCombodata();
  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.code_kategori = '';
    this.fadd_role.titles = '';
    this.fadd_role.keterangan = '';
    this.fadd_role.action = 'create';
    this.modalService.open(content);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('daftarharga/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          // console.log(error);
        });
  }

  // retrieveCombodata(): void {
  //   const params = this.getRequestParams(this.title, this.page, this.pageSize);

  //   this.tutorialService.getAlls('menu/view', params)
  //     .subscribe(
  //       response => {
  //         const { data } = response;
  //         this.vcombo = data;
  //       },
  //       error => {
  //         this.presentToast(error.error.errors, 'error');
  //         // console.log(error);
  //       });
  // }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code_kategori: '',
    titles: '',
    keterangan: '',
  };

  submitted!: boolean;
  saveTutorial(): void {
    const data = {
      code_kategori: this.fadd_role.code_kategori,
      titles: this.fadd_role.titles,
      keterangan: this.fadd_role.keterangan,
    };

    if (this.isCreated) {
      this.tutorialService.postData('daftarharga/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('daftarharga/update/' + this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editBank(sendMessageModal: any, tutorial: any, action: any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.code_kategori = tutorial.code_kategori;
    this.fadd_role.titles = tutorial.titles;
    this.fadd_role.keterangan = tutorial.keterangan;
    this.fadd_role.action = action;
  }

  deleteBanks(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`daftarharga/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
