	<!--**********************************
		Content body start
	***********************************-->
	<div class="content-body">
		<div class="container-fluid default-apex-cl">
			<div class="row page-titles mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>Hi, welcome back!</h4>
                        <p class="mb-0">Your business dashboard template</p>
                    </div>
                </div>
                <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0)">Heatmap chart</a></li>
                    </ol>
                </div>
            </div>
			<!-- row -->
			<div class="row">
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Basic</h4>
						</div>
						<div class="card-body">
							
							<app-heatmap-basic></app-heatmap-basic>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Multiple Colors</h4>
						</div>
						<div class="card-body">
							<app-heatmap-multiple-colors></app-heatmap-multiple-colors>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Multiple Colors Flipped</h4>
						</div>
						<div class="card-body">
							<app-heatmap-multiple-colors></app-heatmap-multiple-colors>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Color Range</h4>
						</div>
						<div class="card-body">
							<app-heatmap-color-range></app-heatmap-color-range>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Rounded</h4>
						</div>
						<div class="card-body">
							<app-heatmap-rounded></app-heatmap-rounded>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<!--**********************************
		Content body end
	***********************************-->