import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from "ng-apexcharts";
import { MasterService } from '../../../../_services/master.service';
// import {
//   ApexNonAxisChartSeries,
//   ApexResponsive,
//   ApexChart,
//   ApexLegend
// } from "ng-apexcharts";

// export type ChartOptions = {
//   series?: ApexNonAxisChartSeries | any;
//   chart?: ApexChart | any;
//   responsive?: ApexResponsive[] | any;
//   labels: any;
//   legend?: ApexLegend | any;
// };

@Component({
  selector: 'app-pie-mar1',
  templateUrl: './pie-mar1.component.html',
  styleUrls: ['./pie-mar1.component.css']
})
export class PieMar1Component implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  chartOptions3: any = {};
  resultdata: any;

  constructor(private apiService: MasterService) {
    let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	  params.monthlys = 'monthlys';

    this.apiService.getAlls('dash/getGrandVendorberatbulan', params)
	  .subscribe(
		response => {
		
		  // var dataseties = [];
      var shippers = [];
      var month = [];
		  for (let i = 0; i < response.length; i++) {
        
        shippers.push(response[i].nama_vendors);
        month.push(response[i].mar_weight);
        // dataseties.push(data1);
		  }
		  // CHARTS
		  this.chartOptions3 = {
        series: month,
        chart: {
          width: 380,
          type: "pie"
        },
        labels: shippers,
      
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 300
              },
              legend: {
                position: "bottom",
                show: true,
              },
              dataLabels: {
                  enabled: false,
              },
            }
          }
        ]
      };
		},
		error => {
		  // console.log(error);
		});
    
  }

  ngOnInit(): void {
  }

}
