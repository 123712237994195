<section class="example-section">
    <mat-checkbox class="example-margin">Check me!</mat-checkbox>
    <mat-checkbox class="example-margin" [disabled]="true">Disabled</mat-checkbox>
</section>

<section class="example-section">
    <span class="example-list-section">
        <mat-checkbox class="example-margin"
        [checked]="allComplete"
        [color]="task.color"
        [indeterminate]="someComplete()"
        (change)="setAll($event.checked)">
            {{task.name}}
        </mat-checkbox>
    </span>
    <span class="example-list-section">
        <ul class="pl-4">
            <li *ngFor="let subtask of task.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed"
                [color]="subtask.color"
                (ngModelChange)="updateAllComplete()">
                    {{subtask.name}}
                </mat-checkbox>
            </li>
        </ul>
    </span>
</section>
