import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-positioning',
  templateUrl: './menu-positioning.component.html',
  styleUrls: ['./menu-positioning.component.css']
})
export class MenuPositioningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
