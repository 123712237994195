<div class="d-inline-block position-relative apex-card-donut">
    
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [plotOptions]="chartOptions.plotOptions"
        [labels]="chartOptions.labels"
        [fill]="chartOptions.fill"
        [stroke]="chartOptions.stroke"
        [responsive]="chartOptions.responsive"
    ></apx-chart>
    
    
</div>
<h5 class="fs-18 text-black">Seconds</h5>
<span>$500</span>