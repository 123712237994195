import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-popover-customclass',
  templateUrl: './popover-customclass.component.html',
  styleUrls: ['./popover-customclass.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PopoverCustomclassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
