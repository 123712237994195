import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-delay',
  templateUrl: './popover-delay.component.html',
  styleUrls: ['./popover-delay.component.css']
})
export class PopoverDelayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
