<mat-form-field>
  <mat-label>Selected tab index</mat-label>
  <input matInput type="number" [formControl]="selected">
</mat-form-field>

<div>
  <button mat-raised-button
          class="example-add-tab-button"
          (click)="addTab(selectAfterAdding.checked)">
    Add new tab
  </button>
  <mat-checkbox #selectAfterAdding> Select tab after adding </mat-checkbox>
</div>

<mat-tab-group [selectedIndex]="selected.value"
               (selectedIndexChange)="selected.setValue($event)">
  <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
    Contents for {{tab}} tab

    <button mat-raised-button
            class="example-delete-tab-button"
            [disabled]="tabs.length === 1"
            (click)="removeTab(index)">
      Delete Tab
    </button>
  </mat-tab>
</mat-tab-group>
