import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputs-with-hints',
  templateUrl: './inputs-with-hints.component.html',
  styleUrls: ['./inputs-with-hints.component.css']
})
export class InputsWithHintsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
