<div class="card-body">
	<div class="row justify-content-between align-items-center">
		<div class="col-auto">
			<h4 class="text-uppercase">74,206 K</h4>
			<span>Lifetime earnings</span>
		</div>
		<div class="chart-wrapper">
				<!-- <canvas id="chart_widget_7"></canvas> -->
		<apx-chart
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[dataLabels]="chartOptions.dataLabels"
		[plotOptions]="chartOptions.plotOptions"
		[yaxis]="chartOptions.yaxis"
		[legend]="chartOptions.legend"
		[fill]="chartOptions.fill"
		[stroke]="chartOptions.stroke"
		[tooltip]="chartOptions.tooltip"
		[xaxis]="chartOptions.xaxis"
		[colors]="chartOptions.colors"
		[grid]="chartOptions.grid"
		[tooltip]="chartOptions.tooltip"
		
	></apx-chart>
			<!-- <div class="chart-wrapper height100">
			</div> -->
		</div>
	</div>
</div>