import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-basic-toggles',
  templateUrl: './button-basic-toggles.component.html',
  styleUrls: ['./button-basic-toggles.component.css']
})
export class ButtonBasicTogglesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
