import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';


export interface Tutorial {
  id?: any;
  name_consignee?: any;
  name?: any;
  reff_name?: any;
  phonecode?: any;
  phone_number?: any;
  id_country?: any;
  country?: any;
  namecountry?: any;
  id_city?: any;
  city?: any;
  namecity?: any;
  post_code?: any;
  address?: any;
  arc_number?: any;
  action?: string;
}

@Component({
  selector: 'app-mst-consignee',
  templateUrl: './mst-consignee.component.html',
  styleUrls: ['./mst-consignee.component.css']
})
export class MstConsigneeComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 10000, 100000, 1000000];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];
  // typeItems:any = [];

  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  subtypeItems: any[] = [];
  selectedSubTypeItem: any | undefined;

  action:any = '';

  countries: any[] = [];
  selectedShipperPhoneNumberCountry: any = {};
  type_id: any;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
    this.retrieveCombodatatypeItems();
    // this.retrieveCombodatasubtypeItems();
    this.fetchDropdownValues();
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)

    if(tipe === 'phone_number') {
      this.selectedShipperPhoneNumberCountry = event.value;
      // console.log('selectedShipperPhoneNumberCountry',this.selectedShipperPhoneNumberCountry);
    } else if(tipe === 'type_id') {
      this.fetchSubItem(event.value.id);
      this.selectedTypeItem = event.value;
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = event.value;
    }
  }

  onDropdownClear(tipe: string) {
    if(tipe === 'phone_number') {
      this.selectedShipperPhoneNumberCountry = {};
    } else if(tipe === 'type_id') {
      this.selectedTypeItem = {};
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = {};
    }
  }

    fetchDropdownValues() {
    // this.selectedPaymentType = event.value;
    // this.selectedShipperPhoneNumberCountry = event.value;

      const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // this.selectedCurrency = event.value;
      this.tutorialService.getcombo(`country/combodata`, params).subscribe((response: any) => {
      this.countries = response.data;
      // console.log(this.countries)
      // if(this.action == 'view' || this.action == 'update'){
      //   let data = {
      //     'value' : this.countries.find(e => e.phonecode ?.value)
      //   }
      //   if(data.value)
      //   this.onDropdownChange(data, 'phone_number');
      // }
    });

  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.fadd_role.id = null;
    this.fadd_role.name = null;
    this.fadd_role.reff_name = null;
    this.fadd_role.phonecode = null;
    this.fadd_role.phone_number = null;
    this.fadd_role.id_country = null;
    this.fadd_role.country = null;
    this.fadd_role.id_city = null;
    this.fadd_role.city = null;
    this.fadd_role.post_code = null;
    this.fadd_role.address = null;
    this.fadd_role.arc_number = null;
    this.selectedShipperPhoneNumberCountry = {};
    this.selectedTypeItem = {};
    this.selectedSubTypeItem = {};
		this.modalService.open(content);
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('consignees/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('consignees/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatatypeItems(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('country/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.typeItems = data;
        // console.log(this.typeItems, 'tset')
        // this.fetchSubItem(this.typeItems.id);
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }
  fetchSubItem(event: any) {
    // console.log(event, 'mantaps')
  //   // const params = this.getRequestParams(this.title, this.page, this.pageSize);
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
  //   // this.id = this.route.snapshot.params['postId'];
    params.country_id = event

  //   // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('states/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.subtypeItems = data;

        let subType = this.subtypeItems.find((e:any) => e.id == this.fadd_role.id_city);
        // console.log(subType, 'subType')
        this.selectedSubTypeItem = subType;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
    // this.apiService
    //   .get(`typeitemsubs/combodata?type_id=${type_id}`)
    //   .subscribe((response: any) => {
    //     this.subTypeItems = response.data;
    //     if(this.action == 'view' || this.action == 'update'){
    //       let data = {
    //         'value' : this.subTypeItems.find(e => e.id === this.form.get('subtype_id')?.value)
    //       }
    //       if(data.value)
    //       this.onDropdownChange(data, 'subtype_id');
    //     }
    //   });
  }
  // retrieveCombodatasubtypeItems(): void {
  //   console.log(this.type_id, 'hh')
  //   const params = this.getRequestParams(this.title, this.page, this.pageSize);

  //   // this.tutorialService.postData('typeitems/create', data)
  //   this.tutorialService.getAlls('typeitemsubs/view', params)
  //   .subscribe(
  //     response => {
  //       const { data } = response;
  //       this.subtypeItems = data;
  //       // console.log(this.vcombo, 'this.vcombo');
  //       // this.count = totalItems;
  //       // console.log(response);
  //     },
  //     error => {
  //       console.log(error);
  //     });
  // }


  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    // code: '',
    name: '',
    reff_name: '',
    phonecode: '',
    phone_number: '',
    id_country: '',
    country: '',
    id_city: '',
    city: '',
    post_code: '',
    address: '',
    arc_number: '',
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      // code: this.fadd_role.code,
      name: this.fadd_role.name,
      reff_name: this.fadd_role.reff_name,
      phonecode: this.fadd_role.phonecode,
      phone_number: this.fadd_role.phone_number,
      id_country: this.fadd_role.id_country,
      country: this.fadd_role.country,
      id_city: this.fadd_role.id_city,
      city: this.fadd_role.city,
      post_code: this.fadd_role.post_code,
      address: this.fadd_role.address,
      arc_number: this.fadd_role.arc_number,
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      // console.log(this.selectedTypeItem)
      // console.log(this.selectedSubTypeItem)
      data.id_country = this.selectedTypeItem.id
      data.country = this.selectedTypeItem.name
      data.id_city = this.selectedSubTypeItem.id
      data.city = this.selectedSubTypeItem.name
      data.phonecode = data.phonecode.phonecode
      // data.country = data.name.name
      // console.log(data, 'data')
      this.tutorialService.postData('consignees/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.selectedTypeItem = {};
            this.selectedSubTypeItem = {};
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      data.id_country = this.selectedTypeItem.id
      data.country = this.selectedTypeItem.name
      data.id_city = this.selectedSubTypeItem.id
      data.city = this.selectedSubTypeItem.name
      data.phonecode = data.phonecode.phonecode

      this.tutorialService.postData('consignees/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.name = tutorial.name;
    this.fadd_role.reff_name = tutorial.reff_name;
    this.fadd_role.phonecode = tutorial.phonecode;
    this.fadd_role.phone_number = tutorial.phone_number;
    this.fadd_role.id_country = tutorial.id_country;
    this.fadd_role.country = tutorial.country;
    this.fadd_role.id_city = tutorial.id_city;
    this.fadd_role.city = tutorial.city;
    this.fadd_role.post_code = tutorial.post_code;
    this.fadd_role.address = tutorial.address;
    this.fadd_role.arc_number = tutorial.arc_number;
    let selectedShipper = this.countries.find((e:any) => e.phonecode == this.fadd_role.phonecode);
    // console.log(selectedShipper)
    this.selectedShipperPhoneNumberCountry = selectedShipper;
    console.log(this.countries, 'this.countries')
    let selectedType = this.countries.find((e:any) => e.id == this.fadd_role.id_country);
    console.log(selectedType,'selectedType')
    this.selectedTypeItem = selectedType;
    this.fetchSubItem(selectedType.id);

    this.fadd_role.action = action;
  }

  deleteConsignee(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`consignees/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  selectShipperPhoneNumberCountry(item: any) {
    this.selectedShipperPhoneNumberCountry = item;
    // console.log(
    //   '🚀 ~ file: cleansing-form.component.ts ~ line 557 ~ CleansingFormComponent ~ this.selectedShipperPhoneNumberCountry',
    //   this.selectedShipperPhoneNumberCountry
    // );
    /* const val = this.form.get('phone_number')?.value;
    this.form.patchValue({
      phone_number: `${item.phonecode}${val}`,
    }); */
  }

}
