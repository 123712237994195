<div id="chart-year">
<apx-chart
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [dataLabels]="chartOptions.dataLabels"
  [plotOptions]="chartOptions.plotOptions"
  [yaxis]="chartOptions.yaxis"
  [xaxis]="chartOptions.xaxis"
  [subtitle]="chartOptions.subtitle"
  [colors]="chartOptions.colors"
  [states]="chartOptions.states"
  [title]="chartOptions.title"
  [tooltip]="chartOptions.tooltip"
></apx-chart>
</div>
<div id="chart-quarter">
<apx-chart
  [series]="chartQuarterOptions.series"
  [chart]="chartQuarterOptions.chart"
  [legend]="chartQuarterOptions.legend"
  [plotOptions]="chartQuarterOptions.plotOptions"
  [yaxis]="chartQuarterOptions.yaxis"
  [xaxis]="chartQuarterOptions.xaxis"
  [grid]="chartQuarterOptions.grid"
  [title]="chartQuarterOptions.title"
></apx-chart>
</div>
