<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <!-- row -->
  <div class="container-fluid">
    <div class="row">

      <div class="col-xl-4 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
          <div class="card-body bg-info rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Payment</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalpayments}}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6" *ngIf="isSuperadmin || isAdmin">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Bill</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalbill}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row" *ngIf="isSuperadmin || isAdmin || isInbound">
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbh}}</h4>
            </div>
          </div>
          <div class="card-body">
            <span class="font-w500 amount mb-0">{{totalberatinbh | number:'1.2-2'}} KG</span>
            <!-- <p><ngb-progressbar type="warning" [value]="10" [striped]="true" [animated]="true"><i> %</i></ngb-progressbar></p>                   -->
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbb}}</h4>
            </div>
          </div>
          <div class="card-body">
            <span class="font-w500 amount mb-0">{{totalberatinbb | number:'1.2-2'}} KG</span>
            <!-- <p><ngb-progressbar type="warning" [value]="10" [striped]="true" [animated]="true"><i> %</i></ngb-progressbar></p>                   -->
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbt}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isSuperadmin || isAdmin">
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Proses</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppt}}</h4>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="row">
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppt1}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <!-- <div class="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
        <div class="widget-stat card bg-primary">
            <div class="card-body  p-4">
                <div class="media">
                    <div class="media-body text-white">
                        <p class="mb-1">Total Pickup Hari Ini</p>
                        <h3 class="text-white">3280</h3>
                        <div class="progress mb-2 bg-secondary">
                            <div class="progress-bar progress-animated bg-light" style="width: 80%"></div>
                        </div>
                        <small>80% Increase in 20 Days</small>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- <div class="card-body bg-warning rounded">
        <div class="d-flex align-items-center">
          <div>
            <span class="text-white">Total Pickup Hari ini</span>
          </div>
        </div>
      </div> -->
      <!-- <h3>25160$</h3> -->
      <div class="col-xl-3 col-sm-3 col-sm-3" *ngIf="isDash || isSuperadmin">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body">
            <h4 class="card-title">Total Pickup Hari Ini</h4>
            <p>{{totalsumtotalinpc}} of {{totalsumtotalinpi}} {{totalweightinbi}} KG</p>
            <p><ngb-progressbar type="warning" [value]="totalinputicpercen2" [striped]="true" [animated]="true"><i> {{totalinputicpercen2}} %</i></ngb-progressbar></p>   
            <small>Total Pickup: {{totalsumtotalinpi}} </small>               
          </div>
        </div>
      </div>

      <ng-container *ngIf="isDash || isSuperadmin">
        <div class="col-xl-3 col-lg-4 col-sm-4" *ngFor="let vInb of datainboundhari; index as x;">
          <div class="widget-stat card">
              <div class="card-body">
                  <h4 class="card-title">{{ vInb.full_name }}</h4>
                  <p class="mb-2">{{vInb.sumInb}} of {{vInb.sumCls}} KG </p>
                    <!-- {{ transformtValue(vInb.sumTInb,vInb.sumTCls) }}  -->
                    <ngb-progressbar class="progress mb-2" type="success" [value]="Math.abs((vInb.sumTCls / vInb.sumTInb) * 100).toFixed(2)" [striped]="true" [animated]="true">
                      <i> {{ transformtValue(vInb.sumTInb,vInb.sumTCls) }} %</i>
                    </ngb-progressbar>
                  <small>Inbound: {{vInb.sumTInb}} Completed : {{vInb.sumTCls}}</small>
              </div>
          </div>
        </div>
      </ng-container>

      <div class="col-xl-3 col-sm-3 col-sm-3" *ngIf="isDash || isSuperadmin">
        <div class="card border coin-holding-card border-success">
          <div class="card-body">
            <h4 class="card-title">Total Pickup</h4>
            <p>{{totalsumtotalinpcm}} of {{totalsumtotalinpim}}</p>
            <p><ngb-progressbar type="success" [value]="totalinputicpercen2m" [striped]="true" [animated]="true"><i> {{totalinputicpercen2m}} %</i></ngb-progressbar></p>   
            <small>Total Pickup: {{totalsumtotalinpim}} </small>               
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-3 col-sm-3" *ngIf="isDash || isSuperadmin">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body">
            <h4 class="card-title">Total Cleansing</h4>
            <p>{{totalsumtotalinpcmn}} of {{totalsumtotalinpcmy}}</p>
            <p><ngb-progressbar type="danger" [value]="totalinputicpercen2mY" [striped]="true" [animated]="true"><i> {{totalinputicpercen2mY}} %</i></ngb-progressbar></p>   
            <small>Total Rounding: {{totalsumtotalinpcmy}} </small>               
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-3 col-sm-3" *ngIf="isDash || isSuperadmin">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body">
            <h4 class="card-title">Total AWB Hari Ini</h4>
            <p>{{totalsumtotalinpiawb}} of {{totalsumtotalinpiawb}}</p>
            <p><ngb-progressbar type="danger" [value]="totalinputicpercen2awb" [striped]="true" [animated]="true"><i> {{totalinputicpercen2awb}} %</i></ngb-progressbar></p>   
            <small>Total AWB: {{totalsumtotalinpcawb}} </small>               
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-3 col-sm-3" *ngIf="isDash || isSuperadmin">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body">
            <h4 class="card-title">Total Pickup Keseluruhan</h4>
            <p>{{totalsumtotalinpcmyear}} of {{totalweightinbcmyear}} KG</p>
            <!-- <p><ngb-progressbar type="danger" [value]="totalinputicpercen2" [striped]="true" [animated]="true"><i> {{totalinputicpercen2}} %</i></ngb-progressbar></p>    -->
            <small>Total Inbound: {{totalsumtotalinpcmyear}} </small>               
          </div>
        </div>
      </div>

      
      <!-- <div class="progress mb-2"> -->
          <!-- <div class="progress-bar progress-animated bg-success" style="width: 30%"></div> -->
      <!-- </div> -->
      <!-- <div class="col-xl-12" *ngIf="isDash || isSuperadmin">
          <app-card-details></app-card-details>
      </div> -->
      <div class="col-xl-12 col-xxl-12" *ngIf="isDash || isSuperadmin">
        <app-graph-market-inbound></app-graph-market-inbound>
      </div>
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
          <div class="card">
              <div class="card-body">
                <h4 class="card-title">Shipper Inbound Pie</h4>
                <p class="mb-0 fs-12">Total Berat By KG {{datenow | date : 'dd MMM y'}}</p>
                <ul ngbNav #nav="ngbNav" [activeId]="6" class="nav-tabs justify-content-right mt-3">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>Jan</a>
                    <ng-template ngbNavContent>
                      <app-pie-jan></app-pie-jan>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>Feb</a>
                    <ng-template ngbNavContent>
                      <app-pie-feb></app-pie-feb>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink>Mar</a>
                    <ng-template ngbNavContent>
                      <app-pie-mar></app-pie-mar>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="4">
                    <a ngbNavLink>Apr</a>
                    <ng-template ngbNavContent>
                      <app-pie-apr></app-pie-apr>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5">
                    <a ngbNavLink>Mei</a>
                    <ng-template ngbNavContent>
                      <app-pie-mei></app-pie-mei>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="6">
                    <ng-template ngbNavContent>
                      <app-pie-juni></app-pie-juni>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="7">
                    <ng-template ngbNavContent>
                      <app-pie-jul></app-pie-jul>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="8">
                    <ng-template ngbNavContent>
                      <app-pie-agt></app-pie-agt>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="9">
                    <ng-template ngbNavContent>
                      <app-pie-sept></app-pie-sept>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="10">
                    <ng-template ngbNavContent>
                      <app-pie-okt></app-pie-okt>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="11">
                    <ng-template ngbNavContent>
                      <app-pie-nov></app-pie-nov>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="12">
                    <ng-template ngbNavContent>
                      <app-pie-des></app-pie-des>
                    </ng-template>
                  </li>
                  <li ngbDropdown class="nav-item">
                    <a href (click)="false" class="nav-link" ngbDropdownToggle>Next</a>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem [ngbNavItem]="6">
                        <a ngbNavLink>Jun</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="7">
                        <a ngbNavLink>Jul</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="8">
                        <a ngbNavLink>Ags</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="9">
                        <a ngbNavLink>Sept</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="10">
                        <a ngbNavLink>Okt</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="11">
                        <a ngbNavLink>Nov</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="12">
                        <a ngbNavLink>Dec</a>
                      </button>
                    </div>
                  </li>
                </ul>
                
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                  
              </div>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <app-graph-market-mitrapie></app-graph-market-mitrapie>
      </div>
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <app-graph-market-vendorpie></app-graph-market-vendorpie>
      </div>
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <app-graph-market-kgvendor></app-graph-market-kgvendor>
      </div>
      <!-- <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <app-graph-market-ns></app-graph-market-ns>
      </div> -->
      
      <!-- PIE -->
        <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
          <div class="card">
              <div class="card-body">
                <h4 class="card-title">Vendor Pie</h4>
                <p class="mb-0 fs-12">Total Berat By KG {{datenow | date : 'dd MMM y'}}</p>
                <ul ngbNav #nav="ngbNav" [activeId]="6" class="nav-tabs justify-content-right mt-3">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>Jan</a>
                    <ng-template ngbNavContent>
                      <app-pie-jan1></app-pie-jan1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>Feb</a>
                    <ng-template ngbNavContent>
                      <app-pie-feb1></app-pie-feb1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink>Mar</a>
                    <ng-template ngbNavContent>
                      <app-pie-mar1></app-pie-mar1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="4">
                    <a ngbNavLink>Apr</a>
                    <ng-template ngbNavContent>
                      <app-pie-apr1></app-pie-apr1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5">
                    <a ngbNavLink>Mei</a>
                    <ng-template ngbNavContent>
                      <app-pie-mei1></app-pie-mei1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="6">
                    <ng-template ngbNavContent>
                      <app-pie-juni1></app-pie-juni1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="7">
                    <ng-template ngbNavContent>
                      <app-pie-jul1></app-pie-jul1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="8">
                    <ng-template ngbNavContent>
                      <app-pie-agt1></app-pie-agt1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="9">
                    <ng-template ngbNavContent>
                      <app-pie-sept1></app-pie-sept1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="10">
                    <ng-template ngbNavContent>
                      <app-pie-okt1></app-pie-okt1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="11">
                    <ng-template ngbNavContent>
                      <app-pie-nov1></app-pie-nov1>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="12">
                    <ng-template ngbNavContent>
                      <app-pie-des1></app-pie-des1>
                    </ng-template>
                  </li>
                  <li ngbDropdown class="nav-item">
                    <a href (click)="false" class="nav-link" ngbDropdownToggle>Next</a>
                    <div ngbDropdownMenu>
                      <button ngbDropdownItem [ngbNavItem]="6">
                        <a ngbNavLink>Jun</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="7">
                        <a ngbNavLink>Jul</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="8">
                        <a ngbNavLink>Ags</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="9">
                        <a ngbNavLink>Sept</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="10">
                        <a ngbNavLink>Okt</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="11">
                        <a ngbNavLink>Nov</a>
                      </button>
                      <button ngbDropdownItem [ngbNavItem]="12">
                        <a ngbNavLink>Dec</a>
                      </button>
                    </div>
                  </li>
                </ul>
                
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                  
              </div>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <app-graph-market-shipper></app-graph-market-shipper>
      </div>
      <!-- PIE -->
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <div class="card">
            <div class="card-body">
              <h4 class="card-title">Shipment Paket Proses Pie</h4>
              <p class="mb-0 fs-12">Total Berat By KG {{datenow | date : 'dd MMM y'}}</p>
              <ul ngbNav #nav="ngbNav" [activeId]="6" class="nav-tabs justify-content-right mt-3">
                <!-- <li [ngbNavItem]="1">
                  <a ngbNavLink>Jan</a>
                  <ng-template ngbNavContent>
                    <app-pie-jan1></app-pie-jan1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Feb</a>
                  <ng-template ngbNavContent>
                    <app-pie-feb1></app-pie-feb1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>Mar</a>
                  <ng-template ngbNavContent>
                    <app-pie-mar1></app-pie-mar1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4">
                  <a ngbNavLink>Apr</a>
                  <ng-template ngbNavContent>
                    <app-pie-apr1></app-pie-apr1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink>Mei</a>
                  <ng-template ngbNavContent>
                    <app-pie-mei1></app-pie-mei1>
                  </ng-template>
                </li> -->
                <li [ngbNavItem]="6">
                  <ng-template ngbNavContent>
                    <app-pie-junpaket></app-pie-junpaket>
                  </ng-template>
                </li>
                <!-- <li [ngbNavItem]="7">
                  <ng-template ngbNavContent>
                    <app-pie-jul1></app-pie-jul1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="8">
                  <ng-template ngbNavContent>
                    <app-pie-agt1></app-pie-agt1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="9">
                  <ng-template ngbNavContent>
                    <app-pie-sept1></app-pie-sept1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="10">
                  <ng-template ngbNavContent>
                    <app-pie-okt1></app-pie-okt1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="11">
                  <ng-template ngbNavContent>
                    <app-pie-nov1></app-pie-nov1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="12">
                  <ng-template ngbNavContent>
                    <app-pie-des1></app-pie-des1>
                  </ng-template>
                </li> -->
                <li ngbDropdown class="nav-item">
                  <a href (click)="false" class="nav-link" ngbDropdownToggle>Jun</a>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem [ngbNavItem]="6">
                      <a ngbNavLink>Jun</a>
                    </button>
                    <!-- <button ngbDropdownItem [ngbNavItem]="7">
                      <a ngbNavLink>Jul</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="8">
                      <a ngbNavLink>Ags</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="9">
                      <a ngbNavLink>Sept</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="10">
                      <a ngbNavLink>Okt</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="11">
                      <a ngbNavLink>Nov</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="12">
                      <a ngbNavLink>Dec</a>
                    </button> -->
                  </div>
                </li>
              </ul>
              
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                
            </div>
        </div>
    </div>
      <!-- PIE -->
    </div>

      <div class="row"> 
        <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
          <app-graph-market-country></app-graph-market-country>
        </div>
        <!-- PIE -->
      <div class="col-xl-6 col-xxl-6" *ngIf="isDash || isSuperadmin">
        <div class="card">
            <div class="card-body">
              <h4 class="card-title">Shipment Country Proses Pie</h4>
              <p class="mb-0 fs-12">Total Shipment By Country {{datenow | date : 'dd MMM y'}}</p>
              <ul ngbNav #nav="ngbNav" [activeId]="6" class="nav-tabs justify-content-right mt-3">
                <!-- <li [ngbNavItem]="1">
                  <a ngbNavLink>Jan</a>
                  <ng-template ngbNavContent>
                    <app-pie-jan1></app-pie-jan1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Feb</a>
                  <ng-template ngbNavContent>
                    <app-pie-feb1></app-pie-feb1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>Mar</a>
                  <ng-template ngbNavContent>
                    <app-pie-mar1></app-pie-mar1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4">
                  <a ngbNavLink>Apr</a>
                  <ng-template ngbNavContent>
                    <app-pie-apr1></app-pie-apr1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink>Mei</a>
                  <ng-template ngbNavContent>
                    <app-pie-mei1></app-pie-mei1>
                  </ng-template>
                </li> -->
                <li [ngbNavItem]="6">
                  <ng-template ngbNavContent>
                    <app-pie-juncountry></app-pie-juncountry>
                  </ng-template>
                </li>
                <!-- <li [ngbNavItem]="7">
                  <ng-template ngbNavContent>
                    <app-pie-jul1></app-pie-jul1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="8">
                  <ng-template ngbNavContent>
                    <app-pie-agt1></app-pie-agt1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="9">
                  <ng-template ngbNavContent>
                    <app-pie-sept1></app-pie-sept1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="10">
                  <ng-template ngbNavContent>
                    <app-pie-okt1></app-pie-okt1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="11">
                  <ng-template ngbNavContent>
                    <app-pie-nov1></app-pie-nov1>
                  </ng-template>
                </li>
                <li [ngbNavItem]="12">
                  <ng-template ngbNavContent>
                    <app-pie-des1></app-pie-des1>
                  </ng-template>
                </li> -->
                <li ngbDropdown class="nav-item">
                  <a href (click)="false" class="nav-link" ngbDropdownToggle>Jun</a>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem [ngbNavItem]="6">
                      <a ngbNavLink>Jun</a>
                    </button>
                    <!-- <button ngbDropdownItem [ngbNavItem]="7">
                      <a ngbNavLink>Jul</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="8">
                      <a ngbNavLink>Ags</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="9">
                      <a ngbNavLink>Sept</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="10">
                      <a ngbNavLink>Okt</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="11">
                      <a ngbNavLink>Nov</a>
                    </button>
                    <button ngbDropdownItem [ngbNavItem]="12">
                      <a ngbNavLink>Dec</a>
                    </button> -->
                  </div>
                </li>
              </ul>
              
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                
            </div>
        </div>
    </div>
      <!-- PIE -->
      </div>  
      <div class="row">    
      
    

      <div class="col-xl-6 col-xxl-12" *ngIf="isSuperadmin">
        <app-graph-market-periode></app-graph-market-periode>
      </div>
      <div class="col-xl-6 col-xxl-12" *ngIf="isDash || isSuperadmin">
        <app-graph-market-ns></app-graph-market-ns>
      </div>

      <!-- <div class="col-xl-6 col-xxl-12">
        <app-graph-market-overview></app-graph-market-overview>
      </div> -->

      <!-- <div class="col-xl-6 col-xxl-12" *ngIf="isSuperadmin || isAdmin">
        <app-graph-market-user></app-graph-market-user>
      </div> -->

      <!-- <div class="col-xl-12">
        <div class="card">
            <div class="card-header pb-0">
                <div class="mt-3 mt-sm-0">
                    
                    
                    <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                        <li class="nav-item" ngbNavItem="navpills-1">
                            <a class="nav-link" data-toggle="tab" href="#navpills-1" role="tab" aria-selected="false" ngbNavLink>
                                BILL SHIPPERS	
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Shipper</th>
                                                    <th scope="col">Jan</th>
                                                    <th scope="col">Feb</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Thu Oct 17 2019 11:03:53</td>
                                                    <td>BTC</td>
                                                    <td>BTC</td>
                                                    <td>BTC</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem="navpills-2">
                            <a class="nav-link" data-toggle="tab" href="#navpills-2" role="tab" aria-selected="false" ngbNavLink>
                                PAYMENT VENDOR
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Vendor</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Wed Oct 16 2019 09:56:03</td>
                                                <td class="">14374</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem="navpills-3">
                            <a class="nav-link" data-toggle="tab" href="#navpills-3" role="tab" aria-selected="false" ngbNavLink>
                                STOP-LIMIT
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                
                                    <table class="table table-bordered ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Time</th>
                                                <th scope="col">Offer Id</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Stop-limit Price</th>
                                                <th scope="col">Trigger Price</th>
                                                <th scope="col">Buy/Sell</th>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body ">
                <div [ngbNavOutlet]="nav" class=""></div>
            </div>
        </div>
    </div>  -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/4.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalbill}}</h4>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">Total Ton</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">Total New Customer</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">UNCOMPETE PAYMENT</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">COMPLETE PAYMENT</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>
      <!-- <div class="col-lg-4" *ngFor="let coins of coinHolding">
            <div class="card border coin-holding-card {{coins.border_class}}">
                <div class="card-body {{coins.bg_class}} rounded">
                    <div class="d-flex align-items-center">
                        <img src="{{coins.image}}" class="mr-3"/>
                        <div>
                            <h4 class="font-w500 text-white title mb-0">{{coins.name}}</h4>
                            <span class="text-white fs-14 op7">{{coins.short_name}}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-4 align-items-center">
                        <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                            <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white"/>
                                <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)" fill="white"/>
                                <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)" fill="white"/>
                                <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)" fill="white"/>
                            </svg>
                            <h4 class="font-w500 text-white amount mb-0">{{coins.price}}</h4>
                        </div>
                        <div class="mb-2">
                            <img src="{{coins.up_down_image}}" class="mt-1 mr-1"/>
                            <span class="text-white fs-14">{{coins.up_down}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-between border-0">
                    <div class="footer-info font-w600">
                        <span class="text-success pr-2">BUY</span>
                        <span class="text-black">{{coins.buy}}</span>
                    </div>
                    <div class="footer-info font-w600">
                        <span class="text-danger pr-2">SELL</span>
                        <span class="text-black">{{coins.sell}}</span>
                    </div>
                    <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More Details</a>
                </div>
            </div>
        </div> -->
      <!-- <div class="col-xl-3 col-sm-6">
                <app-graph1></app-graph1>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph2></app-graph2>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph3></app-graph3>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph4></app-graph4>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <app-graph-market-overview></app-graph-market-overview>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <app-graph-crypto-statistics></app-graph-crypto-statistics>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <app-quick-transfer [data]="quickTransfer"></app-quick-transfer>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-sm-6">
                        <app-sell-order></app-sell-order>
                    </div>
                    <div class="col-sm-6">
                        <app-buy-order></app-buy-order>
                    </div>
                </div>
            </div> -->

<!--**********************************
    Content body end
***********************************-->
