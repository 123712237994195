<div class="card">
    <div class="card-header border-0 pb-0">
        <h4 class="mb-0 text-black fs-20">Sell Order</h4>
        <div class="dropdown ml-auto dropdown-no-icon" ngbDropdown>
            <div class="btn-link" data-toggle="dropdown" ngbDropdownToggle>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Delete</a>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <div class="table-responsive">
            <table class="table text-center bg-info-hover tr-rounded">
                <thead>
                    <tr>
                        <th class="text-left">Price</th>
                        <th class="text-center">Amount</th>
                        <th class="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">82.3</td>
                        <td>0.15</td>
                        <td class="text-right">$134,12</td>
                    </tr>
                    <tr>
                        <td class="text-left">83.9</td>
                        <td>0.18</td>
                        <td class="text-right">$237,31</td>
                    </tr>
                    <tr>
                        <td class="text-left">84.2</td>
                        <td>0.25</td>
                        <td class="text-right">$252,58</td>
                    </tr>
                    <tr>
                        <td class="text-left">86.2</td>
                        <td>0.35</td>
                        <td class="text-right">$126,26</td>
                    </tr>
                    <tr>
                        <td class="text-left">91.6</td>
                        <td>0.75</td>
                        <td class="text-right">$46,92</td>
                    </tr>
                    <tr>
                        <td class="text-left">92.6</td>
                        <td>0.21</td>
                        <td class="text-right">$123,27</td>
                    </tr>
                    <tr>
                        <td class="text-left">93.9</td>
                        <td>0.55</td>
                        <td class="text-right">$212,56</td>
                    </tr>
                    <tr>
                        <td class="text-left">94.2</td>
                        <td>0.18</td>
                        <td class="text-right">$129,26</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer border-0 pt-0 text-center">
        <a href="javascript:void(0);" class="btn-link">Show more <i class="fa fa-caret-right ml-2 scale-2"></i></a>
    </div>
</div>