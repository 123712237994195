    <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
        <div class="container-fluid default-mt-datepicker default-mt-btn">
            <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Hi, welcome back!</h4>
                            <p class="mb-0">Your business dashboard template</p>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">Datepicker</a></li>
                        </ol>
                    </div>
                </div>
            <!-- row -->
            <div class="row">
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Date range picker comparison ranges</h4>
                        </div>
                        <div class="card-body">
                            
                            <app-comparison-ranges></app-comparison-ranges>
                            
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Date range picker forms integration</h4>
                        </div>
                        <div class="card-body">
                            <app-forms-integration></app-forms-integration>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Basic date range picker</h4>
                        </div>
                        <div class="card-body">
                            <app-basic-date-range></app-basic-date-range>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Date range picker with custom a selection strategy</h4>
                        </div>
                        <div class="card-body">
                            <app-range-custom-selection-strategy></app-range-custom-selection-strategy>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker action buttons</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-action-buttons></app-datepicker-action-buttons>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker open method</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-open-method></app-datepicker-open-method>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker palette colors</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-palette-colors></app-datepicker-palette-colors>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker with custom calendar header</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-custom-calendar-header></app-datepicker-custom-calendar-header>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker with custom date classes</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-custom-date-classes></app-datepicker-custom-date-classes>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Disabled datepicker</h4>
                        </div>
                        <div class="card-body">
                            <app-disabled-datepicker></app-disabled-datepicker>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker input and change events</h4>
                        </div>
                        <div class="card-body">
                            <app-input-change-events></app-input-change-events>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker with filter validation</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-filter-validation></app-datepicker-filter-validation>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker with min & max validation</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-min-max-validation></app-datepicker-min-max-validation>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Basic datepicker</h4>
                        </div>
                        <div class="card-body">
                            <app-basic-datepicker></app-basic-datepicker>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker start date</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-start-date></app-datepicker-start-date>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker touch UI</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-touch-ui></app-datepicker-touch-ui>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Datepicker selected value</h4>
                        </div>
                        <div class="card-body">
                            <app-datepicker-selected-value></app-datepicker-selected-value>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <!--**********************************
        Content body end
    ***********************************-->