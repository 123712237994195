import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datepicker-open-method',
  templateUrl: './datepicker-open-method.component.html',
  styleUrls: ['./datepicker-open-method.component.css']
})
export class DatepickerOpenMethodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
