<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-btn default-mt-select default-autocomplete">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Tooltip</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip that demonstrates auto-hiding when it clips out of its scrolling container</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-auto-hiding></app-tooltip-auto-hiding>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip with a show and hide delay</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-with-show-hide-delay></app-tooltip-with-show-hide-delay>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip that can have a custom class applied</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-with-custom-class></app-tooltip-with-custom-class>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip that can be disabled</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-can-be-disabled></app-tooltip-can-be-disabled>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip that can be manually shown/hidden</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-manually-show-hide></app-tooltip-manually-show-hide>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip with a changing message</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-with-changing-message></app-tooltip-with-changing-message>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip with a show and hide delay</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-with-hide-show-delay></app-tooltip-with-hide-show-delay>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic tooltip</h4>
                    </div>
                    <div class="card-body">
                        <app-basic-tooltip></app-basic-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tooltip with a custom position</h4>
                    </div>
                    <div class="card-body">
                        <app-tooltip-with-custom-position></app-tooltip-with-custom-position>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->