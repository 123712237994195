<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-apex-cl">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Line chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-basic-line-chart></app-basic-line-chart>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line with Data Labels</h4>
                    </div>
                    <div class="card-body">
                        <app-line-chart-with-datalabels></app-line-chart-with-datalabels>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Zoomable Timeseries</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-zoomable-timeseries></app-zoomable-timeseries>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line Chart with Annotations</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-line-with-annotations></app-line-with-annotations>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Syncing charts</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-syncing-charts></app-syncing-charts>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Brush chart</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-brush-chart></app-brush-chart>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepline</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-stepline-chart></app-stepline-chart>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Missing / null values</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-missing-or-null-values></app-missing-or-null-values>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Gradient</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-gradient-line-chart></app-gradient-line-chart>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dashed</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-dashed-line-chart></app-dashed-line-chart>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->