<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-apex-cl">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Pie chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Simple</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-pie-simple></app-pie-simple>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Simple Donut</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-pie-donut></app-pie-donut>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Monochrome Pie</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-pie-monochrome></app-pie-monochrome>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Gradient Donut</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-pie-gradient-donut></app-pie-gradient-donut>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Semi Donut</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-semi-donut></app-semi-donut>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Donut with Pattern</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-donut-with-pattern></app-donut-with-pattern>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->