import { Component, OnInit, ViewChild } from '@angular/core';
import { MasterService } from '../../../../_services/master.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
  ApexMarkers,
  ApexGrid,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  plotOptions: ApexPlotOptions | any;
  yaxis: ApexYAxis | any;
  xaxis: ApexXAxis | any;
  fill: ApexFill | any;
  tooltip: ApexTooltip | any;
  stroke: ApexStroke | any;
  legend: ApexLegend | any;
  colors: string[] | any;
  responsive: ApexResponsive[] | any;
  markers: ApexMarkers | any;
  grid: ApexGrid | any;
};

@Component({
  selector: 'app-harimitrapie',
  templateUrl: './harimitrapie.component.html',
  styleUrls: ['./harimitrapie.component.css'],
})
export class HarimitrapieComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  chartOptions: any = {};
  resultdata: any;

  // nameShips: any = [];
  // totalshipments: any = [];

  constructor(private apiService: MasterService) {
    let params: any = {};
    // params[`title`] = 'searchTitle';

    params.tglinput = new Date();
    // params.weeklys = 'weeklys';

    this.apiService.getAlls('dash/getGrandInboundberahari', params)
    .subscribe(
      response => {
        //  console.log(response,'response')
        // const { data, totalItems } = response;
        // this.resultdata = data;
        // console.log(this.resultdata,'resuldata')
        // this.count = totalItems;
        // // console.log(response.rows)
        // let rdata = response.rows
        // let sumpembayarans = 0;
        // var totalshipments = [];
        // var totshipments = [];
        // var nmshippers = [];
        // var dataharis = [];
        var dataseties = [];
        for (let i = 0; i < response.length; i++) {
          // const element = rdata[i];
          // console.log(response[i].datahari,'data hari')
          // this.nameShips.push(response[i].nameshippers);
          let data1 = {
            name : response[i].nama_shippers,
            data : [
              response[i].tgl1,
              response[i].tgl2,
              response[i].tgl3,
              response[i].tgl4,
              response[i].tgl5,
              response[i].tgl6,
              response[i].tgl7,
              response[i].tgl8,
              response[i].tgl9,
              response[i].tgl10,
              response[i].tgl11,
              response[i].tgl12,
              response[i].tgl13,
              response[i].tgl14,
              response[i].tgl15,
              response[i].tgl16,
              response[i].tgl17,
              response[i].tgl18,
              response[i].tgl19,
              response[i].tgl20,
              response[i].tgl21,
              response[i].tgl22,
              response[i].tgl23,
              response[i].tgl24,
              response[i].tgl25,
              response[i].tgl26,
              response[i].tgl28,
              response[i].tgl29,
              response[i].tgl30,
              response[i].tgl31
            ],
          }
          // console.log(data1,'data1')

          // let xxone = {
          //   x: response[i].name,
          //   y: response[i].totalshipment,
          // }
          // // let totshipment
          // nmshippers.push(response[i].name);
          // dataharis.push(response[i].datahari);
          // totshipments.push(response[i].values);
          // totalshipments.push(xxone);
          dataseties.push(data1);
          // sumpembayarans += rdata[i].totalbayar;
        }
        console.log(dataseties,'dataseties')
        
        this.chartOptions = {
          series: dataseties,
          chart: {
            type: "bar",
            height: 350
          },
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0"
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded"
            }
          },
          legend:{
            show:true
          },
          dataLabels: {
          enabled: true
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "30",
              "31"
            ]
          },
          yaxis: {
            title: {
              text: "Berat (KG)"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val:any) {
                return "KG " + val + " berat";
              }
            }
          }
        };
        // console.log(nmshippers,'totshipments')
        // this.chartOptions = {
        //   series: dataseties,
        //   chart: {
        //     type: "bar",
        //     height: 350
        //   },
        //   plotOptions: {
        //     bar: {
        //       horizontal: false,
        //       columnWidth: "55%",
        //       endingShape: "rounded"
        //     }
        //   },
        //   dataLabels: {
        //     enabled: false
        //   },
        //   stroke: {
        //     show: true,
        //     width: 2,
        //     colors: ["transparent"]
        //   },
        //   xaxis: {
        //     categories: nmshippers
        //   },
        //   yaxis: {
        //     title: {
        //       text: "$ (thousands)"
        //     }
        //   },
        //   fill: {
        //     opacity: 1
        //   },
        //   tooltip: {
        //     y: {
        //       formatter: function(val:any) {
        //         return "$ " + val + " thousands";
        //       }
        //     }
        //   }
        // };
        // this.chartOptions = {
        //   series: dataseties,
        //   chart: {
        //     height: 350,
        //     type: "bar",
        //     // events: {
        //     //   click: function(chart, w, e) {
        //     //     // console.log(chart, w, e)
        //     //   }
        //     // }
        //   },
        //   colors: [
        //     "#008FFB",
        //     "#00E396",
        //     "#FEB019",
        //     "#FF4560",
        //     "#775DD0"
        //   ],
        //   plotOptions: {
        //     bar: {
        //       columnWidth: "45%",
        //       distributed: true
        //     }
        //   },
        //   legend:{
        //     show:true
        //   },
        //   dataLabels: {
        //     enabled: true
        //   },
        //   grid: {
        //     show: false
        //   },
        //   xaxis: {
        //     categories: nmshippers,
        //     labels: {
        //       style: {
        //         colors: [
        //           "#008FFB",
        //           "#00E396",
        //           "#FEB019",
        //           "#FF4560",
        //           "#775DD0",
        //           "#546E7A",
        //           "#26a69a",
        //           "#D10CE8"
        //         ],
        //         fontSize: "12px"
        //       }
        //     }
        //   }
        // };
        // CHARTS

        // // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
        // this.totalpayments = this.currencyStr(sumpembayarans)
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });

    
  }

  ngOnInit(): void {}
}
