<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Apex Chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-line']">
                            <img class="w-100" src="assets/images/charts/line.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Area</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-area']">
                            <img class="w-100"  src="assets/images/charts/area.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Column</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-column']">
                            <img class="w-100"  src="assets/images/charts/column.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Bar</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-bar']">
                            <img class="w-100"  src="assets/images/charts/bar.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Mixed</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-mixed']">
                            <img  class="w-100" src="assets/images/charts/mixed.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Timeline Charts</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-timeline']">
                            <img class="w-100"  src="assets/images/charts/timeline.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Candlestick Charts</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-candlestick']">
                            <img class="w-100"  src="assets/images/charts/candlestick.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Pie Charts</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-pie']">
                            <img class="w-100"  src="assets/images/charts/pie.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Radar Charts</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-radar']">
                            <img class="w-100"  src="assets/images/charts/radar.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">RadialBar Charts</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-radialbar']">
                            <img class="w-100"  src="assets/images/charts/radar.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Polar Area Charts </h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-polar-area']">
                            <img class="w-100"  src="assets/images/charts/polar-area.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Bubble Charts</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-bubble']">
                            <img class="w-100"  src="assets/images/charts/bubble.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Scatter Charts </h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-scatter']">
                            <img class="w-100"  src="assets/images/charts/scatter.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Heatmap Charts </h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-heatmap']">
                            <img  class="w-100" src="assets/images/charts/heatmap.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Treemap Charts</h4>
                        
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-treemap']">
                            <img class="w-100"  src="assets/images/charts/treemap.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-md-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Sparklines Charts</h4>
                    </div>
                    <div class="card-body">
                        <a [routerLink]="['/admin/apex-sparklines']">
                            <img class="w-100"  src="assets/images/charts/sparklines.png" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->