import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-custom',
  templateUrl: './alert-custom.component.html',
  styleUrls: ['./alert-custom.component.css']
})
export class AlertCustomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
