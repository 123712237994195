<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="top" ngbTooltip="Tooltip on top">
  Tooltip on top
</button>
<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="right" ngbTooltip="Tooltip on right">
  Tooltip on right
</button>
<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="bottom" ngbTooltip="Tooltip on bottom">
  Tooltip on bottom
</button>
<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="left" ngbTooltip="Tooltip on left">
  Tooltip on left
</button>