import { Component, OnInit, ViewChild } from '@angular/core';
import { MasterService } from '../../../../_services/master.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
  ApexMarkers,
  ApexGrid,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  plotOptions: ApexPlotOptions | any;
  yaxis: ApexYAxis | any;
  xaxis: ApexXAxis | any;
  fill: ApexFill | any;
  tooltip: ApexTooltip | any;
  stroke: ApexStroke | any;
  legend: ApexLegend | any;
  colors: string[] | any;
  responsive: ApexResponsive[] | any;
  markers: ApexMarkers | any;
  grid: ApexGrid | any;
};

@Component({
  selector: 'app-hariuser',
  templateUrl: './hariuser.component.html',
  styleUrls: ['./hariuser.component.css'],
})
export class HariuserComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  chartOptions: any = {};
  resultdata: any;

  // nameShips: any = [];
  // totalshipments: any = [];

  constructor(private apiService: MasterService) {
    let params: any = {};
    // params[`title`] = 'searchTitle';

    params.tglinput = new Date();
    // params.weeklys = 'weeklys';

    this.apiService.getAlls('dash/getGrandShipmentShipperweek', params)
    .subscribe(
      response => {
        console.log(response,'response')
        // const { data, totalItems } = response;
        // this.resultdata = data;
        // console.log(this.resultdata,'resuldata')
        // this.count = totalItems;
        // // console.log(response.rows)
        // let rdata = response.rows
        // let sumpembayarans = 0;
        var totalshipments = [];
        var totshipments = [];
        var nmshippers = [];
        for (let i = 0; i < response.length; i++) {
          // const element = rdata[i];
          // console.log(response[i].nameshippers)
          // this.nameShips.push(response[i].nameshippers);
          let xxone = {
            x: response[i].nameshippers,
            y: response[i].totalshipment,
          }
          // let totshipment
          nmshippers.push(response[i].nameshippers);
          totshipments.push(response[i].totalshipment);
          totalshipments.push(xxone);
          // sumpembayarans += rdata[i].totalbayar;
          
        }

        this.chartOptions = {
          series: [
            {
              name: "Total",
              data: totshipments
            }
          ],
          chart: {
            height: 350,
            type: "bar",
          },
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#546E7A",
            "#26a69a",
            "#D10CE8"
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          grid: {
            show: false
          },
          xaxis: {
            categories: nmshippers,
            labels: {
              style: {
                colors: [
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#546E7A",
                  "#26a69a",
                  "#D10CE8"
                ],
                fontSize: "12px"
              }
            }
          }
        };
        // CHARTS

        // // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
        // this.totalpayments = this.currencyStr(sumpembayarans)
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });

    
  }

  ngOnInit(): void {}
}
