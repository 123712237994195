    <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
        <div class="container-fluid default-mt-chips">
            <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Hi, welcome back!</h4>
                            <p class="mb-0">Your business dashboard template</p>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">Chips</a></li>
                        </ol>
                    </div>
                </div>
            <!-- row -->
            <div class="row">
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Chips Autocomplete</h4>
                        </div>
                        <div class="card-body">
                            
                            <app-chips-autocomplete></app-chips-autocomplete>
                            
                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Chips Drag and Drop</h4>
                        </div>
                        <div class="card-body">
                            <app-chips-drag-drop></app-chips-drag-drop>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Chips with input</h4>
                        </div>
                        <div class="card-body">
                            <app-chips-with-input></app-chips-with-input>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Chips basic</h4>
                        </div>
                        <div class="card-body">
                            <app-chips-basic></app-chips-basic>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-xxl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Stacked chips</h4>
                        </div>
                        <div class="card-body">
                            <app-chips-stacked></app-chips-stacked>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <!--**********************************
        Content body end
    ***********************************-->