import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dzmt-form-field',
  templateUrl: './dzmt-form-field.component.html',
  styleUrls: ['./dzmt-form-field.component.css']
})
export class DzmtFormFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
