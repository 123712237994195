import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { ActivatedRoute, Router } from '@angular/router';

export interface Tutorial {
  id?: any;
  idrole?: string,
  idmenu?: string,
  action?: string;
}

@Component({
  selector: 'app-mst-rolesdetail',
  templateUrl: './mst-rolesdetail.component.html',
  styleUrls: ['./mst-rolesdetail.component.css']
})
export class MstRolesdetailComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];
  id: any;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    // this.id = this.route.snapshot.params['postId'];
    // console.log(this.id, 'thisid');
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {


    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.id = this.route.snapshot.params['postId'];
    params.idrole = this.id
    // console.log(this.id, 'thisid');
    // console.log(params)

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('rolemenuaccess/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('menu/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    idrole:this.route.snapshot.params['postId'],
    idmenu:'',
  };

  submitted!:boolean;
  saveRoledetail(): void {
    const data = {
      idrole: this.fadd_role.idrole,
      idmenu: this.fadd_role.idmenu,
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('rolemenuaccess/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('rolemenuaccess/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.idrole = tutorial.idrole;
    this.fadd_role.idmenu = tutorial.idmenu;
    this.fadd_role.action = action;
  }

  deleteRoledetail(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`rolemenuaccess/delete/${data.id}`)
        .subscribe(
          async response => {
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  // reloadPage(): void {
  //   // window.location.reload();
  //   // this.token.signOut();
	// 	// window.location.reload();
	// 	this.router.navigate(['admin/app-tr-manifest-bag/', this.fadd_role.idrole]);
  //   // this.router.navigate(["admin/index"]);
  // }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
