import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appearance-variants',
  templateUrl: './appearance-variants.component.html',
  styleUrls: ['./appearance-variants.component.css']
})
export class AppearanceVariantsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
