<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Datepicker</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic datepicker</h4>
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-basic></app-datepicker-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Datepicker in a popup</h4>
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-popup></app-datepicker-popup>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Multiple months</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-multiple></app-datepicker-multiple>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Range selection</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-range></app-datepicker-range>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Range selection in a popup</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-range-popup></app-datepicker-range-popup>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Disabled datepicker</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-disabled></app-datepicker-disabled>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom date adapter and formatter</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-adapter></app-datepicker-adapter>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Internationalization of datepickers</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-i18n></app-datepicker-i18n>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom day view</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-customday></app-datepicker-customday>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom month layout</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-custommonth></app-datepicker-custommonth>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Footer template</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-footertemplate></app-datepicker-footertemplate>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Position target</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-positiontarget></app-datepicker-positiontarget>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom keyboard navigation</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        <app-datepicker-footertemplate></app-datepicker-footertemplate>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of datepickers</h4>
                        
                    </div>
                    <div class="card-body default-calendar">
                        
                        <app-datepicker-config></app-datepicker-config>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->