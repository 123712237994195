import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from "ng-apexcharts";
import { MasterService } from '../../../../_services/master.service';


@Component({
  selector: 'app-pie-sept',
  templateUrl: './pie-sept.component.html',
  styleUrls: ['./pie-sept.component.css']
})
export class PieSeptComponent implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;

  chartOptions: any = {};
  resultdata: any;

  constructor(private apiService: MasterService) {
    let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	  params.monthlys = 'monthlys';

    this.apiService.getAlls('dash/getGrandInboundberatbulan', params)
	  .subscribe(
		response => {
		
      var shippers = [];
      var month = [];
		  for (let i = 0; i < response.length; i++) {
        shippers.push(response[i].nama_shippers);
        month.push(response[i].september);
		  }
		  // CHARTS
		  this.chartOptions = {
        series: month,
        chart: {
          width: 380,
          type: "pie"
        },
        labels: shippers,
      
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                width: 300
              },
              legend: {
                position: "bottom",
                show: true,
              },
              dataLabels: {
                  enabled: false,
              },
            }
          }
        ]
      };
		},
		error => {
		  // console.log(error);
		});
    
  }

  ngOnInit(): void {
  }

}
