<div class="card-body pb-0">
	<div class="row">
		<div class="col">
			<h5 class="text-white">Power</h5>
			<span class="text-white">2017.1.20</span>
		</div>
		<div class="col text-right">
			<h5 class="text-white"><i class="fa fa-caret-up"></i> 260</h5>
			<span class="text-white">+12.5(2.8%)</span>
		</div>
	</div>
</div>
<div class="chart-wrapper">
	<!-- <div id="chart_widget_5"></div> -->
	
	<apx-chart
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[xaxis]="chartOptions.xaxis"
		[stroke]="chartOptions.stroke"
		[dataLabels]="chartOptions.dataLabels"
		[yaxis]="chartOptions.yaxis"
		[labels]="chartOptions.labels"
		[legend]="chartOptions.legend"
		[title]="chartOptions.title"
		[subtitle]="chartOptions.subtitle"
		[grid]="chartOptions.grid"
		[colors]="chartOptions.colors"
		[tooltip]="chartOptions.tooltip"
	  ></apx-chart>
	
</div>