<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-stepper default-mt-btn default-select default-autocomplete">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Stepper</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper with editable steps</h4>
                    </div>
                    <div class="card-body">
                        <app-editable-steps></app-editable-steps>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper that displays errors in the steps</h4>
                    </div>
                    <div class="card-body">
                        <app-displays-errors-in-steps></app-displays-errors-in-steps>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper label bottom position</h4>
                    </div>
                    <div class="card-body">
                        <app-label-bottom-position></app-label-bottom-position>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper with optional steps</h4>
                    </div>
                    <div class="card-body">
                        <app-optional-steps></app-optional-steps>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper overview</h4>
                    </div>
                    <div class="card-body">
                        <app-stepper-overview></app-stepper-overview>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper with customized states</h4>
                    </div>
                    <div class="card-body">
                        <app-stepper-customized-states></app-stepper-customized-states>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stepper vertical</h4>
                    </div>
                    <div class="card-body">
                        <app-stepper-vertical></app-stepper-vertical>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->