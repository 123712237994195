<mat-card class="mb-3">
  <mat-card-content>
    <h4 class="example-h2">Slider configuration</h4>

    <section class="example-section">
		<div class="row">
			<div class="col-xl-3 col-xxl-6">
			  <mat-form-field class="example-margin">
				<mat-label>Value</mat-label>
				<input matInput type="number" [(ngModel)]="value">
			  </mat-form-field>
			</div>
			<div class="col-xl-3 col-xxl-6">
			  <mat-form-field class="example-margin">
				<mat-label>Min value</mat-label>
				<input matInput type="number" [(ngModel)]="min">
			  </mat-form-field>
			</div>
			<div class="col-xl-3 col-xxl-6">
			  <mat-form-field class="example-margin">
				<mat-label>Max value</mat-label>
				<input matInput type="number" [(ngModel)]="max">
			  </mat-form-field>
			 </div>
			<div class="col-xl-3 col-xxl-6">
			  <mat-form-field class="example-margin">
				<mat-label>Step size</mat-label>
				<input matInput type="number" [(ngModel)]="step">
			  </mat-form-field>
			</div>
		</div>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="showTicks">Show ticks</mat-checkbox>
      <mat-checkbox class="example-margin" [(ngModel)]="autoTicks" *ngIf="showTicks">
        Auto ticks
      </mat-checkbox>
      <mat-form-field class="example-margin" *ngIf="showTicks && !autoTicks">
        <mat-label>Tick interval</mat-label>
        <input matInput type="number" [(ngModel)]="tickInterval">
      </mat-form-field>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="thumbLabel">Show thumb label</mat-checkbox>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="vertical">Vertical</mat-checkbox>
      <mat-checkbox class="example-margin" [(ngModel)]="invert">Inverted</mat-checkbox>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
    </section>

  </mat-card-content>
</mat-card>

<mat-card class="example-result-card">
  <mat-card-content>
    <h4>Result</h4>

    <mat-slider
        class="example-margin"
        [disabled]="disabled"
        [invert]="invert"
        [max]="max"
        [min]="min"
        [step]="step"
        [thumbLabel]="thumbLabel"
        [tickInterval]="getSliderTickInterval()"
        [(ngModel)]="value"
        [vertical]="vertical">
    </mat-slider>
  </mat-card-content>
</mat-card>
