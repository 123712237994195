import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { ActivatedRoute, Router } from '@angular/router';


export interface Tutorial {
  id?: any;
  state?: string,
  manifest_id?: string,
  bag_no?: string,
  label?: string,
  action?: string;
}

@Component({
  selector: 'app-tr-manifest-bag',
  templateUrl: './tr-manifest-bag.component.html',
  styleUrls: ['./tr-manifest-bag.component.css']
})
export class TrManifestbagComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';
  idmanifest = this.route.snapshot.params['postId'];

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];
  id: any;

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    // this.id = this.route.snapshot.params['postId'];
    // console.log(this.id, 'thisid');
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  opens(contents: any) {
    this.modalService.open(contents);
  }

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {


    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.id = this.route.snapshot.params['postId'];
    params.manifest_id = this.id
    // console.log(this.id, 'thisid');
    // console.log(params)

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('manifests_bags/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    manifest_id:this.route.snapshot.params['postId'],
    bag_no:'',
    label:'',
    // flight_number:'',
    // vendor_id:'',
    // note:'',
    // status:'',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      manifest_id: this.route.snapshot.params['postId'],
      bag_no: this.fadd_role.bag_no,
      label: this.fadd_role.label,
      // flight_number: this.fadd_role.flight_number,
      // vendor_id: this.fadd_role.vendor_id,
      // note: this.fadd_role.note,
      // status: this.fadd_role.status
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('manifests_bags/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('manifests_bags/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.manifest_id = tutorial.manifest_id;
    this.fadd_role.bag_no = tutorial.bag_no;
    this.fadd_role.label = tutorial.label;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  deleteUser(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`manifests_bags/delete/${data.id}`)
        .subscribe(
          async response => {
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  saveBag(): void {
    const data = {
      // manifest_id: this.fadd_role.manifest_id,
      manifest_id: this.route.snapshot.params['postId'],
      bag_no: this.fadd_role.bag_no,
      // label: this.fadd_role.label,
    };

    this.tutorialService.postData('manifests_bags/create_bags', data)
      .subscribe(
        async response => {
          // console.log(response);
          this.fadd_role = {};
          this.presentToast(response.message, 'success');
          this.submitted = true;
          this.modalService.dismissAll();
          this.retrieveTutorials();
          // this.reloadPage();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });

  }

  // reloadPage(): void {
  //   // window.location.reload();
  //   // this.token.signOut();
	// 	// window.location.reload();
	// 	this.router.navigate(['admin/app-tr-manifest-bag/', this.fadd_role.manifest_id]);
  //   // this.router.navigate(["admin/index"]);
  // }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
  
  isAllChecked() {
    return this.tutorials.every(_ => _.state);
  }

  checkAll(ev: any) {
    this.tutorials.forEach(x => x.state = ev.target.checked)
  }

  check(tutorial: any) {
    tutorial.state = !tutorial.state;
  }

  setBulkPrintManbag() {
    var conf = confirm('Anda yakin akan Cetak data ini ?');
    if (conf) {
      let vresult: Tutorial[] = []
      this.tutorials.forEach(async x => {
        if(x.state) {
          vresult.push(x.id)
        }
      });
      // console.log(vresult,'vresult')
      var anchor = document.createElement('a');
      // anchor.href = 'http://localhost/reportnissa/labels/laporan?id='+JSON.stringify(vresult);
      anchor.href = 'https://reports.nissaexpress.com/labels/laporan?id='+JSON.stringify(vresult);
      anchor.target="_blank";
      anchor.click();
      this.router.navigate(['/admin/app-tr-manifest-bag/',this.idmanifest]);

    }
  }

  setBulkPrintManbags() {
    var conf = confirm('Anda yakin akan Cetak data ini ?');
    if (conf) {
      let vresult: Tutorial[] = []
      this.tutorials.forEach(async x => {
        if(x.state) {
          vresult.push(x.id)
        }
      });
      // console.log(vresult,'vresult')
      var anchor = document.createElement('a');
      // anchor.href = 'http://localhost/reportnissa/labels/bags?id='+JSON.stringify(vresult);
      anchor.href = 'https://reports.nissaexpress.com/labels/bags?id='+JSON.stringify(vresult);
      anchor.target="_blank";
      anchor.click();
      this.router.navigate(['/admin/app-tr-manifest-bag/',this.idmanifest]);

    }
  }

}
