<div class="card-body pb-0">
	<div class="row justify-content-between">
		<div class="col-auto">
			<h5>Lorem Ipsum</h5>
		</div>
		<div class="col-auto">
			<h5>
				<span><i class="fa fa-caret-up"></i></span>
				<span>2,250</span>
			</h5>
		</div>
	</div>
</div>
<div class="chart-wrapper">
	<!-- <div id="chart_widget_6"></div> -->
	
	<apx-chart
		[series]="chartOptions.series"
		[chart]="chartOptions.chart"
		[xaxis]="chartOptions.xaxis"
		[stroke]="chartOptions.stroke"
		[dataLabels]="chartOptions.dataLabels"
		[yaxis]="chartOptions.yaxis"
		[labels]="chartOptions.labels"
		[legend]="chartOptions.legend"
		[title]="chartOptions.title"
		[subtitle]="chartOptions.subtitle"
		[grid]="chartOptions.grid"
		[colors]="chartOptions.colors"
		[tooltip]="chartOptions.tooltip"
		[fill]="chartOptions.fill"
	  ></apx-chart>
</div>
<div class="card-footer">
	<div class="row">
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
	</div>
</div>