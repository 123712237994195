import { Component, OnInit, ViewChild } from "@angular/core";
import { MasterService } from '../../../../_services/master.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
  ApexMarkers,
  ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  plotOptions: ApexPlotOptions | any;
  yaxis: ApexYAxis | any;
  xaxis: ApexXAxis | any;
  fill: ApexFill | any;
  tooltip: ApexTooltip | any;
  stroke: ApexStroke | any;
  legend: ApexLegend | any;
  colors: string[] | any;
  responsive: ApexResponsive[] | any;
  markers: ApexMarkers | any;
  grid: ApexGrid | any;
};


@Component({
  selector: 'app-bulanns',
  templateUrl: './bulanns.component.html',
  styleUrls: ['./bulanns.component.css']
})
export class BulannsComponent implements OnInit {

	@ViewChild('chart') chart!: ChartComponent;
	// public chartOptions: Partial<ChartOptions>;
	chartOptions: any = {};
	resultdata: any;
  
	// nameShips: any = [];
	// totalshipments: any = [];
  
	constructor(private apiService: MasterService) {
	  let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	  params.monthlys = 'monthlys';
  
	  this.apiService.getAlls('dash/getGrandsendns', params)
	  .subscribe(
		response => {

			// NEWS
			var totalshipments = [];
		  var totshipments = [];
		  var nmshippers = [];
		  for (let i = 0; i < response.length; i++) {
			// const element = rdata[i];
			// console.log(response[i].nameshippers)
			// this.nameShips.push(response[i].nameshippers);
			let xxone = {
			  x: response[i].nama_bulan,
			  y: response[i].totalshipment,
			}
			nmshippers.push(response[i].nama_bulan);
			totshipments.push(response[i].totalshipment);
			totalshipments.push(xxone);
			
		  }
		  
		  this.chartOptions = {
			series: [
			  {
				name: "Total",
				data: totshipments
			  }
			],
			chart: {
			  height: 350,
			  type: 'area'
			  // events: {
			  //   click: function(chart, w, e) {
			  //     // console.log(chart, w, e)
			  //   }
			  // }
			},
			colors: [
			  "#008FFB",
			  "#00E396",
			  "#FEB019",
			  "#FF4560",
			  "#775DD0",
			  "#546E7A",
			  "#26a69a",
			  "#D10CE8"
			],
			plotOptions: {
			  bar: {
				columnWidth: "45%",
				distributed: true
			  }
			},
			dataLabels: {
			  enabled: false
			},
			legend: {
			  show: false
			},
			grid: {
			  show: false
			},
			xaxis: {
			  categories: nmshippers,
			  labels: {
				style: {
				  colors: [
					"#008FFB",
					"#00E396",
					"#FEB019",
					"#FF4560",
					"#775DD0",
					"#546E7A",
					"#26a69a",
					"#D10CE8"
				  ],
				  fontSize: "12px"
				}
			  }
			}
		  };
		  // CHARTS
		
		//   var dataseties = [];
		//   for (let i = 0; i < response.length; i++) {
		// 	let data1 = {
		// 		name : response[i].nama_bulan,
		// 		data : [ 
		// 			response[i].name_shippers,
		// 		],
		// 	}
		// 	dataseties.push(data1);
		//   }
		  // CHARTS
		//   this.chartOptions = {
		// 	series: dataseties,
		// 	  chart: {
		// 		height: 360,
		// 		toolbar:{
		// 		  show:true
		// 		},
		// 		type: 'area'
		// 	  },
		// 	  colors:[
		// 		'#FFAB2D',
		// 		'#AC4CBC',
		// 		'#FF334F',
		// 		'#F3FF33',
		// 		'#CA33FF',
		// 		'#3361FF',
		// 		"#FEB019",
		// 		"#FF4560",
		// 		"#775DD0"
		// 	],
		// 	  legend:{
		// 		  show:true
		// 	  },
		// 	  dataLabels: {
		// 		enabled: true
		// 	  },
		// 	  stroke: {
		// 		  width:4,
		// 		curve: 'smooth'
		// 	  },
		// 	  xaxis: {
		// 		categories: [
		// 			"Jan",
		// 			"Feb",
		// 			"Mar",
		// 			"Apr",
		// 			"Mei",
		// 			"Jun",
		// 			"Jul",
		// 			"Ags",
		// 			"Sep",
		// 			"Okt",
		// 			"Nov",
		// 			"Des"
		// 		  ],
		// 			labels: {
		// 			 style: {
		// 				colors: '#787878',
		// 				fontSize: '14px',
		// 				 fontFamily: 'Poppins',
		// 				fontWeight: 100,
						
		// 			  },
		// 			},
		// 	  },
		// 	  yaxis: {
		// 		  show:false
		// 	  },
		// 	  fill:{
		// 		  opacity:0.2,
		// 		  type:'solid'
		// 	  },
		// 	  tooltip: {
		// 		x: {
		// 		  format: 'dd/MM/yy HH:mm'
		// 		},
		// 	  },
		//   };
		//   this.chartOptions = {
		// 	series: dataseties,
		// 	chart: {
		// 	  height: 350,
		// 	  type: "line"
		// 	},
		// 	dataLabels: {
		// 	  enabled: false
		// 	},
		// 	stroke: {
		// 	  width: 5,
		// 	  curve: "straight",
		// 	  dashArray: [0, 8, 5]
		// 	},
		// 	title: {
		// 	  text: "Page Statistics",
		// 	  align: "left"
		// 	},
		// 	legend: {
		// 	  tooltipHoverFormatter: function(val:any, opts:any) {
		// 		return (
		// 		  val +
		// 		  " - <strong>" +
		// 		  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
		// 		  "</strong>"
		// 		);
		// 	  }
		// 	},
		// 	markers: {
		// 	  size: 0,
		// 	  hover: {
		// 		sizeOffset: 6
		// 	  }
		// 	},
		// 	xaxis: {
		// 	  labels: {
		// 		trim: true
		// 	  },
			//   categories: [
			// 	"Jan",
			// 	"Feb",
			// 	"Mar",
			// 	"Apr",
			// 	"Mei",
			// 	"Jun",
			// 	"Jul",
			// 	"Ags",
			// 	"Sep",
			// 	"Okt",
			// 	"Nov",
			// 	"Des"
			//   ]
		// 	},
		// 	tooltip: {
		// 	  y: [
		// 		{
		// 		  title: {
		// 			formatter: function(val:any) {
		// 			  return val + " (mins)";
		// 			}
		// 		  }
		// 		},
		// 		{
		// 		  title: {
		// 			formatter: function(val:any) {
		// 			  return val + " per session";
		// 			}
		// 		  }
		// 		},
		// 		{
		// 		  title: {
		// 			formatter: function(val:any) {
		// 			  return val;
		// 			}
		// 		  }
		// 		}
		// 	  ]
		// 	},
		// 	grid: {
		// 	  borderColor: "#f1f1f1"
		// 	}
		//   };


		  // console.log([
		  //   {
		  //     x: 'this.nameShips',
		  //     y: totalshipments,
		  //   }
		  // ],'totalshipments')
		//   this.chartOptions = {
		// 	series: [
		// 	  {
		// 		name: "Total",
		// 		data: totshipments
		// 	  }
		// 	],
		// 	chart: {
		// 	  height: 350,
		// 	  type: "bar",
		// 	  // events: {
		// 	  //   click: function(chart, w, e) {
		// 	  //     // console.log(chart, w, e)
		// 	  //   }
		// 	  // }
		// 	},
		// 	colors: [
		// 	  "#008FFB",
		// 	  "#00E396",
		// 	  "#FEB019",
		// 	  "#FF4560",
		// 	  "#775DD0",
		// 	  "#546E7A",
		// 	  "#26a69a",
		// 	  "#D10CE8"
		// 	],
		// 	plotOptions: {
		// 	  bar: {
		// 		columnWidth: "45%",
		// 		distributed: true
		// 	  }
		// 	},
		// 	dataLabels: {
		// 	  enabled: false
		// 	},
		// 	legend: {
		// 	  show: false
		// 	},
		// 	grid: {
		// 	  show: false
		// 	},
		// 	xaxis: {
		// 		categories: [
		// 			"Jan",
		// 			"Feb",
		// 			"Mar",
		// 			"Apr",
		// 			"Mei",
		// 			"Jun",
		// 			"Jul",
		// 			"Ags",
		// 			"Sep",
		// 			"Okt",
		// 			"Nov",
		// 			"Dec"
		// 			],
		// 	  labels: {
		// 		style: {
		// 		  colors: [
		// 			"#008FFB",
		// 			"#00E396",
		// 			"#FEB019",
		// 			"#FF4560",
		// 			"#775DD0",
		// 			"#546E7A",
		// 			"#26a69a",
		// 			"#D10CE8"
		// 		  ],
		// 		  fontSize: "12px"
		// 		}
		// 	  }
		// 	}
		//   };
		  // CHARTS
  
		  // // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
		  // this.totalpayments = this.currencyStr(sumpembayarans)
		  // console.log(response.rows[0].totalbayar);
		},
		error => {
		  // console.log(error);
		});
  
	  
	}
  
	ngOnInit(): void {}

}
