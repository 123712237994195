import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progressbar-texttypes',
  templateUrl: './progressbar-texttypes.component.html',
  styleUrls: ['./progressbar-texttypes.component.css']
})
export class ProgressbarTexttypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
