import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import * as XLSX from 'xlsx';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

// @Component({
//   selector: 'ngbd-modal-confirm',
//   template: `
//   <div class="modal-header">
//     <h4 class="modal-title" id="modal-title">Profile deletion</h4>
//     <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
//       <span aria-hidden="true">&times;</span>
//     </button>
//   </div>
//   <div class="modal-body">
//     <p>
//     <span class="text-danger">This operation can not be undone.</span>
//     </p>
//   </div>
//   <div class="modal-footer">
//     <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
//     <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
//   </div>
//   `
// })
// export class NgbdModalConfirm {
//   @Input() public data: any;
//   constructor(public modal: NgbActiveModal) {
//     console.log(this.data)
//   }

//   OnSubmit(status:any) {
//     this.modal.close(status);
//   }
// }

export interface Tutorial {
  id?: any;
  idtagihan?: string,
  idcleansings?: string,
  kode_bayar?: string,
  jenis_tagihan?: string,
  nama_tagihan?: string,
  jumlah_tagihan?: string,
  sisa_tagihan?: string,
  jumlah_bayar?: string,
  tgl_bayar?: string,
  start_tgl_bayar?: string,
  end_tgl_bayar?: string,
  metode_bayar?: string,
  idrekening?: string,
  description?: string,
  no_invoice?: string,
  kode_tagihan?: string,
  airwaybill?: string,
  shipper_id?: string,
  vendor_id?: string,
  consignee_id?: string,
  nameshippers?: string,
  namevendors?: string,
  nameconsignees?: string,
  nama_rekening?: string,
  no_rekening?: string,
  cabang_bank?: string,
  code_bank?: string,
  nama_bank?: string,
  full_name?: string,
  // name?: string,
  // reff_name?: string,
  // phone_number?: string,
  // id_country?: number,
  // country?: string,
  // id_city?: number,
  // city?: string,
  // post_code?: number,
  // address?: string,
  // arc_number?: string,
  // is_aktif?: string,
  // is_delete?: string,
  // connote?: string,
  // ship_date?: string,
  // code_resi_inbound?: string;
  // airwaybill?: string,
  // nameshippers?: string,
  // nameconsignee?: string,
  // namevendor?: string,
  // shipper_id?: string,
  // vendor_id?: string,
  // published?: boolean;
  action?: string
}

// const MODALS: {[name: string]: Type<any>} = {
//   focusFirst: NgbdModalConfirm
// };

@Component({
  selector: 'app-rp-pembayaran',
  templateUrl: './rp-pembayaran.component.html',
  styleUrls: ['./rp-pembayaran.component.css']
})
export class RpPembayaranComponent implements OnInit {


  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  tgl_bayar = '';
  start_tgl_bayar = '';
  end_tgl_bayar = '';
  no_invoice = '';
  shipper_id = '';
  vendor_id = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 10000];
  isCreated:boolean = true;

  vcombos:any = [];
  vcomboss:any = [];

  closeResult = '';
  modal: any;
  ExcelData: any;
  file: any;
  exceljsondata: any;
  arrayBuffer: any;

  constructor(private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    private router: Router) { }

    usersData:any = {};
    rolesString:any = '';
    isAdmin:boolean = false;
    isSuperadmin:boolean = false;
    isCustomer:boolean = false;
    isAgen:boolean = false;
    getUserData() {
      this.tokenStorage.getUsers().then(async res => {
        this.usersData = res;
        // console.log(res)
        this.usersData.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
        });
        // console.log(this.rolesString)
        if(this.rolesString.includes('Admin')) {
          this.isAdmin = true;
        }
        if(this.rolesString.includes('Superadmin')) {
          this.isSuperadmin = true;
        }
        if(this.rolesString.includes('Agen')) {
          this.isAgen = true;
        }
        // console.log(this.isAdmin)
      })
    }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.tgl_bayar = '';
    this.no_invoice = '';
    this.shipper_id = '';
    this.vendor_id = '';
		this.modalService.open(content, { size: 'lg' });
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
	}

  // openimport(content:any) {
  //   this.isCreated = true;
  //   this.fadd_role.action = 'create';
  //   this.shipper_id = '';
  //   this.ship_date = '';
  //   this.vendor_id = '';
	// 	this.modalService.open(content, { size: 'lg' });
  //   // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
  //   //   this.closeResult = `Closed with: ${result}`;
  //   // }, (reason) => {
  //   //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   // });
	// }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodatashippers();
    this.retrieveCombodatavendors();
    this.getUserData();
  }



  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    // console.log(params[`shipper_id`], 'params')

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // console.log(this.shipper_id)
    params.shipper_id = this.shipper_id;
    params.vendor_id = this.vendor_id;
    params.tgl_bayar = this.tgl_bayar;

    params.start_tgl_bayar = this.start_tgl_bayar;
    params.end_tgl_bayar = this.end_tgl_bayar;

    params.no_invoice = this.no_invoice;
    // console.log(params)

    this.tutorialService.getAlls('pembayarans/listview', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatashippers(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('shippers/viewshipper', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodatavendors(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboss = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  async downloadExcel() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.shipper_id = this.shipper_id;
    params.vendor_id = this.vendor_id;
    params.tgl_bayar = this.tgl_bayar;
    params.no_invoice = this.no_invoice;
    // console.log(params, 'params')

    this.tutorialService.postData('pembayarans/downloadexcel', params)
      .subscribe(
        async response => {

        var anchor = document.createElement('a');
        anchor.href = 'https://api.nissaexpress.com/uploads/files/pembayarans/'+response.message;
        // anchor.href = 'http://localhost:7777/uploads/files/pembayarans/'+response.message;
        anchor.target="_blank";
        anchor.click();

        this.presentToast('Generate Excel Pembayaran Success', 'success');
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

  // searchTerm: string = '';
  // searchChanged(evt:any) {
  //   this.initializeItems();

  //   const searchTerm = evt;

  //   if (!searchTerm) {
  //     return;
  //   }

  //   this.tutorials = this.tutorials.filter(item => {
  //     if (item.connote && searchTerm) {
  //       if (item.connote.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
  //         return true;
  //       }
  //       return false;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    // console.log('tes close searchTitle form search');
    // this.modal.close();
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    // name: '',
    // ship_date: '',
  };

  // submitted!:boolean;
  // saveTutorial(): void {
  //   const data = {
  //     name: this.fadd_role.name
  //   };

  //   if(this.isCreated) {
  //     this.tutorialService.postData('role/create', data)
  //       .subscribe(
  //         async response => {
  //           console.log(response);
  //           this.fadd_role = {};
  //           this.presentToast(response.message, 'success');
  //           this.submitted = true;
  //           this.modalService.dismissAll();
  //           this.retrieveTutorials();
  //         },
  //         async error => {
  //           console.log(error);
  //           this.presentToast(error.error.errors, 'error');
  //         });
  //   } else {
  //     this.tutorialService.postData('role/update/'+this.fadd_role.id, data)
  //       .subscribe(
  //         async response => {
  //           console.log(response);
  //           this.fadd_role = {};
  //           this.presentToast(response.message, 'success');
  //           this.submitted = true;
  //           this.modalService.dismissAll();
  //           this.retrieveTutorials();
  //         },
  //         async error => {
  //           console.log(error);
  //           this.presentToast(error.error.errors, 'error');
  //         });
  //   }
  // }

  // editUser(sendMessageModal:any, tutorial: any, action:any) {
  //   this.isCreated = false;
  //   this.modalService.open(sendMessageModal);
  //   console.log(tutorial)
  //   this.fadd_role = tutorial;
  //   this.fadd_role.action = action;
  // }

  // deleteData:any;
  // deleteUser(data:any) {
  //   this.deleteData = data;
  //   let modal = this.modalService.open(MODALS['focusFirst']);
  //   modal.result.then((res:any) => {
  //     console.log(res)
  //     if(res == 'Ok') {
  //       this.tutorialService.postData('cleansings/delete/'+this.deleteData.id, {}).subscribe(res => {
  //         this.toastService.show('Successfully delete data.', { classname: 'bg-success text-light', delay: 10000 });
  //         this.retrieveTutorials();
  //       })
  //     }
  //   });
  // }

  // openData(tutorial:any, action:any) {
  //   const param: NavigationExtras = {
  //     queryParams: {
  //       info: JSON.stringify(tutorial),
  //       action: JSON.stringify(action)
  //     }
  //   }
  //   this.router.navigate(['/admin/form-cleansing'], param);
  // }

  // openPrint(tutorial:any, action:any) {

  //   var anchor = document.createElement('a');
  //   anchor.href = 'http://localhost/report/welcome/laporan/'+tutorial.id;
  //   anchor.target="_blank";
  //   anchor.click();
  //   this.router.navigate(['/admin/app-tr-cleansings']);
  //   // this.router.navigate(['/admin/form-cleansing'], param);
  // }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  currencyStr(value:any) {
    var formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  };

  // onFileChange(event: any) {
  //   if (event.target.files.length > 0) {
  //    this.file = event.target.files[0];
  //   }
  // }

  // Downloadtemps(){
  //   var anchor = document.createElement('a');
  //   anchor.href = 'http://103.146.202.32:39999/uploads/template/template_import_cleansings.xlsx';
  //   anchor.target="_blank";
  //   anchor.click();
  //   this.presentToast('Download Template Success', 'success');
  //   // this.submitted = true;
  //   this.modalService.dismissAll();
  //   this.retrieveTutorials();
  // }

//   Upload() {
//       let fileReader = new FileReader();
//       fileReader.onload = (e) => {
//           this.arrayBuffer = fileReader.result;
//           var data = new Uint8Array(this.arrayBuffer);
//           var arr = new Array();
//           for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
//           var bstr = arr.join("");
//           var workbook = XLSX.read(bstr, {type:"binary"});
//           var first_sheet_name = workbook.SheetNames[0];
//           var worksheet = workbook.Sheets[first_sheet_name];
//           this.exceljsondata = XLSX.utils.sheet_to_json(worksheet,{raw:true, defval:""});

//           // console.log(this.exceljsondata);
//           let senddata = this.exceljsondata;
//           let ship_dates = this.ship_date;
//           let shipper_ids = this.shipper_id;
//           let vendor_ids = this.vendor_id;
//           // console.log(senddata);
//           this.tutorialService.postData('cleansings/importexcel', {
//             data: senddata,
//             ship_dates: ship_dates,
//             shipper_ids: shipper_ids,
//             vendor_ids: vendor_ids,
//           })
//           .subscribe(
//             async response => {
//               console.log(response);
//               this.fadd_role = {};
//               this.presentToast(response.message, 'success');
//               this.submitted = true;
//               this.modalService.dismissAll();
//               this.retrieveTutorials();
//             },
//             async error => {
//               console.log(error);
//               this.presentToast(error.error.errors, 'error');
//             });
//           //  this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
//           //  })
//       }
//      fileReader.readAsArrayBuffer(this.file);
//  }


}
