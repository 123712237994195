<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="top"
        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on top">
  Popover on top
</button>

<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="right"
        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on right">
  Popover on right
</button>

<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="bottom"
        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on bottom">
  Popover on bottom
</button>

<button type="button" class="btn btn-outline-secondary mr-2 mb-2" placement="left"
        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on left">
  Popover on left
</button>