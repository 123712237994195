import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disabled-datepicker',
  templateUrl: './disabled-datepicker.component.html',
  styleUrls: ['./disabled-datepicker.component.css']
})
export class DisabledDatepickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
