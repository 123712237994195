import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dzmt-input',
  templateUrl: './dzmt-input.component.html',
  styleUrls: ['./dzmt-input.component.css']
})
export class DzmtInputComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
