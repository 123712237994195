import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mixed',
  templateUrl: './mixed.component.html',
  styleUrls: ['./mixed.component.css']
})
export class MixedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
