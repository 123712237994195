<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [labels]="chartOptions.labels"
    [stroke]="chartOptions.stroke"
    [plotOptions]="chartOptions.plotOptions"
    [markers]="chartOptions.markers"
    [fill]="chartOptions.fill"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>