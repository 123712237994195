<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-apex-cl">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Column chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-basic></app-column-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Column with Data Labels</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-data-labels></app-column-data-labels>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stacked Columns</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-stacked></app-column-stacked>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Stacked Columns 100</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-stacked100></app-column-stacked100>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Column with Rotated Labels</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-rotated-labels></app-column-rotated-labels>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Column with Negative Values</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-negative-values></app-column-negative-values>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dynamic Loaded Chart</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-loaded-chart></app-column-loaded-chart>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Distributed Columns</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-distributed></app-column-distributed>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Range Column Chart</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-column-range></app-column-range>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->