<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Chartjs</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Line</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-chartjs-line></app-chartjs-line>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Bar</h4>
                    </div>
                    <div class="card-body">
                        <app-chartjs-bar></app-chartjs-bar>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Doughnut</h4>
                    </div>
                    <div class="card-body">
                        <app-chartjs-doughnut></app-chartjs-doughnut>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Radar</h4>
                    </div>
                    <div class="card-body">
                        <app-chartjs-radar></app-chartjs-radar>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Pie</h4>
                    </div>
                    <div class="card-body">
                        <app-chartjs-pie></app-chartjs-pie>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Bubble</h4>
                    </div>
                    <div class="card-body">
                        <app-chartjs-bubble></app-chartjs-bubble>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->