
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-xxl-4">
                <app-my-profile></app-my-profile>
            </div>
            <div class="col-xl-9 col-xxl-8">
                <app-coin-holding [data]="coinHolding"></app-coin-holding>
            </div>
            <div class="col-xl-6">
                <div class="row">
                    <div class="col-xl-6 col-xxl-12 col-md-6">
                        <app-current-graph1></app-current-graph1>
                    </div>
                    <div class="col-xl-6 col-xxl-12 col-md-6">
                        <app-current-graph2></app-current-graph2>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="row">
                    <div class="col-xl-12">
                        <app-wallet-activity></app-wallet-activity>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->