<div class="card">
    <div class="card-header d-block d-sm-flex border-0">
        <div>
            <h4 class="fs-20 text-black">Vendor</h4>
            <p class="mb-0 fs-12">Total Berat By KG {{dt2 | date : 'dd MMM y'}}</p>
        </div>
        <div class="card-action card-tabs mt-3 mt-sm-0">
            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                <!-- <li class="nav-item" ngbNavItem="Harikgvendor">
                    <a class="nav-link" data-toggle="tab" href="#Harikgvendor" role="tab" aria-selected="false" ngbNavLink>
                        Hari	
                    </a>
                    <ng-template ngbNavContent>
                        <app-harikgvendor class="bar-chart"></app-harikgvendor>
                    </ng-template>
                </li> -->
                <li class="nav-item" ngbNavItem="Bulankgvendor">
                    <a class="nav-link" data-toggle="tab" href="#Bulankgvendor" role="tab" aria-selected="false" ngbNavLink>
                        Bulan
                    </a>
                    <ng-template ngbNavContent>
                        <app-bulankgvendor class="bar-chart"></app-bulankgvendor>
                    </ng-template>
                </li>
                <!-- <li class="nav-item" ngbNavItem="Tahunkgvendor">
                    <a class="nav-link" data-toggle="tab" href="#Tahunkgvendor" role="tab" aria-selected="false" ngbNavLink>
                        Tahun
                    </a>
                    <ng-template ngbNavContent>
                        <app-tahunkgvendor class="bar-chart"></app-tahunkgvendor>
                    </ng-template>
                </li> -->
            </ul>
        </div>
    </div>   
<div class="card-body pb-2">
    <div [ngbNavOutlet]="nav" class=""></div>
</div>
</div>
