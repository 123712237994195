<button mat-raised-button color="primary" class="mr-3"
        matTooltip="Info about the action"
        [matTooltipDisabled]="disabled.value"
        aria-label="Button that displays a tooltip that can be programmatically disabled">
  Action
</button>

<mat-checkbox [formControl]="disabled" class="example-disabled-checkbox">
  Tooltip disabled
</mat-checkbox>
