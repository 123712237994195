import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apex',
  templateUrl: './apex.component.html',
  styleUrls: ['./apex.component.css']
})
export class ApexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
