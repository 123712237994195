<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-autocomplete">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Autocomplete</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Highlight the first autocomplete option</h4>
                        <!-- <p class="subtitle mb-0">Bootstrap default style</p> -->
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-autocomplete-first-active></app-dzmt-autocomplete-first-active>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Display value autocomplete</h4>
                        <!-- <p class="subtitle mb-0">add <code>.solid</code> class to change the solid color.</p> -->
                    </div>
                    <div class="card-body">
                        <app-dzmt-autocomplete-display></app-dzmt-autocomplete-display>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Filter autocomplete</h4>
                        <!-- <p class="mb-0 subtitle">add <code>.alert-square</code> class to change the solid color. </p>-->
                        
                    </div>
                    <div class="card-body">
                        <app-dzmt-autocomplete-filter></app-dzmt-autocomplete-filter>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Option groups autocomplete</h4>
                        <!-- <p class="mb-0 subtitle">add <code>.alert-rounded</code> class to change the solid color. </p>-->
                        
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-autocomplete-optgroup></app-dzmt-autocomplete-optgroup>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Autocomplete overview</h4>
                        <!-- <p class="mb-0 subtitle">add <code>.alert-rounded</code> class to change the solid color. </p>-->
                        
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-autocomplete-overview></app-dzmt-autocomplete-overview>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Plain input autocomplete</h4>
                        <!-- <p class="mb-0 subtitle">add <code>.alert-rounded</code> class to change the solid color. </p>-->
                        
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-autocomplete-plane></app-dzmt-autocomplete-plane>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Simple autocomplete</h4>
                        <!-- <p class="mb-0 subtitle">add <code>.alert-rounded</code> class to change the solid color. </p>-->
                        
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-autocomplete-simple></app-dzmt-autocomplete-simple>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->