	<!--**********************************
		Content body start
	***********************************-->
	<div class="content-body">
		<div class="container-fluid">
			<div class="row page-titles mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>Hi, welcome back!</h4>
                        <p class="mb-0">Your business dashboard template</p>
                    </div>
                </div>
                <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                        <li class="breadcrumb-item active"><a href="javascript:void(0)">Modal</a></li>
                    </ol>
                </div>
            </div>

			<!-- row -->
			<div class="row">
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Modal with default options</h4>
						</div>
						<div class="card-body">
							<app-basic-modal></app-basic-modal>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Components as content</h4>
						</div>
						<div class="card-body">
							<app-modal-component></app-modal-component>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Modal with options</h4>
							
						</div>
						<div class="card-body">
							<app-modal-options></app-modal-options>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Custom alert</h4>
							
						</div>
						<div class="card-body">
							
							<app-modal-focus></app-modal-focus>
							
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Stacked modals</h4>
						</div>
						<div class="card-body">
							<app-modal-stacked></app-modal-stacked>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-xxl-12">
					<div class="card">
						<div class="card-header d-block">
							<h4 class="card-title">Custom alert</h4>
							
						</div>
						<div class="card-body">
							
							<app-modal-config></app-modal-config>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--**********************************
		Content body end
	***********************************-->