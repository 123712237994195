<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-apex-cl">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Apex Chart</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Treemap chart</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-treemap-basic></app-treemap-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Treemap Multiple Series</h4>
                    </div>
                    <div class="card-body">
                        <app-treemap-multiple-series></app-treemap-multiple-series>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Color Range</h4>
                    </div>
                    <div class="card-body">
                        <app-treemap-color-range></app-treemap-color-range>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Distributed</h4>
                    </div>
                    <div class="card-body">
                        <app-treemap-distributed></app-treemap-distributed>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->