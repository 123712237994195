import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-manually-show-hide',
  templateUrl: './tooltip-manually-show-hide.component.html',
  styleUrls: ['./tooltip-manually-show-hide.component.css']
})
export class TooltipManuallyShowHideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
