<div class="row">
	<div class="col-sm-6">	
		<h4>Basic mat-select</h4>
		<mat-form-field appearance="fill">
		  <mat-label>Favorite food</mat-label>
		  <mat-select>
			<mat-option *ngFor="let food of foods" [value]="food.value">
			  {{food.viewValue}}
			</mat-option>
		  </mat-select>
		</mat-form-field>
	</div>
	<div class="col-sm-6">
		<h4>Basic native select</h4>
		<mat-form-field appearance="fill">
		  <mat-label>Cars</mat-label>
		  <select matNativeControl required>
			<option value="volvo">Volvo</option>
			<option value="saab">Saab</option>
			<option value="mercedes">Mercedes</option>
			<option value="audi">Audi</option>
		  </select>
		</mat-form-field>
	</div>
</div>