<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">thumb_up</mat-icon>
      First
    </ng-template>
    Content 1
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">thumb_up</mat-icon>
      Second
    </ng-template>
    Content 2
    </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">thumb_up</mat-icon>
      Third
    </ng-template>

    Content 3
  </mat-tab>
</mat-tab-group>
