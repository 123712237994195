<button mat-raised-button color="primary" (click)="addColumn()"> Add column </button>
<button mat-raised-button color="warn" (click)="removeColumn()"> Remove column </button>
<button mat-raised-button (click)="shuffle()"> Shuffle </button>
<div class="table-responsive">
	<table mat-table [dataSource]="data" class="mat-elevation-z8">
	  <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
		<th mat-header-cell *matHeaderCellDef> {{column}} </th>
		<td mat-cell *matCellDef="let element"> {{element[column]}} </td>
	  </ng-container>

	  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
	  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
	</table>
</div>
