<p>Illustrates custom validation, you have to select time between 12:00 and 13:59</p>

<div class="form-group">
  <ngb-timepicker [formControl]="ctrl" required></ngb-timepicker>
  <div *ngIf="ctrl.valid" class="small form-text text-success">Great choice</div>
  <div *ngIf="ctrl.errors" class="small form-text text-danger">
    <div *ngIf="ctrl.errors['required']">Select some time during lunchtime</div>
    <div *ngIf="ctrl.errors['tooLate']">Oh no, it's way too late</div>
    <div *ngIf="ctrl.errors['tooEarly']">It's a bit too early</div>
  </div>
</div>

<hr>
<pre>Selected time: {{ctrl.value | json}}</pre>