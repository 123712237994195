export const githubData = {
  series: [
    {
      x: 1352592000000,
      a: 306,
      d: 33,
      y: 13
    },
    {
      x: 1353196800000,
      a: 77,
      d: 41,
      y: 11
    },
    {
      x: 1353801600000,
      a: 97,
      d: 52,
      y: 13
    },
    {
      x: 1354406400000,
      a: 349,
      d: 231,
      y: 27
    },
    {
      x: 1355011200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1355616000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1356220800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1356825600000,
      a: 93,
      d: 16,
      y: 12
    },
    {
      x: 1357430400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1358035200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1358640000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1359244800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1359849600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1360454400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1361059200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1361664000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1362268800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1362873600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1363478400000,
      a: 47,
      d: 20,
      y: 6
    },
    {
      x: 1364083200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1364688000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1365292800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1365897600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1366502400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1367107200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1367712000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1368316800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1368921600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1369526400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1370131200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1370736000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1371340800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1371945600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1372550400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1373155200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1373760000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1374364800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1374969600000,
      a: 22,
      d: 16,
      y: 9
    },
    {
      x: 1375574400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1376179200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1376784000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1377388800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1377993600000,
      a: 104,
      d: 79,
      y: 12
    },
    {
      x: 1378598400000,
      a: 60,
      d: 17,
      y: 9
    },
    {
      x: 1379203200000,
      a: 27,
      d: 36,
      y: 3
    },
    {
      x: 1379808000000,
      a: 283,
      d: 199,
      y: 20
    },
    {
      x: 1380412800000,
      a: 1,
      d: 1,
      y: 1
    },
    {
      x: 1381017600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1381622400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1382227200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1382832000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1383436800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1384041600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1384646400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1385251200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1385856000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1386460800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1387065600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1387670400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1388275200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1388880000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1389484800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1390089600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1390694400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1391299200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1391904000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1392508800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1393113600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1393718400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1394323200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1394928000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1395532800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1396137600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1396742400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1397347200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1397952000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1398556800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1399161600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1399766400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1400371200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1400976000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1401580800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1402185600000,
      a: 115,
      d: 38,
      y: 11
    },
    {
      x: 1402790400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1403395200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1404000000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1404604800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1405209600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1405814400000,
      a: 598,
      d: 209,
      y: 34
    },
    {
      x: 1406419200000,
      a: 195,
      d: 119,
      y: 18
    },
    {
      x: 1407024000000,
      a: 174,
      d: 54,
      y: 13
    },
    {
      x: 1407628800000,
      a: 1,
      d: 1,
      y: 1
    },
    {
      x: 1408233600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1408838400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1409443200000,
      a: 2,
      d: 2,
      y: 1
    },
    {
      x: 1410048000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1410652800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1411257600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1411862400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1412467200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1413072000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1413676800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1414281600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1414886400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1415491200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1416096000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1416700800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1417305600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1417910400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1418515200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1419120000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1419724800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1420329600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1420934400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1421539200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1422144000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1422748800000,
      a: 46,
      d: 43,
      y: 8
    },
    {
      x: 1423353600000,
      a: 20,
      d: 4,
      y: 1
    },
    {
      x: 1423958400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1424563200000,
      a: 18,
      d: 11,
      y: 4
    },
    {
      x: 1425168000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1425772800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1426377600000,
      a: 54,
      d: 63,
      y: 4
    },
    {
      x: 1426982400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1427587200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1428192000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1428796800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1429401600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1430006400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1430611200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1431216000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1431820800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1432425600000,
      a: 10,
      d: 11,
      y: 1
    },
    {
      x: 1433030400000,
      a: 296,
      d: 172,
      y: 18
    },
    {
      x: 1433635200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1434240000000,
      a: 10,
      d: 13,
      y: 2
    },
    {
      x: 1434844800000,
      a: 20,
      d: 16,
      y: 3
    },
    {
      x: 1435449600000,
      a: 24,
      d: 10,
      y: 3
    },
    {
      x: 1436054400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1436659200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1437264000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1437868800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1438473600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1439078400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1439683200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1440288000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1440892800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1441497600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1442102400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1442707200000,
      a: 275,
      d: 129,
      y: 12
    },
    {
      x: 1443312000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1443916800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1444521600000,
      a: 1213,
      d: 837,
      y: 5
    },
    {
      x: 1445126400000,
      a: 299,
      d: 54,
      y: 3
    },
    {
      x: 1445731200000,
      a: 30,
      d: 33,
      y: 1
    },
    {
      x: 1446336000000,
      a: 202,
      d: 185,
      y: 18
    },
    {
      x: 1446940800000,
      a: 554,
      d: 292,
      y: 39
    },
    {
      x: 1447545600000,
      a: 9030,
      d: 44,
      y: 7
    },
    {
      x: 1448150400000,
      a: 8,
      d: 1,
      y: 1
    },
    {
      x: 1448755200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1449360000000,
      a: 18,
      d: 12,
      y: 5
    },
    {
      x: 1449964800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1450569600000,
      a: 4,
      d: 3,
      y: 2
    },
    {
      x: 1451174400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1451779200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1452384000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1452988800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1453593600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1454198400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1454803200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1455408000000,
      a: 2,
      d: 2,
      y: 1
    },
    {
      x: 1456012800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1456617600000,
      a: 32,
      d: 43,
      y: 1
    },
    {
      x: 1457222400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1457827200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1458432000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1459036800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1459641600000,
      a: 23,
      d: 13,
      y: 3
    },
    {
      x: 1460246400000,
      a: 421,
      d: 335,
      y: 9
    },
    {
      x: 1460851200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1461456000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1462060800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1462665600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1463270400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1463875200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1464480000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1465084800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1465689600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1466294400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1466899200000,
      a: 6,
      d: 1,
      y: 1
    },
    {
      x: 1467504000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1468108800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1468713600000,
      a: 886,
      d: 49,
      y: 15
    },
    {
      x: 1469318400000,
      a: 38,
      d: 26,
      y: 4
    },
    {
      x: 1469923200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1470528000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1471132800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1471737600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1472342400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1472947200000,
      a: 2,
      d: 2,
      y: 1
    },
    {
      x: 1473552000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1474156800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1474761600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1475366400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1475971200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1476576000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1477180800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1477785600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1478390400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1478995200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1479600000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1480204800000,
      a: 8,
      d: 0,
      y: 1
    },
    {
      x: 1480809600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1481414400000,
      a: 1,
      d: 1,
      y: 1
    },
    {
      x: 1482019200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1482624000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1483228800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1483833600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1484438400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1485043200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1485648000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1486252800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1486857600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1487462400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1488067200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1488672000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1489276800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1489881600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1490486400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1491091200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1491696000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1492300800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1492905600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1493510400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1494115200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1494720000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1495324800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1495929600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1496534400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1497139200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1497744000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1498348800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1498953600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1499558400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1500163200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1500768000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1501372800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1501977600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1502582400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1503187200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1503792000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1504396800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1505001600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1505606400000,
      a: 2,
      d: 2,
      y: 2
    },
    {
      x: 1506211200000,
      a: 49,
      d: 10,
      y: 4
    },
    {
      x: 1506816000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1507420800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1508025600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1508630400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1509235200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1509840000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1510444800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1511049600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1511654400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1512259200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1512864000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1513468800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1514073600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1514678400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1515283200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1515888000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1516492800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1517097600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1517702400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1518307200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1518912000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1519516800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1520121600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1520726400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1521331200000,
      a: 768,
      d: 2125,
      y: 12
    },
    {
      x: 1521936000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1522540800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1523145600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1523750400000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1524355200000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1524960000000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1525564800000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1526169600000,
      a: 0,
      d: 0,
      y: 0
    },
    {
      x: 1526774400000,
      a: 1,
      d: 0,
      y: 1
    }
  ]
};
