<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Produk</a></li>
          </ol>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search by title" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i
          class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i
          class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Produk</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Stok</th>
                    <th scope="col">Harga</th>
                    <!--<th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x+1 }}
                    </th>
                    <td>
                      {{ tutorial.name_kategori }}
                    </td>
                    <td>
                      {{ tutorial.code_product }}
                    </td>
                    <td>
                      {{ tutorial.name_product }}
                    </td>
                    <td>
                      {{ tutorial.stock }}
                    </td>
                    <td>
                      {{ tutorial.harga }}
                    </td>
                    <!-- <td>{{ tutorial.name }}</td>
                    <td>{{ tutorial.phone_number }}</td>
                    <td>{{ tutorial.address }}</td> -->
                    <td>
                      <button type="button" class="btn btn-rounded btn-info"
                        (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp;
                      <button type="button" class="btn btn-rounded btn-danger" (click)="deleteShippers(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                        </span>Remove</button>&nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)">
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize"
                  (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal Harga -->


<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Produk</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="row">
          <div class="col-xl-12">

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="code_product">Kategori Produk
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                
                <p-dropdown
                  [options]="typeItems"
                  optionLabel="name_kategori"
                  [filter]="true"
                  [disabled]="action == 'view'"
                  filterBy="name_kategori"
                  [showClear]="true"
                  styleClass="w-100"
                  [(ngModel)]="selectedTypeItem"
                  [ngModelOptions]="{standalone: true}"
                  placeholder="Select a Kategori"
                  (onChange)="onDropdownChange($event, 'type_id')"
                  (onClear)="onDropdownClear('type_id')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedTypeItem">
                      <div>{{ selectedTypeItem.name_kategori }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>{{ item.name_kategori }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>

                <!-- <div
                  *ngIf="angForm.controls['code_product'].invalid && (angForm.controls['code_product'].dirty || angForm.controls['code_product'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['code_product'].errors.required">
                    Kode Produk is required.
                  </div>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="code_product">Kode Produk
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="code_product" formControlName="code_product"
                  placeholder="Enter a Kode Produk.." [(ngModel)]="code_product">
                <div
                  *ngIf="angForm.controls['code_product'].invalid && (angForm.controls['code_product'].dirty || angForm.controls['code_product'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['code_product'].errors.required">
                    Kode Produk is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="name_product">Nama Produk
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="name_product" formControlName="name_product"
                  placeholder="Enter a Nama Produk.." [(ngModel)]="name_product">
                <div
                  *ngIf="angForm.controls['name_product'].invalid && (angForm.controls['name_product'].dirty || angForm.controls['name_product'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['name_product'].errors.required">
                    Nama Produk is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="stock">Stok
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="number" class="form-control" id="stock" formControlName="stock" placeholder="Enter a stock.."
                  [(ngModel)]="stock">
                <div
                  *ngIf="angForm.controls['stock'].invalid && (angForm.controls['stock'].dirty || angForm.controls['stock'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['stock'].errors.required">
                    Stock is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="harga">Harga
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                  <p-inputNumber id="harga" formControlName="harga" [(ngModel)]="harga"></p-inputNumber>
                <div
                  *ngIf="angForm.controls['harga'].invalid && (angForm.controls['harga'].dirty || angForm.controls['harga'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['harga'].errors.required">
                    Harga is required.
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="address">Keterangan <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <textarea class="form-control" id="keterangan" rows="5" [(ngModel)]="keterangan"
                  formControlName="keterangan" placeholder="Keterangan ?"></textarea>
                <div
                  *ngIf="angForm.controls['keterangan'].invalid && (angForm.controls['keterangan'].dirty || angForm.controls['keterangan'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['keterangan'].errors.required">
                    Keterangan is required.
                  </div>
                </div>
              </div>
            </div>

           

            <!-- <div class="form-group row">
              <label class="col-lg-4 col-form-label">Upload Image
              </label>
              <div class="col-lg-8">
                <input type="file" class="form-control" name="fileimage" id="fileimage"
                  (change)="onFileSelected($event)">
              </div>
            </div> -->

            <div class="d-flex justify-content-between my-4 mt-1">
              <h5 class="my-auto">List Upload Image</h5>
            </div>
            <ngx-dropzone
              style="z-index: 99"
              [disabled]="action == 'view'"
              (change)="onSelect($event)"
              [disabled]="action == 'view'"
              accept="image/jpeg,image/jpg,image/png,image/gif"
            >
              <ngx-dropzone-label>Click to add images</ngx-dropzone-label>
              <ngx-dropzone-image-preview
                *ngFor="let f of files"
                [removable]="true"
                [file]="f"
                (removed)="onRemove(f)"
              >
                <ngx-dropzone-label
                  >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
                >
              </ngx-dropzone-image-preview>
            </ngx-dropzone>


            <div class="form-group row mt-2">
              <div class="col-lg-8 ml-auto">
                <!-- <button type="submit" class="btn btn-primary"
                  [disabled]="angForm.pristine || angForm.invalid">Submit</button> -->
                <!-- <button (click)="onSubmit()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                <!-- <button (click)="saveTutorial()" [disabled]="angForm.pristine || angForm.invalid"
                  class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                  <ng-container *ngIf="(isLoading$) === false">
                    <span class="indicator-label">
                      <button (click)="saveTutorial()" [disabled]="angForm.pristine || angForm.invalid"
                  class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="isLoading$">
                    <span class="indicator-progress" [style.display]="'block'">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>