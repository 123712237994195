<div class="card">
    <div class="card-header pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center">
        <div class="mr-auto mb-3">
            <h4 class="fs-20 text-black">Coin Chart</h4>
            <p class="mb-0 fs-12">Lorem ipsum dolor sit amet, consectetur</p>
        </div>
        <a href="javascript:void(0);" class="btn btn-primary light btn-rounded mr-3  mb-3"><i class="las la-download scale5 mr-2"></i>Get Report</a>
        <div class="input-group detault-daterange mr-3  mb-3" >
            <mat-form-field class="dashboard-select" appearance="fill">
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date">
                <input matEndDate placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
		<mat-form-field appearance="fill" class="style-1 dashboard-select  mb-3">
		  <mat-select value="BTC ($ US Dollar)">
			<mat-option value="USD ($ US Dollar)">USD ($ US Dollar)</mat-option>
			<mat-option value="BTC ($ US Dollar)">BTC ($ US Dollar)</mat-option>
		  </mat-select>
		</mat-form-field>
    </div>
    <div class="card-body pb-0 pt-sm-3 pt-0">
        <div class="row sp20 mb-4 align-items-center">
            <div class="col-lg-8 col-xxl-12 d-flex flex-wrap justify-content-between align-items-center">
                <div class="px-2 info-group">
                    <p class="fs-18 mb-1">Price</p>
                    <h2 class="fs-28 font-w600 text-black">$11,898.15</h2>
                </div>
                <div class="px-2 info-group">
                    <p class="fs-14 mb-1">24h% change</p>
                    <h3 class="fs-20 font-w600 text-success">1.64%
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 7L7.00001 -8.77983e-06L14 7H7.00001H0Z" fill="#2BC155"></path>
                        </svg>
                    </h3>
                </div>
                <div class="px-2 info-group">
                    <p class="fs-14 mb-1">Volume (24h)</p>
                    <h3 class="fs-20 font-w600 text-black">$47.22B</h3>
                </div>
                <div class="px-2 info-group">
                    <p class="fs-14 mb-1">Market Cap</p>
                    <h3 class="fs-20 font-w600 text-black">$219.24B</h3>
                </div>
            </div>
            <div class="d-flex col-lg-4 col-xxl-12 align-items-center mt-sm-0 mt-3 justify-content-center">
                <div class="fs-18 font-w600 mr-4">
                    <span class="text-success pr-3">BUY</span>
                    <span class="text-black">$5,673</span>
                </div>
                <div class="fs-18 font-w600">
                    <span class="text-danger pr-3">SELL</span>
                    <span class="text-black">$5,982</span>
                </div>
            </div>
        </div>
        <div id="chartBarRunning" class="bar-chart">
            
            <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [legend]="chartOptions.legend"
                [fill]="chartOptions.fill"
                [stroke]="chartOptions.stroke"
                [tooltip]="chartOptions.tooltip"
                [xaxis]="chartOptions.xaxis"
                [colors]="chartOptions.colors"
                [responsive]="chartOptions.responsive"
                [markers]="chartOptions.markers"
                [grid]="chartOptions.grid"
            ></apx-chart>
            
        </div>
    </div>
</div>