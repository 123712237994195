<div class="row">
	<div class="col-sm-6">
		<mat-form-field>
		  <mat-label>Message</mat-label>
		  <input matInput value="Disco party!" #message>
		</mat-form-field>
	</div>
	<div class="col-sm-6">

		<mat-form-field>
		  <mat-label>Action</mat-label>
		  <input matInput value="Dance" #action>
		</mat-form-field>

	</div>
</div>
<button mat-stroked-button color="primary" (click)="openSnackBar(message.value, action.value)">Show snack-bar</button>
