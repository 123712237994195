<ngb-alert [dismissible]="false">
  If you configure the locale and register the locale data as explained in the
  <a href="https://angular.io/guide/i18n">i18n guide</a>, the time picker will honor
  the locale and use the day periods ("AM" and "PM") from the locale data. You can however
  provide a custom service, as demonstrated in this example, to customize the
  days and months names the way you want to.
</ngb-alert>

<p>Timepicker in Greek</p>

<ngb-timepicker [(ngModel)]="model" [meridian]="true"></ngb-timepicker>