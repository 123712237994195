<div class="card">
    <div class="card-header d-block d-sm-flex border-0">
        <div>
            <h4 class="fs-20 text-black">Mitra</h4>
            <p class="mb-0 fs-12">Total Bulan {{dt2 | date : 'MMM y'}}</p>
        </div>
        <div class="card-action card-tabs mt-3 mt-sm-0">
            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" activeId="Bulanmitrapie">
                <li class="nav-item" ngbNavItem="Bulanmitrapie">
                    <a class="nav-link" data-toggle="tab" href="#Bulanmitrapie" role="tab" aria-selected="false" ngbNavLink>
                        Bulan
                    </a>
                    <ng-template ngbNavContent>
                        <app-bulanmitrapie class="bar-chart"></app-bulanmitrapie>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Tahunmitrapie">
                    <a class="nav-link" data-toggle="tab" href="#Tahunmitrapie" role="tab" aria-selected="false" ngbNavLink>
                        Tahun
                    </a>
                    <ng-template ngbNavContent>
                        <app-tahunmitrapie class="bar-chart"></app-tahunmitrapie>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>   
<div class="card-body pb-2">
    <div [ngbNavOutlet]="nav" class=""></div>
</div>
</div>
