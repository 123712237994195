<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-btn">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Button</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-12 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Button basic</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-button-basic></app-dzmt-button-basic>
                        
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Button varieties</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-dzmt-button-varieties></app-dzmt-button-varieties>
                        
                        
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->