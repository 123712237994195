<div class="row">
	<div class="col-sm-6">
		<p>
		  <mat-form-field appearance="legacy">
			<mat-label>Legacy form field</mat-label>
			<input matInput placeholder="Placeholder">
			<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
			<mat-hint>Hint</mat-hint>
		  </mat-form-field>
		</p>
	</div>
	<div class="col-sm-6">
		<p>
		  <mat-form-field appearance="standard">
			<mat-label>Standard form field</mat-label>
			<input matInput placeholder="Placeholder">
			<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
			<mat-hint>Hint</mat-hint>
		  </mat-form-field>
		</p>
	</div>
	<div class="col-sm-6">
		<p>
		  <mat-form-field appearance="fill">
			<mat-label>Fill form field</mat-label>
			<input matInput placeholder="Placeholder">
			<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
			<mat-hint>Hint</mat-hint>
		  </mat-form-field>
		</p>
	</div>
	<div class="col-sm-6">
		<p>
		  <mat-form-field appearance="outline">
			<mat-label>Outline form field</mat-label>
			<input matInput placeholder="Placeholder">
			<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
			<mat-hint>Hint</mat-hint>
		  </mat-form-field>
		</p>
	</div>
</div>
