<p>First focusable element within the modal window will receive focus upon opening.
This could be configured to focus any other element by adding an <code>ngbAutofocus</code> attribute on it.</p>

<pre class="language-html"><code class="language-html"><span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>button</span> <span class="token attr-name">type</span><span class="token attr-value"><span class="token punctuation">=</span><span class="token punctuation">"</span>button<span class="token punctuation">"</span></span> <span class="token attr-name">ngbAutofocus</span> <span class="token attr-name">class</span><span class="token attr-value"><span class="token punctuation">=</span><span class="token punctuation">"</span>btn btn-danger<span class="token punctuation">"</span></span>
      <span class="token attr-name">(click)</span><span class="token attr-value"><span class="token punctuation">=</span><span class="token punctuation">"</span>modal.close(<span class="token punctuation">'</span>Ok click<span class="token punctuation">'</span>)<span class="token punctuation">"</span></span><span class="token punctuation">&gt;</span></span>Ok<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>button</span><span class="token punctuation">&gt;</span></span></code></pre>

<br />
<button class="btn btn-outline-primary mr-2 mb-2" (click)="open('focusFirst')">
  <div>Open confirm modal</div>
  <div class="text-dark" aria-hidden="true"><small>&times; button will be focused</small></div>
</button>

<button class="btn btn-outline-primary mb-2" (click)="open('autofocus')">
  <div>Open confirm modal with `ngbAutofocus`</div>
  <div class="text-dark" aria-hidden="true"><small>Ok button will be focused</small></div>
</button>