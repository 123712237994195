<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Typeahead</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Simple Typeahead</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-typeahead-basic></app-typeahead-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Open on focus</h4>
                    </div>
                    <div class="card-body">
                        <app-typeahead-focus></app-typeahead-focus>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Formatted results</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-typeahead-format></app-typeahead-format>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Wikipedia search</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-typeahead-http></app-typeahead-http>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Template for results</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-typeahead-template></app-typeahead-template>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Prevent manual entry</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-typeahead-config></app-typeahead-config>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of typeaheads</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-typeahead-prevent-manual-entry></app-typeahead-prevent-manual-entry>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->