<p>A pagination with customized links:</p>
<div class="pagination-responsive">
	<ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Custom pagination">
	  <ng-template ngbPaginationPrevious>Prev</ng-template>
	  <ng-template ngbPaginationNext>Next</ng-template>
	  <ng-template ngbPaginationNumber let-p>{{ getPageSymbol(p) }}</ng-template>
	</ngb-pagination>
</div>
<hr>

<pre>Current page: {{page}}</pre>