import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-das2-buy-order',
  templateUrl: './das2-buy-order.component.html',
  styleUrls: ['./das2-buy-order.component.css']
})
export class Das2BuyOrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
