<p>You can bind our <code>NgbPagination</code> component with slicing the data list</p>
<div class="table-responsive">
	<table class="table table-striped">
	  <thead>
	  <tr>
		<th scope="col">#</th>
		<th scope="col">Country</th>
		<th scope="col">Area</th>
		<th scope="col">Population</th>
	  </tr>
	  </thead>
	  <tbody>
	  <tr *ngFor="let country of countries">
		<th scope="row">{{ country.id }}</th>
		<td>
		  <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2" style="width: 20px">
		  {{ country.name }}
		</td>
		<td>{{ country.area | number}}</td>
		<td>{{ country.population | number }}</td>
	  </tr>
	  </tbody>
	</table>
</div>

<div class="d-sm-flex d-block justify-content-between p-2">
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
  </ngb-pagination>

  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
    <option [ngValue]="2">2 items per page</option>
    <option [ngValue]="4">4 items per page</option>
    <option [ngValue]="6">6 items per page</option>
  </select>
</div>