import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tooltip-customclass',
  templateUrl: './tooltip-customclass.component.html',
  styleUrls: ['./tooltip-customclass.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipCustomclassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
