<div id="DZ_W_Todo3" class="widget-media dz-scroll height370" [perfectScrollbar]>
	<ul class="timeline">
		<li>
			<div class="timeline-panel">
				<div class="media mr-2">
					<img alt="image" width="50" src="assets/images/avatar/1.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Alfie Mason <small class="text-muted">29 July 2020</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-left"  placement="bottom-left" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-info">
					KG
				</div>
				<div class="media-body">
					<h5 class="mb-1">Jacob Tucker <small class="text-muted">29 July 2020</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown  display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-info light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-success">
					<img alt="image" width="50" src="assets/images/avatar/2.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Jack Ronan <small class="text-muted">29 July 2020</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-success light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2">
					<img alt="image" width="50" src="assets/images/avatar/1.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Noah Baldon <small class="text-muted">29 July 2020</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-danger">
					PU
				</div>
				<div class="media-body">
					<h5 class="mb-1">Thomas Grady  <small class="text-muted">02:26 PM</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-danger light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="media mr-2 media-primary">
					<img alt="image" width="50" src="assets/images/avatar/3.jpg">
				</div>
				<div class="media-body">
					<h5 class="mb-1">Oscar Weston  <small class="text-muted">29 July 2020</small></h5>
					<p class="mb-1">I shared this on my fb wall a few months back..</p>
					<a href="#" class="btn btn-primary btn-xxs shadow mr-1">Reply</a>
					<a href="#" class="btn btn-outline-danger btn-xxs">Delete</a>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown display="dynamic" placement="bottom-right">
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
	</ul>
</div>