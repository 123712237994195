import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';


export interface Tutorial {
  id?: any;
  code?: string;
  keberangkatan?: string;
  kedatangan?: string;
  time_keberangkatan?: string;
  time_kedatangan?: string;
  code_t?: string;
  keberangkatan_t?: string;
  kedatangan_t?: string;
  time_keberangkatan_t?: string;
  time_kedatangan_t?: string;
  tanggal_flight?: string;
  isStatusTransit?: string;
  // connote?: string,
  // airwaybill?: string,
  // status?: string,
  // description?: string,
  // note?: string,
  // published?: boolean;
  action?: string
}

@Component({
  selector: 'app-mst-flights',
  templateUrl: './mst-flights.component.html',
  styleUrls: ['./mst-flights.component.css']
})
export class MstFlightsComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		// this.modalService.open(content);
    this.modalService.open(content, { size: 'lg' });
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('flights/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('typeitems/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code: '',
    keberangkatan: '',
    kedatangan: '',
    time_keberangkatan: '',
    time_kedatangan: '',
    code_t: '',
    keberangkatan_t: '',
    kedatangan_t: '',
    time_keberangkatan_t: '',
    time_kedatangan_t: '',
    tanggal_flight: '',
    isStatusTransit: '',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      code: this.fadd_role.code,
      keberangkatan: this.fadd_role.keberangkatan,
      kedatangan: this.fadd_role.kedatangan,
      time_keberangkatan: this.fadd_role.time_keberangkatan,
      time_kedatangan: this.fadd_role.time_kedatangan,
      code_t: this.fadd_role.code_t,
      keberangkatan_t: this.fadd_role.keberangkatan_t,
      kedatangan_t: this.fadd_role.kedatangan_t,
      time_keberangkatan_t: this.fadd_role.time_keberangkatan_t,
      time_kedatangan_t: this.fadd_role.time_kedatangan_t,
      tanggal_flight: this.fadd_role.tanggal_flight,
      isStatusTransit: this.fadd_role.isStatusTransit,
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('flights/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('flights/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    this.fadd_role.id = tutorial.id;
    this.fadd_role.code = tutorial.code;
    this.fadd_role.keberangkatan = tutorial.keberangkatan;
    this.fadd_role.kedatangan = tutorial.kedatangan;
    this.fadd_role.time_keberangkatan = tutorial.time_keberangkatan;
    this.fadd_role.time_kedatangan = tutorial.time_kedatangan;
    this.fadd_role.code_t = tutorial.code_t;
    this.fadd_role.keberangkatan_t = tutorial.keberangkatan_t;
    this.fadd_role.kedatangan_t = tutorial.kedatangan_t;
    this.fadd_role.time_keberangkatan_t = tutorial.time_keberangkatan_t;
    this.fadd_role.time_kedatangan_t = tutorial.time_kedatangan_t;
    this.fadd_role.tanggal_flight = tutorial.tanggal_flight;
    this.fadd_role.isStatusTransit = tutorial.isStatusTransit;
    this.fadd_role.action = action;
  }

  deleteTrackings(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`flights/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
