<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-formfield default-autocomplete default-mt-select">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Form Field</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field appearance variants</h4>
                    </div>
                    <div class="card-body">
                        <app-appearance-variants></app-appearance-variants>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field with error messages</h4>
                    </div>
                    <div class="card-body">
                        <app-field-with-error-messages></app-field-with-error-messages>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field with hints</h4>
                    </div>
                    <div class="card-body">
                        <app-field-with-hints></app-field-with-hints>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field with label</h4>
                    </div>
                    <div class="card-body">
                        <app-field-with-label></app-field-with-label>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Simple form field</h4>
                    </div>
                    <div class="card-body">
                        <app-simple-form-field></app-simple-form-field>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field with prefix & suffix</h4>
                    </div>
                    <div class="card-body">
                        <app-field-with-prefix-suffix></app-field-with-prefix-suffix>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Form field theming</h4>
                    </div>
                    <div class="card-body">
                        <app-field-theming></app-field-theming>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->