import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
// import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Profile deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    // console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

export interface Tutorial {
  id?: any;
  idcleansing?: string,
  no_invoice?: string,
  tanggal_tagihan?: string,
  tgl_bayar?: string,
  kode_tagihan?: string,
  jenis_tagihan?: string,
  nama_tagihan?: string,
  jumlah_tagihan?: string,
  sisa_tagihan?: string,
  is_status_tagihan?: string,
  description?: string,
  jumlah_bayar?: string,
  metode_bayar?: string,
  idrekening?: string,
  id_bank?: string,
  nameconsignee?: string,
  // tunai?: string,
  // transfer?: string,
  // status_kurir?: string,
  // status_pickup?: string,
  // published?: boolean;
  action?: string
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-tr-tagihan',
  templateUrl: './tr-tagihan.component.html',
  styleUrls: ['./tr-tagihan.component.css']
})
export class TrTagihanComponent implements OnInit {

  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 2000, "ALL"];
  isCreated:boolean = true;
  id: any;
  idcleansing: any;
  no_invoice: any;
  tanggal_tagihan: any;
  kode_tagihan: any;
  jenis_tagihan: any;
  nama_tagihan: any;
  jumlah_tagihan: any;
  sisa_tagihan: any;
  is_status_tagihan: any;
  description: any;
  idrekening: any;
  idtagihan: any;
  // tunai: any;
  // transfer: any;
  // status_kurir: any;
  // status_pickup: any;
  metode_bayar: any;
  id_bank: any;

  statusopen: boolean | undefined;
  // angForm: FormGroup | any;
  // value!: any;
  vcombo: any = [];

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private router: Router,
    // private fb: FormBuilder
    ) {
      // this.createForm();
    }

    // createForm() {
    //   this.angForm = this.fb.group({
    //     //  code: ['', [Validators.required ]],
    //     idcleansing: [null],
    //     no_invoice: [null],
    //     tanggal_tagihan: [null],
    //      status_kurir: [],
    //      status_pickup: [],
    //      kode_tagihan: [],
    //      jenis_tagihan: [],
    //      nama_tagihan: [],
    //      jumlah_tagihan: [],
    //      sisa_tagihan: [],
    //      is_status_tagihan: [],
    //      description: [],
    //     //  status_pickup: [],
    //     //  status_direct: [],
    //     //  id_shippers: ['', [Validators.required ]],
    //     //  status_paket: ['', [Validators.required ]],
    //     //  tgl_terima: ['', [Validators.required ]],
    //     //  berat_paket: ['', [Validators.required ]],
    //     //  isi_paket: ['', [Validators.required ]],
    //     //  description: ['', [Validators.required] ],
    //     //  template_option: ['', [Validators.required] ]
    //   });

    //   // console.log('masuk')

    // }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  // open(content:any) {
  //   const dialogRef = this.dialog.open(content, {
  //     width: '500px',
  //     data: { adminData: r }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.content = [];
  //     this.getDataAdmin();
  //   });
  // }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('tagihans/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.tutorialsTemp = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });

    // this.tutorialService.getAllCleansing(params)
    // .subscribe(
    //   response => {
    //     const { data, totalItems } = response;
    //     this.tutorials = data;
    //     this.tutorialsTemp = data;
    //     this.count = totalItems;
    //     console.log(this.tutorials, 'd');
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('rekenings/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.vcombo = data;
        },
        error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  searchTerm: string = '';
  searchChanged(evt:any) {
    this.initializeItems();

    const searchTerm = evt;

    if (!searchTerm) {
      return;
    }

    this.tutorials = this.tutorials.filter(item => {
      if (item.kode_tagihan && searchTerm) {
        if (item.kode_tagihan.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
  }

  onChange(deviceValue: any) {
      // console.log(deviceValue.value);
      if(deviceValue.value === 'Tunai'){
        // console.log('function called is tunai');
        this.statusopen = false;
      } else {
        // console.log('function called is transfer');
        this.statusopen = true;
      }
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    idcleansing: '',
    no_invoice: '',
    tanggal_tagihan: '',
    kode_tagihan: '',
    jenis_tagihan: '',
    nama_tagihan: '',
    jumlah_tagihan: '',
    sisa_tagihan: '',
    is_status_tagihan: '',
    description: '',
    idrekening: '',
    // idtagihan: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      idtagihan: this.fadd_role.id,
      idcleansing: this.fadd_role.idcleansing,
      jenis_tagihan: this.fadd_role.jenis_tagihan,
      nama_tagihan: this.fadd_role.nama_tagihan,
      kode_tagihan: this.fadd_role.kode_tagihan,
      jumlah_tagihan: this.fadd_role.jumlah_tagihan,
      sisa_tagihan: this.fadd_role.sisa_tagihan,
      tgl_bayar: this.fadd_role.tgl_bayar,
      metode_bayar: this.fadd_role.metode_bayar,
      idrekening: this.fadd_role.idrekening,
      jumlah_bayar: this.fadd_role.jumlah_bayar,
      description: this.fadd_role.description,
    };

    if(this.isCreated) {
      this.tutorialService.postData('pembayarans/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      // this.id = this.id
      // data.idtagihan = this.id
      // console.log(this.fadd_role.id, data, 'this.fadd_role.id, data')
      this.tutorialService.postData('pembayarans/createbayar', data)
        .subscribe(
          async response => {
            // console.log(response, 'response update');
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error, 'errors');
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  actionPembayaran(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    // console.log(tutorial, 'tes')
    this.id = tutorial.id;
    this.idcleansing = tutorial.idcleansing;
    this.no_invoice = tutorial.no_invoice;
    this.tanggal_tagihan = tutorial.tanggal_tagihan;
    this.kode_tagihan = tutorial.kode_tagihan;
    this.jenis_tagihan = tutorial.jenis_tagihan;
    this.nama_tagihan = tutorial.nama_tagihan;
    this.jumlah_tagihan = tutorial.jumlah_tagihan;
    this.sisa_tagihan = tutorial.sisa_tagihan;
    this.is_status_tagihan = tutorial.is_status_tagihan;
    this.description = tutorial.description;
    this.idrekening = tutorial.idrekening;
    // this.status_kurir = tutorial.status_kurir;
    // this.status_kurir = tutorial.status_kurir == "N" ? this.status_kurir = false:this.status_kurir = true;
    // this.status_pickup = tutorial.status_pickup == "N" ? this.status_pickup = false:this.status_pickup = true;
    this.fadd_role = tutorial;
    this.fadd_role.action = action;
  }

  deleteData:any;
  deleteUser(data:any) {
    this.deleteData = data;
    let modal = this.modalService.open(MODALS['focusFirst']);
    modal.result.then((res:any) => {
      // console.log(res)
      if(res == 'Ok') {
        this.tutorialService.postData('cleansings/delete/'+this.deleteData.id, {}).subscribe(res => {
          this.toastService.show('Successfully delete data.', { classname: 'bg-success text-light', delay: 10000 });
          this.retrieveTutorials();
        })
      }
    });
  }

  openData(tutorial:any, action:any) {
    const param: NavigationExtras = {
      queryParams: {
        info: JSON.stringify(tutorial),
        action: JSON.stringify(action)
      }
    }
    this.router.navigate(['/admin/form-cleansing'], param);
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  currencyStr (value:any) {
    var formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  };

}
