<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Popover</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic Popover</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-popover-basic></app-popover-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">HTML and bindings in popovers</h4>
                    </div>
                    <div class="card-body">
                        <app-popover-tplcontent></app-popover-tplcontent>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom and manual triggers</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-popover-triggers></app-popover-triggers>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Automatic closing with keyboard and mouse</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-popover-autoclose></app-popover-autoclose>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Context and manual triggers</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-popover-tplwithcontext></app-popover-tplwithcontext>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Open and close delays</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-popover-delay></app-popover-delay>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Popover visibility events</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-popover-visibility></app-popover-visibility>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Append popover in the body</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-popover-container></app-popover-container>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Popover with custom class</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-popover-customclass></app-popover-customclass>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of popovers</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-popover-config></app-popover-config>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->