import { Component, OnInit, Injectable } from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


const I18N_VALUES:any = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): any { 
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): any {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): any {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): any {
    return `${date.day}-${date.month}-${date.year}`;
  }
  
  getWeekdayLabel(weekday: number): any {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
}

@Component({
  selector: 'app-datepicker-i18n',
  templateUrl: './datepicker-i18n.component.html',
  styleUrls: ['./datepicker-i18n.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}] // define custom NgbDatepickerI18n provider
})
export class DatepickerI18nComponent implements OnInit {

  constructor() { }
  
  model!: NgbDateStruct;
  
  ngOnInit(): void {
  }

}
