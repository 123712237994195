<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Customer</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-responsive-lg mb-0 table-striped">
								<thead>
									<tr>
										<th class="">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkAll" (change)="checkUncheckAll($event)">
												<label class="custom-control-label" for="checkAll"></label>
											</div>
										</th>
										<th>Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th class="pl-5 width200">Billing Address
										</th>
										<th>Joined</th>
										<th></th>
									</tr>
								</thead>
								<tbody id="customers">
									<tr class="btn-reveal-trigger">
										<td>
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox1">
												<label class="custom-control-label" for="checkbox1"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Ricky Antony</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:ricky@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:2012001851">(201) 200-1851</a></td>
										<td class="py-2 pl-5 wspace-no">2392 Main Avenue, Penasauka</td>
										<td class="py-2">30/03/2018</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox2">
												<label class="custom-control-label" for="checkbox2"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Emma Watson</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a href="mailto:emma@example.com">info@example.com</a>
										</td>
										<td class="py-2"> <a href="tel:2122288403">(212) 228-8403</a></td>
										<td class="py-2 pl-5 wspace-no">2289 5th Avenue, New York
										</td>
										<td class="py-2">11/07/2017</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox3">
												<label class="custom-control-label" for="checkbox3"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Rowen Atkinson</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a href="mailto:rown@example.com">info@example.com</a>
										</td>
										<td class="py-2"> <a href="tel:2012001851">(201) 200-1851</a></td>
										<td class="py-2 pl-5 wspace-no">112 Bostwick Avenue, Jersey City
										</td>
										<td class="py-2">05/04/2016</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox4">
												<label class="custom-control-label" for="checkbox4"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Antony Hopkins</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:antony@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:9013243127">(901) 324-3127</a></td>
										<td class="py-2 pl-5 wspace-no">3448 Ile De France St #242, </td>
										<td class="py-2">05/04/2018</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox5">
												<label class="custom-control-label" for="checkbox5"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Jennifer Schramm</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:jennifer@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:8283829631">(828) 382-9631</a></td>
										<td class="py-2 pl-5 wspace-no">659 Hannah Street, Charlotte
										</td>
										<td class="py-2">17/03/2016</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox6">
												<label class="custom-control-label" for="checkbox6"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Raymond Mims</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:raymond@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:5624685646">(562) 468-5646</a></td>
										<td class="py-2 pl-5 wspace-no">2298 Locust Court, Artesia
										</td>
										<td class="py-2">12/07/2014</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox7">
												<label class="custom-control-label" for="checkbox7"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Michael Jenkins</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:jenkins@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:3026138829">(302) 613-8829</a></td>
										<td class="py-2 pl-5 wspace-no">4678 Maud Street, Philadelphia
										</td>
										<td class="py-2">15/06/2014</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox8">
												<label class="custom-control-label" for="checkbox8"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Kristine Cadena</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:cadena@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:3172737814">(317) 273-7814</a></td>
										<td class="py-2 pl-5 wspace-no">3412 Crestview Manor, Indianapolis</td>
										<td class="py-2">15/04/2015</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox9">
												<label class="custom-control-label" for="checkbox9"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Ricky Antony</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:ricky@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:2012001851">(201) 200-1851</a></td>
										<td class="py-2 pl-5 wspace-no">2392 Main Avenue, Penasauka</td>
										<td class="py-2">30/03/2018</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox10">
												<label class="custom-control-label" for="checkbox10"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Emma Watson</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a href="mailto:emma@example.com">info@example.com</a>
										</td>
										<td class="py-2"> <a href="tel:2122288403">(212) 228-8403</a></td>
										<td class="py-2 pl-5 wspace-no">2289 5th Avenue, New York
										</td>
										<td class="py-2">11/07/2017</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox11">
												<label class="custom-control-label" for="checkbox11"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Rowen Atkinson</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a href="mailto:rown@example.com">info@example.com</a>
										</td>
										<td class="py-2"> <a href="tel:2012001851">(201) 200-1851</a></td>
										<td class="py-2 pl-5 wspace-no">112 Bostwick Avenue, Jersey City
										</td>
										<td class="py-2">05/04/2016</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox12">
												<label class="custom-control-label" for="checkbox12"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Antony Hopkins</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:antony@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:9013243127">(901) 324-3127</a></td>
										<td class="py-2 pl-5 wspace-no">3448 Ile De France St #242</td>
										<td class="py-2">05/04/2018</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox13">
												<label class="custom-control-label" for="checkbox13"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Jennifer Schramm</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:jennifer@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:8283829631">(828) 382-9631</a></td>
										<td class="py-2 pl-5 wspace-no">659 Hannah Street, Charlotte
										</td>
										<td class="py-2">17/03/2016</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox14">
												<label class="custom-control-label" for="checkbox14"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<div class=""><img class="rounded-circle img-fluid"
															src="assets/images/avatar/5.png" width="30" alt="" />
														</div>
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Raymond Mims</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:raymond@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:5624685646">(562) 468-5646</a></td>
										<td class="py-2 pl-5 wspace-no">2298 Locust Court, Artesia
										</td>
										<td class="py-2">12/07/2014</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox15">
												<label class="custom-control-label" for="checkbox15"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Michael Jenkins</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:jenkins@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:3026138829">(302) 613-8829</a></td>
										<td class="py-2 pl-5 wspace-no">4678 Maud Street, Philadelphia
										</td>
										<td class="py-2">15/06/2014</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
									<tr class="btn-reveal-trigger">
										<td class="py-2">
											<div class="custom-control custom-checkbox mx-2">
												<input type="checkbox" class="custom-control-input" id="checkbox16">
												<label class="custom-control-label" for="checkbox16"></label>
											</div>
										</td>
										<td class="py-3">
											<a href="javascript:void(0);">
												<div class="media d-flex align-items-center">
													<div class="avatar avatar-xl mr-2">
														<img class="rounded-circle img-fluid"
														src="assets/images/avatar/1.png" alt="" width="30" />
													</div>
													<div class="media-body">
														<h5 class="mb-0 fs--1">Kristine Cadena</h5>
													</div>
												</div>
											</a>
										</td>
										<td class="py-2"><a
										href="mailto:cadena@example.com">info@example.com</a></td>
										<td class="py-2"> <a href="tel:3172737814">(317) 273-7814</a></td>
										<td class="py-2 pl-5 wspace-no">3412 Crestview Manor, Indianapolis</td>
										<td class="py-2">15/04/2015</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown>
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
