import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';

export interface Tutorial {
  id?: any;
  code_bank?: any;
  nama_bank?: any;
  id_bank?: any;
  no_rekening?: any;
  nama_rekening?: any;
  cabang_bank?: any;
  action?: string;
}

@Component({
  selector: 'app-mst-rekening-bank',
  templateUrl: './mst-rekening-bank.component.html',
  styleUrls: ['./mst-rekening-bank.component.css']
})
export class MstRekeningbankComponent implements OnInit {

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  vcombo: any = [];

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.modalService.open(content);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('rekenings/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('banks/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.vcombo = data;
        },
        error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code_bank: '',
    nama_bank: '',
    id_bank: '',
    no_rekening: '',
    nama_rekening: '',
    cabang_bank: '',
  };

  submitted!: boolean;
  saveTutorial(): void {
    const data = {
      code_bank: this.fadd_role.code_bank,
      nama_bank: this.fadd_role.nama_bank,
      id_bank: this.fadd_role.id_bank,
      no_rekening: this.fadd_role.no_rekening,
      nama_rekening: this.fadd_role.nama_rekening,
      cabang_bank: this.fadd_role.cabang_bank,
    };
    // console.log(data, 'data');

    if (this.isCreated) {
      this.tutorialService.postData('rekenings/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('rekenings/update/' + this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal: any, tutorial: any, action: any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.code_bank = tutorial.code_bank;
    this.fadd_role.nama_bank = tutorial.nama_bank;
    this.fadd_role.id_bank = tutorial.id_bank;
    this.fadd_role.no_rekening = tutorial.no_rekening;
    this.fadd_role.nama_rekening = tutorial.nama_rekening;
    this.fadd_role.cabang_bank = tutorial.cabang_bank;
    this.fadd_role.action = action;
  }

  deleteVendors(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`rekenings/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
