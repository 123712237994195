<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-autocomplete">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Ripple</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Radios with ngModel</h4>
                    </div>
                    <div class="card-body">
                        
                        
                        <mat-checkbox [(ngModel)]="centered" class="example-ripple-checkbox mr-3">Centered</mat-checkbox>
                        <mat-checkbox [(ngModel)]="disabled" class="example-ripple-checkbox mr-3">Disabled</mat-checkbox>
                        <mat-checkbox [(ngModel)]="unbounded" class="example-ripple-checkbox mr-3">Unbounded</mat-checkbox>
                        <div class="row">
                            <div class="col-sm-6">
                                <mat-form-field class="example-ripple-form-field">
                                    <input matInput [(ngModel)]="radius" type="number" placeholder="Radius">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6">
                                <mat-form-field class="example-ripple-form-field">
                                    <input matInput [(ngModel)]="color" type="text" placeholder="Color">
                                </mat-form-field>
                                
                            </div>
                        </div>
                        
                        <div class="example-ripple-container mat-elevation-z4"
                        matRipple
                        [matRippleCentered]="centered"
                        [matRippleDisabled]="disabled"
                        [matRippleUnbounded]="unbounded"
                        [matRippleRadius]="radius"
                        [matRippleColor]="color">
                            Click me
                        </div>
                        
                        
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->