<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Hi, welcome back!</h4>
          <p class="mb-0">Your business dashboard</p>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">User</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0)">Profile</a>
          </li>
        </ol>
      </div>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="profile card card-body px-3 pt-3 pb-0">
          <div class="profile-head">
            <div class="photo-content">
              <div class="cover-photo"></div>
            </div>
            <div class="profile-info">
              <div class="profile-photo">
                <img
                  src="{{
                    users.images == ''
                      ? 'assets/images/profile/noimage.jpg'
                      :  profilImg+users.images
                  }}"
                  class="img-fluid rounded-circle"
                  alt=""
                />
              </div>
              <div class="profile-details">
                <div class="profile-name px-3 pt-2">
                  <h4 class="text-primary mb-0">
                    {{ users.name == undefined ? "" : users.name }}
                  </h4>
                  <p>{{ rolesString == "" ? "" : rolesString }}</p>
                </div>
                <div class="profile-email px-2 pt-2">
                  <h4 class="text-muted mb-0">
                    {{ users.email == undefined ? "" : users.email }}
                  </h4>
                  <p>Email</p>
                </div>
                <div class="profile-email px-2 pt-2">
                  <h4 class="text-muted mb-0">
                    {{ users.hp == undefined ? "" : users.hp }}
                  </h4>
                  <p>Handphone</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="profile-tab">
              <div class="custom-tab-1">
                <ul class="nav nav-tabs" ngbNav #nav="ngbNav">
                  <li class="nav-item" ngbNavItem="profile-settings">
                    <a
                      href="#profile-settings"
                      data-toggle="tab"
                      class="nav-link"
                      ngbNavLink
                      >Account Setting</a
                    >
                    <ng-template ngbNavContent>
                      <div id="profile-settings" class="tab-pane">
                        <div class="pt-3">
                          <div class="settings-form">
                            <!-- <h4 class="text-primary">Account Setting</h4> -->
                            <form class="comment-form">
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label>Username</label>
                                  <input
                                    id="short_name"
                                    type="text"
                                    placeholder="Username"
                                    [(ngModel)]="short_name"
                                    name="short_name"
                                    #name="ngModel"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>Fullname</label>
                                  <input
                                    id="full_name"
                                    type="text"
                                    placeholder="Fullname"
                                    [(ngModel)]="full_name"
                                    name="full_name"
                                    #name="ngModel"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>No Handphone</label>
                                  <input
                                    id="hp"
                                    type="text"
                                    placeholder="No Handphone"
                                    [(ngModel)]="hp"
                                    name="hp"
                                    #name="ngModel"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>Email</label>
                                  <input
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    [(ngModel)]="email"
                                    name="email"
                                    #name="ngModel"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>Password</label>
                                  <input
                                  type="text"
                                  class="form-control"
                                  id="password"
                                  required
                                  [(ngModel)]="password"
                                  #name="ngModel"
                                  name="password"
                                  />
                                </div>

                                <div class="form-group row">
                                  <label class="col-lg-4 col-form-label">Upload Foto
                                  </label>
                                  <div class="col-lg-8">
                                      <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
                                  </div>
                                </div>

                              </div>
                              <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">Update</button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>

                <div [ngbNavOutlet]="nav" class=""></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--**********************************
	Content body end
***********************************-->
