
<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <app-dashboard-crousal1 [data] = "quickTransfer"></app-dashboard-crousal1>
            </div>
            <div class="col-xl-4 col-xxl-6 col-lg-6">	
                <app-das2-graph1></app-das2-graph1>
            </div>
            <div class="col-xl-4 col-xxl-6 col-lg-6">	
                <app-das2-graph2></app-das2-graph2>
                
            </div>
            <div class="col-xl-4 col-xxl-12">	
                <app-das2-graph3></app-das2-graph3>
            </div>
            <div class="col-xl-4 col-xxl-12">
                <app-market-info></app-market-info>
            </div>
            <div class="col-xl-8 col-xxl-12">
                <app-graph-coin-chart></app-graph-coin-chart>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <app-das2-quick-transfer></app-das2-quick-transfer>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-sm-6">
                        <app-das2-sell-order></app-das2-sell-order>
                    </div>
                    <div class="col-sm-6">
                        <app-das2-buy-order></app-das2-buy-order>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->