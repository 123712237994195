<mat-card class="mb-3">
    <mat-card-content>
        <h4 class="example-h2">Checkbox configuration</h4>
        
        <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="indeterminate">Indeterminate</mat-checkbox>
        </section>
        
        <section class="example-section">
            <label class="example-margin">Align:</label>
            <mat-radio-group [(ngModel)]="labelPosition">
                <mat-radio-button class="example-margin" value="after">After</mat-radio-button>
                <mat-radio-button class="example-margin" value="before">Before</mat-radio-button>
            </mat-radio-group>
        </section>
        
        <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
        </section>
    </mat-card-content>
</mat-card>

<mat-card class="result">
    <mat-card-content>
        <h4 class="example-h2">Result</h4>
        
        <section class="example-section">
            <mat-checkbox
            class="example-margin"
            [(ngModel)]="checked"
            [(indeterminate)]="indeterminate"
            [labelPosition]="labelPosition"
            [disabled]="disabled">
                I'm a checkbox
            </mat-checkbox>
        </section>
    </mat-card-content>
</mat-card>
