<form>
	<div class="row">
		<div class="col-sm-6">
		  <h4>mat-select</h4>
		  <mat-form-field appearance="fill">
			<mat-label>Favorite food</mat-label>
			<mat-select [(ngModel)]="selectedValue" name="food">
			  <mat-option *ngFor="let food of foods" [value]="food.value">
				{{food.viewValue}}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  <p> Selected food: {{selectedValue}} </p>
		</div>
		<div class="col-sm-6">
		  <h4>native html select</h4>
		  <mat-form-field appearance="fill">
			<mat-label>Favorite car</mat-label>
			<select matNativeControl [(ngModel)]="selectedCar" name="car">
			  <option value="" selected></option>
			  <option *ngFor="let car of cars" [value]="car.value">
				{{car.viewValue}}
			  </option>
			</select>
		  </mat-form-field>
		  <p> Selected car: {{selectedCar}} </p>
		</div>
	</div>
</form>
