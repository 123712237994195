<h4>mat select</h4>
<mat-form-field appearance="fill">
  <mat-label>Favorite animal</mat-label>
  <mat-select [formControl]="animalControl" required>
    <mat-option>--</mat-option>
    <mat-option *ngFor="let animal of animals" [value]="animal">
      {{animal.name}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
  <mat-hint>{{animalControl.value?.sound}}</mat-hint>
</mat-form-field>

<h4>native html select</h4>
<mat-form-field appearance="fill">
  <mat-label>Select your car (required)</mat-label>
  <select matNativeControl required [formControl]="selectFormControl">
    <option label="--select something --"></option>
    <option value="saab">Saab</option>
    <option value="mercedes">Mercedes</option>
    <option value="audi">Audi</option>
  </select>
  <mat-error *ngIf="selectFormControl.hasError('required')">
    This field is required
  </mat-error>
  <mat-hint>You can pick up your favorite car here</mat-hint>
</mat-form-field>

