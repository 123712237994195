<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <!-- container starts -->
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Accordion</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <!-- Row starts -->
        <div class="row">
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Default Accordion</h4>
                        <!-- <p class="m-0 subtitle">Default accordion. Add <code>accordion</code> class in root</p> -->
                    </div>
                    <div class="card-body">
                        <!-- Default accordion -->
                        <app-accordion-basic></app-accordion-basic>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">One open panel at a time</h4>
                        <!-- <p class="m-0 subtitle">Accordion with border. Add class <code>accordion-bordered</code> with the class <code> accordion</code></p> -->
                    </div>
                    <div class="card-body">
                        <app-accordion-static></app-accordion-static>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Toggle panels</h4>
                        <!-- <p class="m-0 subtitle">Add <code>accordion-no-gutter</code> class with <code>accordion</code></p> -->
                    </div>
                    <div class="card-body">
                        <app-accordion-toggle></app-accordion-toggle>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom header</h4>
                        <!-- <p class="m-0 subtitle">Add <code>accordion-no-gutter accordion-bordered</code> class with <code>accordion</code></p> -->
                    </div>
                    <div class="card-body">
                        <app-accordion-header></app-accordion-header>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Prevent panel toggle</h4>
                        <!-- <p class="m-0 subtitle">Add <code>accordion-left-indicator</code> class with <code>accordion</code></p> -->
                    </div>
                    <div class="card-body">
                        <app-accordion-preventchange></app-accordion-preventchange>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
            <!-- Column starts -->
            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of accordions</h4>
                        <!-- <p class="m-0 subtitle">Add <code>accordion-with-icon</code> class with <code>accordion</code></p> -->
                    </div>
                    <div class="card-body">
                        <app-accordion-config></app-accordion-config>
                    </div>
                </div>
            </div>
            <!-- Column ends -->
        </div>
        <!-- Row ends -->
    </div>
    <!-- container ends -->
</div>
<!--**********************************
    Content body end
***********************************-->
