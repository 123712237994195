<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/admin/app-mst-shippers']">Shippers</a></li>
                  <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Shipper price list</a></li>
              </ol>
            </div>
          </div>
      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">Price List</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Shipper</th>
                      <th scope="col">Destination Country</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Selling Price</th>
                      <th scope="col">Purchase Price</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>{{ tutorial.nameshippers }}</td>
                        <td>{{ tutorial.namecountry }}</td>
                        <td>{{ tutorial.weight }}</td>
                        <td>{{ tutorial.sell_price }}</td>
                        <td>{{ tutorial.purc_price }}</td>
                        <td>{{ tutorial.description }}</td>

                        <td>
                        <button type="button" class="btn btn-rounded btn-info btn-xxs mr-2 mb-2" (click)="editShippersprice(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                            <i class="fa fa-pencil color-info"></i>
                          </button>
                          <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteShippersprice(tutorial)" placement="left" ngbTooltip="Delete">
                              <i class="fa fa-trash color-info"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

               <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination class="pagination-responsive"
                  [collectionSize]="count"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select
                    class="mr-sm-2 custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="handlePageSizeChange($event)"
                  >
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>
              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Shippers Price List</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
            <!-- <input
              type="hidden"
              class="form-control"
              id="shippers_id"
              required
              [(ngModel)]="fadd_role.shippers_id"
              #name="ngModel"
              name="shippers_id"
            /> -->



				<div class="col-lg-12 mb-3">
					<label class="text-black font-w600">Country <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombo.length > 0" id="dest_country" [(ngModel)]="fadd_role.dest_country" #name="ngModel" name="dest_country">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.name}}</option>
            </select>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Weight <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="weight"
              required
              [(ngModel)]="fadd_role.weight"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="weight"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Purchase price <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="purc_price"
              required
              [(ngModel)]="fadd_role.purc_price"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="purc_price"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Selling price <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="sell_price"
              required
              [(ngModel)]="fadd_role.sell_price"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="sell_price"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Description <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            [(ngModel)]="fadd_role.description"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="description"></textarea>
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveShippersprice()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>

			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
