import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
// import { Select2OptionData } from "ng-select2";
// import { Select2OptionData } from 'ng2-select2';
// import { Options } from "select2";
// import { Select2Module } from "ng-select2-component";

import { MasterService } from '../../../_services/master.service';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries | any;
//   chart: ApexChart | any;
//   responsive: ApexResponsive[] | any;
//   labels: any;
//   dataLabels: ApexDataLabels | any;
//   stroke: ApexStroke | any;
//   colors: string[] | any;
//   legend: ApexLegend | any;
// };
// export interface Country {
//   idm: number;
//   name: string;
//   // flag: string;
//   // area: number;
//   // population: number;
// }
//type data


export interface Tutorial {
  id?: any;
  // username?: string,
  // short_name?: string,
  // full_name?: string,
  // hp?: string,
  // email?: string,
  // password?: string,
  // level?: string,
  // fileimage?: string,
  code?: any;
  name?: any;
  description?: any;
  // description?: string,
  // note?: string,
  // published?: boolean;
  action?: string;
}

@Component({
  selector: 'app-mst-vendors',
  templateUrl: './mst-vendors.component.html',
  styleUrls: ['./mst-vendors.component.css']
})
export class MstVendorsComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}


  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('menu/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code: '',
    name: '',
    description: ''
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      code: this.fadd_role.code,
      name: this.fadd_role.name,
      description: this.fadd_role.description
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('vendors/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('vendors/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.code = tutorial.code;
    this.fadd_role.name = tutorial.name;
    this.fadd_role.description = tutorial.description;
    this.fadd_role.action = action;
  }

  deleteVendors(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`vendors/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
