<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Progressbar</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Contextual progress bars</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-progressbar-basic></app-progressbar-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Contextual text progress bars</h4>
                    </div>
                    <div class="card-body">
                        <app-progressbar-texttypes></app-progressbar-texttypes>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Progress bars with current value labels</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-progressbar-showvalue></app-progressbar-showvalue>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Striped progress bars</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-progressbar-striped></app-progressbar-striped>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Progress bars with custom labels</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-progressbar-labels></app-progressbar-labels>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Progress bars with height</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-progressbar-height></app-progressbar-height>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration of progress bars</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-progressbar-height></app-progressbar-height>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->