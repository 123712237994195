<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Product List</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12 col-xl-6"  *ngFor="let product of products">
				<div class="card">
					<div class="card-body">
						<div class="row m-b-30">
							<div class="col-md-5 col-xxl-12">
								<div class="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
									<div class="new-arrivals-img-contnent">
										<img class="img-fluid" src="{{product.image}}" alt="">
									</div>
								</div>
							</div>
							<div class="col-md-7 col-xxl-12">
								<div class="new-arrival-content position-relative">
									<h4><a [routerLink]="['/admin/ecom-product-detail']">{{product.title}}</a></h4>
									<div class="comment-review star-rating">
										<ul>
											<li *ngFor="let ratings of product.ratings_class "><i class="{{ratings.icon_class}}"></i></li>
											<!-- <li><i class="fa fa-star"></i></li>
												<li><i class="fa fa-star"></i></li>
												<li><i class="fa fa-star-half-empty"></i></li>
											<li><i class="fa fa-star-half-empty"></i></li> -->
										</ul>
										<span class="review-text">({{product.reviews}} reviews) / </span><a class="product-review" href="javascript:void(0);" (click)="open(reviewModal)">Write a review?</a>
										<p class="price">{{product.price}}</p>
									</div>
									<p>Availability: <span class="item"> {{product.stock}} <i class="{{product.stock_class}}"></i></span></p>
									<p>Product code: <span class="item">{{product.product_code}}</span> </p>
									<p>Brand: <span class="item">{{product.brand}}</span></p>
									<p class="text-content">{{product.description}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- review -->
			
			<ng-template #reviewModal let-modal>
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Review</h4>
					<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form>
						<div class="text-center mb-4">
							<img class="img-fluid rounded" width="78" src="assets/images/avatar/1.jpg" alt="DexignZone">
						</div>
						<div class="form-group">
							<div class="rating-widget mb-4 text-center">
								<!-- Rating Stars Box -->
								<ngb-rating [max]="5"></ngb-rating>
							</div>
						</div>
						<div class="form-group">
							<textarea class="form-control" placeholder="Comment" rows="5"></textarea>
						</div>
						<button class="btn btn-success btn-block">RATE</button>
					</form>
				</div>
				
			</ng-template>
			
			
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->