import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../bootstrap/toast/toast-global/toast-service';
import {SharedService} from '../shared.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  title = 'Logistik';
  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;

    constructor(
      public sharedService: SharedService,
      public tokenService: TokenStorageService,
      public toastService: ToastService,
      private router: Router
    ) {

    }

  ngOnInit(): void {
    this.getToken();
  }

  async getToken() {
    let token = await this.tokenService.getToken();
    // console.log(token)
    if(token == null) {
      // this.toastService.show('Login terlebih dahulu!', { classname: 'bg-danger text-light', delay: 10000 });
      this.router.navigate(['/page-login'], {replaceUrl:true});
    }
  }

}
