import { Component, OnInit, ViewChild } from "@angular/core";
import { MasterService } from '../../../../_services/master.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
  ApexMarkers,
  ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  plotOptions: ApexPlotOptions | any;
  yaxis: ApexYAxis | any;
  xaxis: ApexXAxis | any;
  fill: ApexFill | any;
  tooltip: ApexTooltip | any;
  stroke: ApexStroke | any;
  legend: ApexLegend | any;
  colors: string[] | any;
  responsive: ApexResponsive[] | any;
  markers: ApexMarkers | any;
  grid: ApexGrid | any;
};

@Component({
  selector: 'app-tahuninbound',
  templateUrl: './tahuninbound.component.html',
  styleUrls: ['./tahuninbound.component.css']
})
export class TahuninboundComponent implements OnInit {
	@ViewChild('chart') chart!: ChartComponent;
	// public chartOptions: Partial<ChartOptions>;
	chartOptions: any = {};
	resultdata: any;
  
	// nameShips: any = [];
	// totalshipments: any = [];
  
	constructor(private apiService: MasterService) {
	  let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	//   params.monthlys = 'monthlys';
  
	  this.apiService.getAlls('dash/getGrandInboundberattahun', params)
	  .subscribe(
		response => {
			var dataseties = [];
			for (let i = 0; i < response.length; i++) {
				//   type: 
			  let data1 = {
				  name : response[i].nama_shippers,
				  type : 'column',
				  data : [ 
					  response[i].tahun2022,
					  response[i].tahun2023,
					  response[i].tahun2024
				   ],
			  }
			  dataseties.push(data1);
			}
			// CHARTS
			this.chartOptions = {
				// series: [
				//   {
				// 	name: "Income",
				// 	type: "column",
				// 	data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
				//   },
				//   {
				// 	name: "Cashflow",
				// 	type: "column",
				// 	data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
				//   },
				//   {
				// 	name: "Revenue",
				// 	type: "line",
				// 	data: [20, 29, 37, 36, 44, 45, 50, 58]
				//   }
				// ],
				series: dataseties,
				chart: {
				  height: 350,
				  type: "line",
				  stacked: false
				},
				colors: [
					"#008FFB",
					"#00E396",
					"#FEB019",
					"#FF4560",
					"#775DD0",
					"#546E7A",
					"#26a69a",
					"#D10CE8",
					'#CA33FF'
				],
				// legend:{
				// 	show:true
				// },
				dataLabels: {
				  enabled: true
				},
				stroke: {
				  width: [1, 1, 4]
				},
				title: {
				  text: "Analysis (2022 - 2024)",
				  align: "left",
				  offsetX: 110
				},
				xaxis: {
				  categories: [2022,2023,2024]
				},
				// yaxis: [
				//   {
				// 	axisTicks: {
				// 	  show: true
				// 	},
				// 	axisBorder: {
				// 	  show: true,
				// 	  color: "#008FFB"
				// 	},
				// 	labels: {
				// 	  style: {
				// 		color: "#008FFB"
				// 	  }
				// 	},
				// 	title: {
				// 	  text: "Income (thousand crores)",
				// 	  style: {
				// 		color: "#008FFB"
				// 	  }
				// 	},
				// 	tooltip: {
				// 	  enabled: true
				// 	}
				//   },
				//   {
				// 	seriesName: "Income",
				// 	opposite: true,
				// 	axisTicks: {
				// 	  show: true
				// 	},
				// 	axisBorder: {
				// 	  show: true,
				// 	  color: "#00E396"
				// 	},
				// 	labels: {
				// 	  style: {
				// 		color: "#00E396"
				// 	  }
				// 	},
				// 	title: {
				// 	  text: "Operating Cashflow (thousand crores)",
				// 	  style: {
				// 		color: "#00E396"
				// 	  }
				// 	}
				//   },
				//   {
				// 	seriesName: "Revenue",
				// 	opposite: true,
				// 	axisTicks: {
				// 	  show: true
				// 	},
				// 	axisBorder: {
				// 	  show: true,
				// 	  color: "#FEB019"
				// 	},
				// 	labels: {
				// 	  style: {
				// 		color: "#FEB019"
				// 	  }
				// 	},
				// 	title: {
				// 	  text: "Revenue (thousand crores)",
				// 	  style: {
				// 		color: "#FEB019"
				// 	  }
				// 	}
				//   }
				// ],
				yaxis: {
					show:false
				},
				tooltip: {
				  fixed: {
					enabled: true,
					position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
					offsetY: 30,
					offsetX: 60
				  }
				},
				legend: {
				  horizontalAlign: "left",
				  offsetX: 40,
				  show:true
				}
			  };
			// this.chartOptions = {
			// 	series: dataseties,
			// 	chart: {
			// 	  type: "bar",
			// 	  height: 350
			// 	},
			// 	colors: [
			// 		"#008FFB",
			// 		"#00E396",
			// 		"#FEB019",
			// 		"#FF4560",
			// 		"#775DD0",
			// 		"#546E7A",
			// 		"#26a69a",
			// 		"#D10CE8",
			// 		'#CA33FF','#3361FF'
			// 	  ],
			// 	plotOptions: {
			// 	  bar: {
			// 		horizontal: false,
			// 		columnWidth: "55%",
			// 		endingShape: "rounded"
			// 	  }
			// 	},
			// 	legend:{
			// 		show:true
			// 	},
			// 	dataLabels: {
			// 	  enabled: true
			// 	},
			// 	stroke: {
			// 	  show: true,
			// 	  width: 2,
			// 	  colors: ["transparent"]
			// 	},
			// 	xaxis: {
			// 	  categories: [
			// 		"2022",
			// 		"2023",
			// 		"2024"
			// 	  ]
			// 	},
			// 	yaxis: {
			// 	  title: {
			// 		text: "KG (Berat)"
			// 	  }
			// 	},
			// 	fill: {
			// 	  opacity: 1
			// 	},
			// 	tooltip: {
			// 	  y: {
			// 		formatter: function(val:any) {
			// 		  return "KG " + val + " Berat";
			// 		}
			// 	  }
			// 	}
			//   };
			
		  // CHARTS
  
		  // // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
		  // this.totalpayments = this.currencyStr(sumpembayarans)
		  // console.log(response.rows[0].totalbayar);
		},
		error => {
		  // console.log(error);
		});
  
	  
	}
  
	ngOnInit(): void {}

}
