import { Component, OnInit, ViewChild } from "@angular/core";
import { MasterService } from '../../../../_services/master.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive,
  ApexMarkers,
  ApexGrid
} from "ng-apexcharts";

export type chartOptions1 = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  dataLabels: ApexDataLabels | any;
  plotOptions: ApexPlotOptions | any;
  yaxis: ApexYAxis | any;
  xaxis: ApexXAxis | any;
  fill: ApexFill | any;
  tooltip: ApexTooltip | any;
  stroke: ApexStroke | any;
  legend: ApexLegend | any;
  colors: string[] | any;
  responsive: ApexResponsive[] | any;
  markers: ApexMarkers | any;
  grid: ApexGrid | any;
};

@Component({
  selector: 'app-tahunvendorpie',
  templateUrl: './tahunvendorpie.component.html',
  styleUrls: ['./tahunvendorpie.component.css']
})
export class TahunvendorpieComponent implements OnInit {
	@ViewChild('chart') chart!: ChartComponent;
	// public chartOptions1: Partial<chartOptions1>;
	chartOptions1: any = {};
	resultdata: any;
  
	// nameShips: any = [];
	// totalshipments: any = [];
  
	constructor(private apiService: MasterService) {
	  let params: any = {};
	  // params[`title`] = 'searchTitle';
  
	  params.tglinput = new Date();
	//   params.monthlys = 'monthlys';
  
	  this.apiService.getAlls('dash/getVendorTotaltahun', params)
	  .subscribe(
		response => {
			var shippers1 = [];
      	  var total1 = [];
		  for (let i = 0; i < response.length; i++) {
			shippers1.push(response[i].nama_vendors);
			total1.push(response[i].total);
		  }
		  // CHARTS
		  this.chartOptions1 = {
			series: total1,
			chart: {
			  width: 380,
			  type: "pie"
			},
			labels: shippers1,
			stroke: {
				width: 0,
			},
			legend:{
				position: "bottom",
					show:true
				},
				dataLabels: {
					enabled: true
				},
			responsive: [
			  {
				breakpoint: 575,
				options: {
				  chart: {
					width: 300
				  },
				  legend: {
					position: "bottom",
					show: true,
				  },
				  dataLabels: {
					  enabled: true,
				  },
				}
			  }
			]
		  };
			
		  // CHARTS
  
		  // // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
		  // this.totalpayments = this.currencyStr(sumpembayarans)
		  // console.log(response.rows[0].totalbayar);
		},
		error => {
		  // console.log(error);
		});
  
	  
	}
  
	ngOnInit(): void {}

}
