import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-option-ripple',
  templateUrl: './no-option-ripple.component.html',
  styleUrls: ['./no-option-ripple.component.css']
})
export class NoOptionRippleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
