import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cleansing-print',
  templateUrl: './cleansing-print.component.html',
  styleUrls: ['./cleansing-print.component.css'],
})
export class CleansingPrintComponent implements OnInit {
  cleansing: any | undefined;
  logo: string = '';
  qrcode: string = '';
  dataCleansing:any;
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((data: any) => {
      if(data.info) {
        this.dataCleansing = JSON.parse(data.info);
        // console.log('dataCleansing',this.dataCleansing)
        // this.router.navigate(['/admin/app-tr-cleansings']);
      }
    });
  }

  ngOnInit(): void {}

  config(param: any) {
    console.log(
      '🚀 ~ file: cleansing-print.component.ts ~ line 17 ~ CleansingPrintComponent ~ param',
      param
    );
  }
}
