<div class="card-body pb-0">
	<div class="row justify-content-between">
		<div class="col-auto">
			<h5>Lorem Ipsum</h5>
		</div>
		<div class="col-auto">
			<h5>
				<span><i class="fa fa-caret-up"></i></span>
				<span>2,250</span>
			</h5>
		</div>
	</div>
</div>
<div class="chart-wrapper">
	<!-- <canvas id="chart_widget_3"></canvas> -->
	
	<apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [responsive]="chartOptions.responsive"
    [xaxis]="chartOptions.xaxis"
	[yaxis]="chartOptions.yaxis"
    [legend]="chartOptions.legend"
    [fill]="chartOptions.fill"
    [colors]="chartOptions.colors"
	[grid]="chartOptions.grid"
  ></apx-chart>
	
	
	
	
</div>
<div class="card-footer">
	<div class="row">
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
		<div class="col text-center">
			<h5 class="font-weight-normal">1230</h5>
			<span>Type A</span>
		</div>
	</div>
</div>