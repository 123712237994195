import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    email: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  onSubmit(): void {
    // console.log('tes')
    const { email, password } = this.form;
    // console.log(email, password, 'email, password');

    this.authService.login(email, password).subscribe(
      data => {
        // console.log(data,'data');
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);
        // // console.log(data, 'data')

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().role;
        // console.log(this.roles, 'this.roles');
        this.reloadPage();
      },
      err => {
        // console.log(err.error, 'err.error')
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(): void {
		this.router.navigate(['admin/index']);
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }


}
