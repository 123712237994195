import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dzmt-select',
  templateUrl: './dzmt-select.component.html',
  styleUrls: ['./dzmt-select.component.css']
})
export class DzmtSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
