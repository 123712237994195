<div id="DZ_W_Todo4" class="widget-media dz-scroll height370" [perfectScrollbar]>
	<ul class="timeline">
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-success check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox1" required="">
					<label class="custom-control-label" for="customCheckBox1"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Get up</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-success light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-warning check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox2" required="">
					<label class="custom-control-label" for="customCheckBox2"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Stand up</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-warning light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-primary check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox3" required="">
					<label class="custom-control-label" for="customCheckBox3"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Don't give up the fight.</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-primary light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-info check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox4" required="">
					<label class="custom-control-label" for="customCheckBox4"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Do something else</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-info light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-success check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox5" required="">
					<label class="custom-control-label" for="customCheckBox5"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Get up</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-success light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
		<li>
			<div class="timeline-panel">
				<div class="custom-control custom-checkbox checkbox-warning check-lg mr-3">
					<input type="checkbox" class="custom-control-input" id="customCheckBox6" required="">
					<label class="custom-control-label" for="customCheckBox6"></label>
				</div>
				<div class="media-body">
					<h5 class="mb-0">Stand up</h5>
					<small class="text-muted">29 July 2020 - 02:26 PM</small>
				</div>
				<div class="dropdown dropdown-no-icon" ngbDropdown>
					<button type="button" class="btn btn-warning light sharp" data-toggle="dropdown" ngbDropdownToggle>
						<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg>
					</button>
					<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
						<a class="dropdown-item" href="#">Edit</a>
						<a class="dropdown-item" href="#">Delete</a>
					</div>
				</div>
			</div>
		</li>
	</ul>
</div>