import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  /* providers: [NgbAlertConfig] */
})
export class AlertComponent implements OnInit {

	constructor() { }
   

   ngOnInit(): void {
  }
}
