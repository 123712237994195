import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-cleansing-form-errors',
  templateUrl: './cleansing-form-errors.component.html',
  styleUrls: ['./cleansing-form-errors.component.css'],
})
export class CleansingFormErrorsComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  constructor() {}

  ngOnInit(): void {}

  showError() {
    const { touched, dirty, errors, invalid } = this.control;
    return touched && dirty && errors && invalid;
  }
}
