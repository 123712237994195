import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-with-prefix-suffix',
  templateUrl: './field-with-prefix-suffix.component.html',
  styleUrls: ['./field-with-prefix-suffix.component.css']
})
export class FieldWithPrefixSuffixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  hide = true;

}
