import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-tplcontent',
  templateUrl: './tooltip-tplcontent.component.html',
  styleUrls: ['./tooltip-tplcontent.component.css']
})
export class TooltipTplcontentComponent implements OnInit {

  constructor() { }

    name ="tooltip";
    
  ngOnInit(): void {
  }

}
