<p>
  <mat-form-field appearance="fill">
    <mat-label>Completely disabled</mat-label>
    <input matInput [matDatepicker]="dp1" disabled>
    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
    <mat-datepicker #dp1></mat-datepicker>
  </mat-form-field>
</p>

<p>
  <mat-form-field appearance="fill">
    <mat-label>Popup disabled</mat-label>
    <input matInput [matDatepicker]="dp2">
    <mat-datepicker-toggle matSuffix [for]="dp2" disabled></mat-datepicker-toggle>
    <mat-datepicker #dp2></mat-datepicker>
  </mat-form-field>
  </p>

<p>
  <mat-form-field appearance="fill">
    <mat-label>Input disabled</mat-label>
    <input matInput [matDatepicker]="dp3" disabled>
    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
  </mat-form-field>
</p>
