<div class="example-container">
  <mat-form-field class="mb-3" hintLabel="Max 10 characters" appearance="fill">
    <mat-label>Enter some input</mat-label>
    <input matInput #input maxlength="10" placeholder="Ex. Nougat">
    <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Select me</mat-label>
    <mat-select>
      <mat-option value="option">Option</mat-option>
    </mat-select>
    <mat-hint align="end">Here's the dropdown arrow ^</mat-hint>
  </mat-form-field>
</div>