<div class="card">
    <div class="card-header border-0 pb-0">
        <h4 class="mb-0 text-black fs-20">Buy Order</h4>
        <div class="dropdown ml-auto dropdown-no-icon" ngbDropdown>
            <div class="btn-link" data-toggle="dropdown" ngbDropdownToggle>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="12" cy="5" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="19" r="2"></circle></g></svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Delete</a>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <div class="dropdown custom-dropdown d-block dropdown-no-icon" ngbDropdown>
            <div class="btn  d-flex align-items-center order-bg border-0 rounded" data-toggle="dropdown" ngbDropdownToggle>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.5 16.5002C28.4986 14.844 27.156 13.5018 25.5003 13.5H16.5002V19.4999H25.5003C27.156 19.4985 28.4986 18.1559 28.5 16.5002Z" fill="#FFAB2D"/>
                    <path d="M16.5002 28.5H25.5003C27.1569 28.5 28.5 27.1569 28.5 25.5003C28.5 23.8432 27.1569 22.5001 25.5003 22.5001H16.5002V28.5Z" fill="#FFAB2D"/>
                    <path d="M21 9.15527e-05C9.40213 9.15527e-05 0.00012207 9.4021 0.00012207 21C0.00012207 32.5979 9.40213 41.9999 21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9867 9.40759 32.5924 0.0133667 21 9.15527e-05ZM31.5002 25.4998C31.4961 28.8122 28.8122 31.4961 25.5003 31.4997V32.9998C25.5003 33.8284 24.8283 34.4999 24.0002 34.4999C23.1717 34.4999 22.5001 33.8284 22.5001 32.9998V31.4997H19.5004V32.9998C19.5004 33.8284 18.8284 34.4999 18.0003 34.4999C17.1718 34.4999 16.5002 33.8284 16.5002 32.9998V31.4997H12.0004C11.1718 31.4997 10.5003 30.8282 10.5003 30.0001C10.5003 29.1715 11.1718 28.5 12.0004 28.5H13.5V13.5H12.0004C11.1718 13.5 10.5003 12.8285 10.5003 11.9999C10.5003 11.1714 11.1718 10.4998 12.0004 10.4998H16.5002V9.00018C16.5002 8.17163 17.1718 7.50009 18.0003 7.50009C18.8289 7.50009 19.5004 8.17163 19.5004 9.00018V10.4998H22.5001V9.00018C22.5001 8.17163 23.1717 7.50009 24.0002 7.50009C24.8288 7.50009 25.5003 8.17163 25.5003 9.00018V10.4998C28.7999 10.4861 31.486 13.1494 31.5002 16.4489C31.5075 18.1962 30.7499 19.8593 29.4265 21C30.7376 22.1279 31.4943 23.7699 31.5002 25.4998Z" fill="#FFAB2D"/>
                </svg>
                <div class="text-left ml-3">
                    <span class="d-block fs-16 text-black">Bitcoin</span>
                </div>
                <i class="fa fa-angle-down scale5 ml-auto"></i>
            </div>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0);">Dash coin</a>
                <a class="dropdown-item" href="javascript:void(0);">ETH coin</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table text-center bg-warning-hover tr-rounded">
                <thead>
                    <tr>
                        <th class="text-left">Price</th>
                        <th class="text-center">Amount</th>
                        <th class="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">82.3</td>
                        <td>0.15</td>
                        <td class="text-right">$134,12</td>
                    </tr>
                    <tr>
                        <td class="text-left">83.9</td>
                        <td>0.18</td>
                        <td class="text-right">$237,31</td>
                    </tr>
                    <tr>
                        <td class="text-left">84.2</td>
                        <td>0.25</td>
                        <td class="text-right">$252,58</td>
                    </tr>
                    <tr>
                        <td class="text-left">86.2</td>
                        <td>0.35</td>
                        <td class="text-right">$126,26</td>
                    </tr>
                    <tr>
                        <td class="text-left">91.6</td>
                        <td>0.75</td>
                        <td class="text-right">$46,92</td>
                    </tr>
                    <tr>
                        <td class="text-left">92.6</td>
                        <td>0.21</td>
                        <td class="text-right">$123,27</td>
                    </tr>
                    <tr>
                        <td class="text-left">93.9</td>
                        <td>0.55</td>
                        <td class="text-right">$212,56</td>
                    </tr>
                    <tr>
                        <td class="text-left">94.2</td>
                        <td>0.18</td>
                        <td class="text-right">$129,26</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer border-0 pt-0 text-center">
        <a [routerLink]="['/admin/coin-details']" class="btn-link">Show more <i class="fa fa-caret-right ml-2 scale-2"></i></a>
    </div>
</div>
