A typeahead example that gets values from the <code>WikipediaService</code>
<ul>
  <li>remote data retrieval</li>
  <li><code>debounceTime</code> operator</li>
  <li><code>tap</code> operator</li>
  <li><code>distinctUntilChanged</code> operator</li>
  <li><code>switchMap</code> operator</li>
  <li><code>catch</code> operator to display an error message in case of connectivity issue</li>
</ul>

<fieldset class="form-inline">
  <div class="form-group">
    <label for="typeahead-http">Search for a wiki page:</label>
    <input id="typeahead-http" type="text" class="form-control mx-sm-3" [class.is-invalid]="searchFailed" [(ngModel)]="model" [ngbTypeahead]="search" placeholder="Wikipedia search" />
    <small *ngIf="searching" class="form-text text-muted">searching...</small>
    <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
  </div>
</fieldset>
<hr>
<pre>Model: {{ model | json }}</pre>