<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-sort">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Sort Header</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-12 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Snack-bar with a custom component</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table matSort class="table" (matSortChange)="sortData($event)">
                                <tr>
                                    <th mat-sort-header="name">Dessert (100g)</th>
                                    <th mat-sort-header="calories">Calories</th>
                                    <th mat-sort-header="fat">Fat (g)</th>
                                    <th mat-sort-header="carbs">Carbs (g)</th>
                                    <th mat-sort-header="protein">Protein (g)</th>
                                </tr>
                                
                                <tr *ngFor="let dessert of sortedData">
                                    <td>{{dessert.name}}</td>
                                    <td>{{dessert.calories}}</td>
                                    <td>{{dessert.fat}}</td>
                                    <td>{{dessert.carbs}}</td>
                                    <td>{{dessert.protein}}</td>
                                </tr>
                            </table>
                            
                            
                        </div>  
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->