import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

// console.log(environment.url, 'environment')
const VERSIONS = 'v1/api/';
const API_URL = environment.url + VERSIONS;
// const API_URL = 'http://localhost:8080/api/test/';
// const API_URL = 'http://103.146.202.32:39999/v1/api/';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  constructor(private http: HttpClient) { }

  // getRole(): Observable<any> {
  //   return this.http.get(API_URL + 'role/view', { responseType: 'text' });
  // }
  get(apiRoute: string) {
    return this.http.get(`${API_URL + apiRoute}`);
  }

  getData(url: any): Observable<any> {
    return this.http.get<any>(API_URL + url);
  }

  //Role
  getAll(params: any): Observable<any> {
    return this.http.get<any>(API_URL + 'role/view', { params });
  }


  postData(url: any, data:any): Observable<any> {
    return this.http.post<any>(API_URL + url, data);
  }

  delete(url: any): Observable<any> {
    return this.http.post(API_URL + url, { responseType: 'text' });
  }

  postDataI(url: any, data:any): Observable<any> {
    return this.http.post<any>(API_URL + url, data, {
      headers: {
        'content-type': 'multipart/form-data; boundary=--------------------------665866579412727101411713',
        // responseType: 'json'
        // 'enctype': 'multipart/form-data'
      },
    });
  }

  // Typeitem
  getAllTi(params: any): Observable<any> {
    return this.http.get<any>(API_URL + 'typeitems/view', { params });
  }

  //Subtype
  getAlls(url: any, params: any): Observable<any> {
    return this.http.get<any>(API_URL + url, { params });
  }

  getcombo(url: string, params: any): Observable<any> {
    return this.http.get<any>(API_URL + url, { params });
  }

  // postDataTi(url: any, data:any): Observable<any> {
  //   return this.http.post<any>(API_URL + url, data);
  // }

  getAllCleansing(params: any): Observable<any> {
    return this.http.get<any>(API_URL + 'tagihan/view', { params });
  }

}
