<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<!-- Add Order -->
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Plugins</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Light Gallery</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-title">Light Gallery</h4>
					</div>
					<div class="card-body pb-1">
						<div id="lightgallery" class="row">
							<a class="col-lg-3 col-md-6 mb-4" href="javascript:void(0);" *ngFor="let item of galleryCol1; let key=index"><img class="rounded" [src]="item.thumb" (click)="open(galleryCol1, key)" style="width:100%;"></a>
						</div>
					</div>
				</div>
				<!-- /# card -->
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->