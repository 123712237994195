<div class="card overflow-hidden">
    <div class="card-header align-items-start border-0 pb-0">
        <div class="mr-auto">
            <p class="mb-2 fs-13"><i class="fa fa-caret-up scale5 mr-2 text-success" aria-hidden="true"></i>4%(30 days)</p>
            <h2 class="text-black mb-0 font-w600">$65,123</h2>
        </div>
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5566 23.893C21.1991 24.0359 20.8009 24.0359 20.4434 23.893L16.6064 22.3582L21 31.1454L25.3936 22.3582L21.5566 23.893Z" fill="#AC4CBC"/>
            <path d="M21 20.8846L26.2771 18.7739L21 10.3304L15.7229 18.7739L21 20.8846Z" fill="#AC4CBC"/>
            <path d="M21 0.00012207C9.40213 0.00012207 0.00012207 9.40213 0.00012207 21C0.00012207 32.5979 9.40213 41.9999 21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9871 9.40762 32.5924 0.0129395 21 0.00012207ZM29.8417 20.171L22.3417 35.171C21.9714 35.9121 21.0701 36.2124 20.3294 35.8421C20.0387 35.697 19.8034 35.4617 19.6583 35.171L12.1583 20.171C11.9253 19.7032 11.9519 19.1479 12.2284 18.7043L19.7284 6.70453C20.2269 6.00232 21.1996 5.83661 21.9018 6.33511C22.0451 6.43674 22.1701 6.56125 22.2717 6.70453L29.7712 18.7043C30.0482 19.1479 30.0747 19.7032 29.8417 20.171Z" fill="#AC4CBC"/>
        </svg>
    </div>
    <div class="card-body p-0">
        <!-- <canvas id="widgetChart4" class="max-h80 mt-auto"></canvas> -->
        <div class="chart-wrapper">
            <canvas class="max-h80"  #canvas4  baseChart 
            [datasets]="lineChartData" 
            [labels]="lineChartLabels" 
            [options]="lineChartOptions"
            [colors]="lineChartColors" 
            [legend]="lineChartLegend" 
            [chartType]="lineChartType" 
            
            >
        </canvas>
        
    </div>
    </div>
</div>