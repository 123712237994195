import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputs-with-prefixes-suffixes',
  templateUrl: './inputs-with-prefixes-suffixes.component.html',
  styleUrls: ['./inputs-with-prefixes-suffixes.component.css']
})
export class InputsWithPrefixesSuffixesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
