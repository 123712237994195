<div class="card">
    <div class="card-header d-block d-sm-flex border-0">
        <div>
            <h4 class="fs-20 text-black">Shipment</h4>
            <p class="mb-0 fs-12">Total Shipment By Country {{dt1 | date : 'dd MMM y'}}</p>
        </div>
        <div class="card-action card-tabs mt-3 mt-sm-0">
            <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" activeId="Bulan">
                <!-- <li class="nav-item" ngbNavItem="Hari">
                    <a class="nav-link" data-toggle="tab" href="#Hari" role="tab" aria-selected="false" ngbNavLink>
                        Week	
                    </a>
                    <ng-template ngbNavContent>
                        <app-haricountry class="bar-chart"></app-haricountry>
                    </ng-template>
                </li> -->
                <li class="nav-item" ngbNavItem="Bulan">
                    <a class="nav-link" data-toggle="tab" href="#Bulan" role="tab" aria-selected="false" ngbNavLink>
                        Month
                    </a>
                    <ng-template ngbNavContent>
                        <app-bulancountry class="bar-chart"></app-bulancountry>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem="Tahun">
                    <a class="nav-link" data-toggle="tab" href="#Tahun" role="tab" aria-selected="false" ngbNavLink>
                        Year
                    </a>
                    <ng-template ngbNavContent>
                        <app-tahuncountry class="bar-chart"></app-tahuncountry>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>   
    <div class="card-body pb-2">
        <div [ngbNavOutlet]="nav" class=""></div>
    </div>
</div>
