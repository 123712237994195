import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';


export interface Tutorial {
  id?: any;
  connote?: string,
  airwaybill?: string,
  status?: string,
  description?: string,
  note?: string,
  no_resi?: string,
  createdAt?: string,
  // published?: boolean;
  action?: string
}

@Component({
  selector: 'app-mst-trackinglokal',
  templateUrl: './mst-trackinglokal.component.html',
  styleUrls: ['./mst-trackinglokal.component.css']
})
export class MstTrackingLokalComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 1000, 10000, 100000, 1000000];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.fadd_role.connote = '';
    this.fadd_role.airwaybill = '';
    this.fadd_role.status = '';
    this.fadd_role.status = '';
    this.fadd_role.description = '';
		this.modalService.open(content);
	}

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('trackinglokals/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('typeitems/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    connote: '',
    airwaybill: '',
    status: '',
    description: '',
    note: '',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      connote: this.fadd_role.connote,
      airwaybill: this.fadd_role.airwaybill,
      status: this.fadd_role.status,
      description: this.fadd_role.description,
      note: this.fadd_role.note
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('trackings/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('trackings/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.connote = tutorial.connote;
    this.fadd_role.airwaybill = tutorial.airwaybill;
    this.fadd_role.status = tutorial.status;
    this.fadd_role.description = tutorial.description;
    this.fadd_role.note = tutorial.note;
    this.fadd_role.action = action;
  }

  deleteTrackings(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`trackings/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
