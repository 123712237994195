<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-select default-mt-btn default-autocomplete default-mt-tree default-mt-progress">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Tree</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tree with checkboxes</h4>
                    </div>
                    <div class="card-body">
                        <app-tree-with-checkboxes></app-tree-with-checkboxes>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tree with dynamic data</h4>
                    </div>
                    <div class="card-body">
                        <app-tree-with-dynamic-data></app-tree-with-dynamic-data>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tree with flat nodes</h4>
                    </div>
                    <div class="card-body">
                        <app-tree-with-flat-nodes></app-tree-with-flat-nodes>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tree with partially loaded data</h4>
                    </div>
                    <div class="card-body">
                        <app-tree-with-partially-loaded-data></app-tree-with-partially-loaded-data>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Tree with nested nodes</h4>
                    </div>
                    <div class="card-body">
                        <app-tree-with-nested-nodes></app-tree-with-nested-nodes>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->