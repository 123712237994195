import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';


import { MasterService } from '../../../_services/master.service';


export interface Tutorial {
  id?: any;
  username?: string,
  short_name?: string,
  full_name?: string,
  hp?: string,
  email?: string,
  password?: string,
  level?: string,
  shipper_id?: string,
  fileimage?: string,
  status?: string,
  namer?: string,
  nameshippers?: string,
  action?: string
}

@Component({
  selector: 'app-mst-user',
  templateUrl: './mst-user.component.html',
  styleUrls: ['./mst-user.component.css']
})
export class MstUserComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];
  vcombos:any = [];

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
    this.retrieveCombodatashippers();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}


  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('user/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('role/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatashippers(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.size = 100;
    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('shippers/viewcombs', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    short_name: '',
    full_name: '',
    hp: '',
    email: '',
    password: '',
    level: '',
    shipper_id: '',
    fileimage: '',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      short_name: this.fadd_role.short_name,
      full_name: this.fadd_role.full_name,
      hp: this.fadd_role.hp,
      email: this.fadd_role.email,
      password: this.fadd_role.password,
      level: this.fadd_role.level,
      shipper_id: this.fadd_role.shipper_id,
      fileimage: this.fadd_role.fileimage,
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('users/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('users/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.short_name = tutorial.short_name;
    this.fadd_role.full_name = tutorial.full_name;
    this.fadd_role.hp = tutorial.hp;
    this.fadd_role.email = tutorial.email;
    this.fadd_role.password = tutorial.password;
    this.fadd_role.level = tutorial.level;
    this.fadd_role.shipper_id = tutorial.shipper_id;
    this.fadd_role.fileimage = tutorial.fileimage;
    this.fadd_role.action = action;
  }

  deleteUsers(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`users/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  activeUsers(data:any) {
    var conf = confirm('Anda yakin Aktifkan ?');
    if (conf) {
      this.tutorialService.delete(`users/aktive/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  nonActiveUsers(data:any) {
    var conf = confirm('Anda yakin Non Aktifkan?');
    if (conf) {
      this.tutorialService.delete(`users/nonaktive/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
