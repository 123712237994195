import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-delay',
  templateUrl: './tooltip-delay.component.html',
  styleUrls: ['./tooltip-delay.component.css']
})
export class TooltipDelayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
