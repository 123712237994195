<div class="card">
    <div class="card-header d-sm-flex d-block pb-0 border-0">
        <div>
            <h4 class="fs-20 text-black">Crypto Statistics</h4>
            <p class="mb-0 fs-12">Lorem ipsum dolor sit amet, consectetur</p>
        </div>
        <div class="d-flex mt-sm-0 mt-3">
            <div class="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customSwitch11">
                <label class="custom-control-label fs-14 text-black pr-2" for="customSwitch11">Date</label>
            </div>
            <div class="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customSwitch12">
                <label class="custom-control-label fs-14 text-black pr-2" for="customSwitch12">Value</label>
            </div>
        </div>
    </div>
    <div class="card-body pb-0">
        <div class="d-flex flex-wrap">
            <div class="custom-control check-switch custom-checkbox mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customCheck9">
                <label class="custom-control-label" for="customCheck9">
                    <span class="d-block text-black font-w500">Bitcoin</span>
                    <span class="fs-12">BTC</span>
                </label>
            </div>
            <div class="custom-control check-switch custom-checkbox mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customCheck91">
                <label class="custom-control-label" for="customCheck91">
                    <span class="d-block text-black font-w500">Ripple</span>
                    <span class="fs-12">XRP</span>
                </label>
            </div>
            <div class="custom-control check-switch custom-checkbox mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customCheck92">
                <label class="custom-control-label" for="customCheck92">
                    <span class="d-block text-black font-w500">Ethereum</span>
                    <span class="fs-12">ETH</span>
                </label>
            </div>
            <div class="custom-control check-switch custom-checkbox mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customCheck93">
                <label class="custom-control-label" for="customCheck93">
                    <span class="d-block text-black font-w500">Zcash</span>
                    <span class="fs-12">ZEC</span>
                </label>
            </div>
            <div class="custom-control check-switch custom-checkbox mr-4 mb-2">
                <input type="checkbox" class="custom-control-input" id="customCheck94">
                <label class="custom-control-label" for="customCheck94">
                    <span class="d-block text-black font-w500">LiteCoin</span>
                    <span class="fs-12">LTC</span>
                </label>
            </div>
        </div>
        
         <apx-chart
			id="lineChart"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [dataLabels]="chartOptions.dataLabels"
            [yaxis]="chartOptions.yaxis"
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend"
            [title]="chartOptions.title"
            [subtitle]="chartOptions.subtitle"
            [colors]="chartOptions.colors"
            [fill]="chartOptions.fill"
            [tooltip]="chartOptions.tooltip"
            [stroke]="chartOptions.stroke"
          ></apx-chart>
    </div>
</div>