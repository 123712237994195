<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid default-mt-dialog  default-mt-btn default-autocomplete">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Material</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Dialog</a></li>
                </ol>
            </div>
        </div>
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dialog with header, scrollable content and actions</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-header-scrollbar-action></app-header-scrollbar-action>
                        
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Injecting data when opening a dialog</h4>
                    </div>
                    <div class="card-body">
                        <app-injecting-data></app-injecting-data>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dialog elements</h4>
                    </div>
                    <div class="card-body">
                        <app-dialog-elements></app-dialog-elements>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dialog launched from a menu</h4>
                    </div>
                    <div class="card-body">
                        <app-dialog-from-menu></app-dialog-from-menu>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Dialog Overview</h4>
                    </div>
                    <div class="card-body">
                        <app-dialog-overview></app-dialog-overview>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->