import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';

export interface Tutorial {
  id?: any;
  title?: any;
  url?: any;
  icon?: any;
  is_main_menu?: any;
  is_aktif?: any;
  no_urut?: any;
  action?: string;
}

@Component({
  selector: 'app-mst-menu',
  templateUrl: './mst-menu.component.html',
  styleUrls: ['./mst-menu.component.css']
})
export class MstMenuComponent implements OnInit {

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService,private router: Router) { }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodata();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('menu/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('menu/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    title: '',
    url: '',
    icon: '',
    is_main_menu: '',
    is_aktif: '',
    no_urut: '',
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      title: this.fadd_role.title,
      url: this.fadd_role.url,
      icon: this.fadd_role.icon,
      is_main_menu: this.fadd_role.is_main_menu,
      is_aktif: this.fadd_role.is_aktif,
      no_urut: this.fadd_role.no_urut
    };

    if(this.isCreated) {
      this.tutorialService.postData('menu/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('menu/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.title = tutorial.title;
    this.fadd_role.url = tutorial.url;
    this.fadd_role.icon = tutorial.icon;
    this.fadd_role.is_main_menu = tutorial.is_main_menu;
    this.fadd_role.is_aktif = tutorial.is_aktif;
    this.fadd_role.no_urut = tutorial.no_urut;
    this.fadd_role.action = action;
  }

  deleteMenu(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`menu/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
