<p>Custom rating template provided via a variable. Shows fine-grained rating display</p>

<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">&hearts;</span>&hearts;
  </span>
</ng-template>

<ngb-rating [(rate)]="currentRate" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>

<hr>
<pre>Rate: <b>{{currentRate}}</b></pre>
<button class="btn btn-sm btn-outline-primary mr-2" (click)="currentRate = 1.35">1.35</button>
<button class="btn btn-sm btn-outline-primary mr-2" (click)="currentRate = 4.72">4.72</button>