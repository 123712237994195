import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-keep-content',
  templateUrl: './nav-keep-content.component.html',
  styleUrls: ['./nav-keep-content.component.css']
})
export class NavKeepContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
   active = 1;
}
