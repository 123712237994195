<p>Bootstrap split buttons and dropdowns on button groups are supported with the existing dropdown directives.</p>

<div class="btn-group mr-3 mb-2">
  <button type="button" class="btn btn-outline-success">Plain ol' button</button>
  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn btn-outline-primary" ngbDropdownToggle>Drop me</button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem>One</button>
      <button ngbDropdownItem>Two</button>
      <button ngbDropdownItem>Four!</button>
    </div>
  </div>
</div>

<div class="btn-group mr-3 mb-2">
  <button type="button" class="btn btn-primary">Split me</button>
  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem>One</button>
      <button ngbDropdownItem>Two</button>
      <button ngbDropdownItem>Four!</button>
    </div>
  </div>
</div>

<div class="btn-group mr-3 mb-2">
  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn btn-outline-danger" ngbDropdownToggle>Select me</button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem>One</button>
      <button ngbDropdownItem>Two</button>
      <button ngbDropdownItem>Four!</button>
    </div>
  </div>
  <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn btn-outline-success" ngbDropdownToggle>Or me</button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem>One</button>
      <button ngbDropdownItem>Two</button>
      <button ngbDropdownItem>Four!</button>
    </div>
  </div>
</div>