<form [formGroup]="stateForm">
  <mat-form-field>
    <mat-label>States Group</mat-label>
    <input type="text"
           matInput
           formControlName="stateGroup"
           required
           [matAutocomplete]="autoGroup">
      <mat-autocomplete #autoGroup="matAutocomplete">
        <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
          <mat-option *ngFor="let name of group.names" [value]="name">
            {{name}}
          </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>