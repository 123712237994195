<apx-chart
  [series]="chart1options.series"
  [chart]="chart1options.chart"
  [colors]="chart1options.colors"
  [yaxis]="chart1options.yaxis"
  [dataLabels]="commonOptions.dataLabels"
  [markers]="commonOptions.markers"
  [stroke]="commonOptions.stroke"
  [grid]="commonOptions.grid"
  [xaxis]="commonOptions.xaxis"
  [tooltip]="commonOptions.tooltip"
></apx-chart>
<apx-chart
  [series]="chart2options.series"
  [chart]="chart2options.chart"
  [colors]="chart2options.colors"
  [yaxis]="chart2options.yaxis"
  [dataLabels]="commonOptions.dataLabels"
  [markers]="commonOptions.markers"
  [stroke]="commonOptions.stroke"
  [grid]="commonOptions.grid"
  [xaxis]="commonOptions.xaxis"
  [tooltip]="commonOptions.tooltip"
></apx-chart>
<apx-chart
  [series]="chart3options.series"
  [chart]="chart3options.chart"
  [colors]="chart3options.colors"
  [yaxis]="chart3options.yaxis"
  [dataLabels]="commonOptions.dataLabels"
  [markers]="commonOptions.markers"
  [stroke]="commonOptions.stroke"
  [grid]="commonOptions.grid"
  [xaxis]="commonOptions.xaxis"
  [tooltip]="commonOptions.tooltip"
></apx-chart>
