<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
    <div class="container-fluid">
        <div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                    <h4>Hi, welcome back!</h4>
                    <p class="mb-0">Your business dashboard template</p>
                </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Bootstrap</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Pagination</a></li>
                </ol>
            </div>
        </div>
        
        <!-- row -->
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Basic Pagination</h4>
                    </div>
                    <div class="card-body">
                        
                        <app-pagination-basic></app-pagination-basic>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Advanced pagination</h4>
                    </div>
                    <div class="card-body">
                        <app-pagination-advanced></app-pagination-advanced>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Custom links</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-pagination-customization></app-pagination-customization>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Pagination size</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-pagination-size></app-pagination-size>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Pagination alignment</h4>
                        
                    </div>
                    <div class="card-body">
                        <app-pagination-justify></app-pagination-justify>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Disabled pagination</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-pagination-disabled></app-pagination-disabled>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header d-block">
                        <h4 class="card-title">Global configuration</h4>
                        
                    </div>
                    <div class="card-body">
                        
                        <app-pagination-config></app-pagination-config>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->