<p>You can easily control dropdowns programmatically using the exported dropdown instance.</p>

<div class="d-inline-block mb-2" ngbDropdown #myDrop="ngbDropdown">
  <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">Toggle dropdown</button>
  <div ngbDropdownMenu aria-labelledby="dropdownManual">
    <button ngbDropdownItem>Action - 1</button>
    <button ngbDropdownItem>Another Action</button>
    <button ngbDropdownItem>Something else is here</button>
  </div>
</div>

<button class="btn btn-outline-success mr-2 mb-2" (click)="$event.stopPropagation(); myDrop.open();">Open from outside</button>
<button class="btn btn-outline-danger mr-2 mb-2" (click)="$event.stopPropagation(); myDrop.close();">Close from outside</button>