<ngb-carousel class="default-carousel" *ngIf="images" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
  <ng-template ngbSlide *ngFor="let image of images">
    <div class="picsum-img-wrapper">
      <img [src]="image" alt="Random slide">
    </div>
    <div class="carousel-caption">
      <h3>No mouse navigation</h3>
      <p>This carousel hides navigation arrows and indicators.</p>
    </div>
  </ng-template>
</ngb-carousel>

<hr>

<div class="btn-group" role="group" aria-label="Carousel toggle controls">
  <button type="button" (click)="showNavigationArrows = !showNavigationArrows" class="btn btn-outline-primary btn-sm">Toggle navigation arrows</button>
  <button type="button" (click)="showNavigationIndicators = !showNavigationIndicators" class="btn btn-outline-primary btn-sm">Toggle navigation indicators</button>
</div>