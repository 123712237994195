<p>A typeahead example that uses a custom template for results display, an object as the model,
  and the highlight directive to highlight the term inside the custom template.
</p>

<ng-template #rt let-r="result" let-t="term">
  <img [src]="'https://upload.wikimedia.org/wikipedia/commons/thumb/' + r['flag']" class="mr-1" style="width: 16px">
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

<label for="typeahead-template">Search for a state:</label>
<input id="typeahead-template" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt"
  [inputFormatter]="formatter" />
<hr>
<pre>Model: {{ model | json }}</pre>